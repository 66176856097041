import { Filter } from "./Filter"
import { Info } from "./Info"
import { JournalTable } from "./Table"

export const Journal = () => (
  <>
    <Info />
    <Filter />
    <JournalTable />
  </>
)
