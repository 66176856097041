import { useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { EditOutlined } from "@ant-design/icons"
import { Button, Flex, Spin, Typography } from "antd"
import classNames from "classnames/bind"
import { GroupStatisticsAcademic } from "@/api/groups"
import { ROUTES } from "@/constants"
import mainDictionary from "@/dictionary"
import { useStores } from "@/stores"
import { currentDateWithDotsFormat, formatDate } from "@/utils"
import { AcademicHour } from "./AcademicHour"

import styles from "./statistics.module.scss"

const cn = classNames.bind(styles)

export const Statistics = observer(() => {
  const { groupsStore } = useStores()
  const { id } = useParams()

  const handleAcademicHourChange = (academic: GroupStatisticsAcademic) => {
    groupsStore.setSelectedGroupAcademic(academic)
    groupsStore.setAcademicHoursModal(true)
  }

  useEffect(() => {
    if (id) {
      const parsedId = parseInt(id, 10)

      groupsStore.getGroupStatistics(parsedId)
    }
  }, [])

  if (groupsStore.groupStatisticsLoading)
    return (
      <Flex className={cn("statistics")} justify="center" align="center">
        <Spin />
      </Flex>
    )

  return (
    <div className={cn("statistics")}>
      <Flex gap={24} vertical>
        <Typography.Title level={4}>{mainDictionary.statistics}</Typography.Title>

        <Flex vertical gap={12} className={cn("statistics__holder")}>
          <Flex className={cn("statistics__wrapper")}>
            <Typography.Text className={cn("statistics__title")}>{mainDictionary.fromPayers}</Typography.Text>

            <Typography.Text className={cn("statistics__price", { "statistics__price--value": true })}>
              {groupsStore?.groupStatistics?.totalPaid?.toLocaleString("FR-fr")} so‘m
            </Typography.Text>
          </Flex>

          <Flex className={cn("statistics__wrapper")}>
            <Typography.Text className={cn("statistics__title")}>{mainDictionary.lastPrice}</Typography.Text>

            <Typography.Text className={cn("statistics__title", { "statistics__title--value": true })}>
              {!!groupsStore?.groupStatistics?.remainInCurrency.toLocaleString("FR-fr")}
              {groupsStore?.groupStatistics?.remainInCurrency ? "so‘m +" : "0"}
              {!!groupsStore?.groupStatistics?.remainInHour.toLocaleString("FR-fr")}
              {groupsStore?.groupStatistics?.remainInHour ? "soat" : ""}
            </Typography.Text>
          </Flex>

          <Flex className={cn("statistics__all")}>
            <Typography.Text className={cn("statistics__title")}>{mainDictionary.allOfthem}</Typography.Text>

            <span className={cn("statistics__border")} />

            <Typography.Text className={cn("statistics__title", { "statistics__title--value": true })}>
              {groupsStore?.groupStatistics?.totalRemainInCurrency?.toLocaleString("FR-fr")}
              {groupsStore?.groupStatistics?.totalRemainInCurrency ? "so‘m +" : "0"}
              {!!groupsStore.groupStatistics?.totalRemainInHour.toLocaleString("Fr-fr")}
              {groupsStore.groupStatistics?.totalRemainInHour ? "soat" : ""}
            </Typography.Text>
          </Flex>
        </Flex>

        {/*  */}

        <Flex className={cn("statistics__holder")} gap={12} vertical>
          <Typography.Title level={5} className={cn("statistics__typography")}>
            📅 {currentDateWithDotsFormat()} dan
          </Typography.Title>
          <Flex className={cn("statistics__wrapper")}>
            <Typography.Text className={cn("statistics__title")}>{mainDictionary.visits}</Typography.Text>

            <Typography.Text className={cn("statistics__title", { "statistics__title--value": true })}>
              {(groupsStore.groupStatistics?.lessonsCount || 0) + (groupsStore.groupStatistics?.freeLessonsCount || 0)}
            </Typography.Text>
          </Flex>

          <Flex className={cn("statistics__wrapper")}>
            <Typography.Text className={cn("statistics__title")}>{mainDictionary.free}</Typography.Text>

            <Typography.Text className={cn("statistics__title", { "statistics__title--value": true })}>
              {groupsStore.groupStatistics?.freeLessonsCount}
            </Typography.Text>
          </Flex>

          <Flex className={cn("statistics__all")}>
            <Typography.Text className={cn("statistics__title")}>{mainDictionary.allPayed}</Typography.Text>

            <span className={cn("statistics__border")} />

            <Typography.Text className={cn("statistics__title", { "statistics__title--value": true })}>
              {groupsStore.groupStatistics?.lessonsCount}
            </Typography.Text>
          </Flex>
        </Flex>

        {/*  */}

        <Flex className={cn("statistics__holder")} gap={12} vertical>
          <Flex className={cn("statistics__wrapper")}>
            <Typography.Text className={cn("statistics__title")}>{mainDictionary.paid}</Typography.Text>

            <Typography.Text className={cn("statistics__title", { "statistics__title--value": true })}>
              {groupsStore.groupStatistics?.chargeableAbsenceCount || 0}
            </Typography.Text>
          </Flex>

          <Flex className={cn("statistics__wrapper")}>
            <Typography.Text className={cn("statistics__title")}>{mainDictionary.unpaid}</Typography.Text>

            <Typography.Text className={cn("statistics__title", { "statistics__title--value": true })}>
              {groupsStore.groupStatistics?.nonChargeableAbsenceCount}
            </Typography.Text>
          </Flex>

          <Flex className={cn("statistics__all")}>
            <Typography.Text className={cn("statistics__title")}>{mainDictionary.chargableLessons}</Typography.Text>

            <span className={cn("statistics__border")} />

            <Typography.Text className={cn("statistics__title", { "statistics__title--value": true })}>
              {`${
                // eslint-disable-next-line operator-linebreak
                (groupsStore.groupStatistics?.chargeableAbsenceCount || 0) +
                (groupsStore.groupStatistics?.nonChargeableAbsenceCount || 0)
              }`}
            </Typography.Text>
          </Flex>
        </Flex>

        <Flex className={cn("statistics__holder")} gap={20} vertical>
          {groupsStore.groupStatistics?.academics?.map((item) => (
            <Flex vertical gap={12} key={item.id}>
              <Link
                className={cn("statistics__academic")}
                to={ROUTES.usersAcademicSingle?.replace(":id", item.id?.toString())}
              >
                {`${item.firstName} ${item.lastName}`}
              </Link>

              <Flex className={cn("statistics__wrapper")}>
                <Typography.Text className={cn("statistics__title")}>{mainDictionary.taughtHours}</Typography.Text>

                <Typography.Text className={cn("statistics__title", { "statistics__title--value": true })}>
                  {item.totalHours}
                </Typography.Text>
              </Flex>

              <Flex className={cn("statistics__wrapper")}>
                <Typography.Text className={cn("statistics__title")}>{mainDictionary.scoreForGroup}</Typography.Text>

                <Flex vertical gap={8}>
                  {item.prices?.length ? (
                    item.prices?.map((price, index) => (
                      <Flex
                        className={cn("statistics__prices", {
                          "statistics__prices--addition": index !== item.prices?.length - 1,
                        })}
                        key={price.id}
                        justify="flex-end"
                        gap={12}
                      >
                        <Typography.Text className={cn("statistics__title", { "text--bold": true })}>
                          {`${price?.priceMinAmount} / ${price?.hourNotation}`}
                        </Typography.Text>

                        {price?.from && (
                          <Typography.Text className={cn("statistics__title", { "text--bold": true })}>
                            {`${formatDate(price?.from, true)} ${mainDictionary.from}`}
                          </Typography.Text>
                        )}

                        {item.prices?.length - 1 === index && (
                          <Button
                            onClick={handleAcademicHourChange.bind(null, item)}
                            icon={<EditOutlined />}
                            size="small"
                            type="text"
                            shape="circle"
                          />
                        )}
                      </Flex>
                    ))
                  ) : (
                    <Flex justify="flex-end" align="center" gap={12}>
                      <Typography.Text className={cn("statistics__title", { "text--bold": true })}>
                        0 / {mainDictionary.hour?.toLowerCase()}
                      </Typography.Text>

                      <Button
                        onClick={handleAcademicHourChange.bind(null, item)}
                        icon={<EditOutlined />}
                        size="small"
                        type="text"
                        shape="circle"
                      />
                    </Flex>
                  )}
                </Flex>
              </Flex>

              <Flex className={cn("statistics__wrapper")}>
                <Typography.Text className={cn("statistics__title")}>
                  {mainDictionary.salaryForAllGroups}
                </Typography.Text>

                <Typography.Text className={cn("statistics__title", { "statistics__title--value": true })}>
                  {item.totalPayments} UZS
                </Typography.Text>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>

      {groupsStore.academicHoursModal && <AcademicHour />}
    </div>
  )
})
