import { makeAutoObservable } from "mobx"
import { TPermission } from "@/api/controls"
import {
  AcademicHoursEdit,
  AcademicHoursItem,
  AcademicHoursRes,
  AcademicPriceEdit,
  AcademicPrices,
  AccessControl,
  Categories,
  CategoriesEdit,
  CategoriesRes,
  Contract,
  ContractHistoryRes,
  ContractRes,
  Courses,
  CoursesEdit,
  CoursesRes,
  CustomField,
  CustomFieldTargetType,
  DeleteFinancePayments,
  EditFinanceDiscount,
  EditFinancePayments,
  Expenses,
  ExpensesEdit,
  FinanceDiscounts,
  FinancePayments,
  FinancePaymentsData,
  GamificationOfferingParams,
  GamificationOfferingResponseTypes,
  LearningPayments,
  LearningPaymentsEdit,
  Location,
  LocationEdit,
  RolePermission,
  settingsApi,
  SettingsFinancePriceParam,
  SettingsFinanePriceEdit,
  StaffStatus,
  StaffStatusesRes,
  StudentStatus,
  StudentStatusesRes,
} from "@/api/settings"
import mainDictionary from "@/dictionary"
import { addCatchNotification, addSuccessNotification } from "@/modules/notifications"
import { PaymentSegmentedValues, PricesUnits } from "@/pages/Settings/SettingsFinance/constants"
import { MobileAppVersionReqParams, MobileAppVersionRes } from "@/types"
import {
  AddRoleParams,
  ChangeStudentReq,
  ContractFormValues,
  CustomFieldAddValue,
  CustomFieldEditValue,
  EditContractReq,
  EditMenuParams,
  EditRoleParams,
  EditRolePermissionParams,
  EditStudentReq,
  GetContractHistoryParams,
  GetContractHistoryReq,
  GetContractsAll,
  GetCustomFieldReq,
  GetPricesResponse,
  PricesItem,
} from "./types"

class SettingsStore {
  isUpdateGamificationOffering = false
  isGamificationModal = false
  gamificationOffer: GamificationOfferingResponseTypes | null = null
  editorContent = ""
  studentStatuses: StudentStatusesRes | null = null
  studentStatus: StudentStatus | null = null
  contracts: ContractRes | null = null
  contractsLoading = false
  makeContractModal = false
  selectedContract: Contract | null = null
  contractHistory: ContractHistoryRes | null = null
  contractHistoryLoading = false
  singleContractHistory: Contract | null = null
  contractsFilter: GetContractsAll = {
    page: 1,
    perPage: 10,
  }

  isNewVersionModal = false
  mobileAppVersions: MobileAppVersionRes | null = null
  totalMobileAppVersions = 0
  mobileVersionsPage = 1
  mobileVersionsPerPage = 10
  mobileAppFilterOptions: Partial<MobileAppVersionReqParams> = {
    page: 1,
    perPage: 10,
  }

  contractHistoryFilter: GetContractHistoryParams = {
    page: 1,
    perPage: 10,
  }

  additionalFeesModal = false
  finanPriceModal = false
  isSettingsDiscountModal = false
  tariffsModal = false
  paymentSegmentedValue = PaymentSegmentedValues.PaymentType
  isSettingsPaymentsModal = false
  cagetoriesModal = false
  financeDiscounts: FinanceDiscounts[] = []
  financeDiscountsLoading = false
  financeDiscountsEditData: FinanceDiscounts | null = null
  academicPrices: AcademicPrices[] = []
  academicPricesLoading = false
  academicPriceEditData: AcademicPrices | null = null
  academicHours: AcademicHoursRes[] = []
  financePrices: GetPricesResponse | null = null
  financePricesLoading = false
  financePriceSelectedUnit = PricesUnits.Minute
  financePriceIsPacket = false
  accessControlUsers: AccessControl[] | null = null
  financePricesFilter: SettingsFinancePriceParam = {
    page: 1,
    perPage: 10,
  }

  financePriceEditData: PricesItem | null = null
  financePaymentsLoading = false
  financePayments: FinancePayments[] = []
  financePaymentsEditData: FinancePayments | null = null
  accessControlUsersLoading = false
  makeRoleModal = false
  selectedRole: AccessControl | null = null
  rolePermissions: RolePermission[] | null = null
  rolePermissionsLoading = false
  menus: TPermission[] | null = null
  menusLoading = false
  makeStatus = false
  staffStatuses: StaffStatusesRes | null = null
  staffStatusesLoading = true
  selectedStaffStatus: StaffStatus | null = null
  coursesModal = false
  academicHoursModal = false
  academicHoursLoading = false
  academicHoursEditData: AcademicHoursItem | null = null
  customFieldsLoading = false
  customFieldsTarget: CustomFieldTargetType | string = CustomFieldTargetType.Student
  customFields: CustomField[] | null = null
  makeCustomFieldModal = false
  selectedCustomField: CustomField | null = null
  courses: CoursesRes | null = null
  coursesLoading = false
  coursesEditData: Courses | null = null
  directionsModal = false
  categoriesLoading = false
  categories: CategoriesRes | null = null
  categoriesEditData: Categories | null = null
  learningPaymentsModal = false
  laerningPayments: LearningPayments[] | null = null
  learningPaymentsLoadnig = false
  learningPaymentsEditData: LearningPayments | null = null
  locationModal = false
  locationTableLoading = false
  locationData: Location[] | null = null
  locationEditData: Location | null = null
  expensesModal = false
  expensesTableLoading = false
  expensesData: Expenses[] | null = null
  expensesEditData: Expenses | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setExpensesEditData = (params: Expenses | null) => {
    this.expensesEditData = params
  }

  setExpensesData = (params: Expenses[]) => {
    this.expensesData = params
  }

  setExpensesTableLoading = (params: boolean) => {
    this.expensesTableLoading = params
  }

  setExpensesModal = (params: boolean) => {
    this.expensesModal = params
  }

  setLocationModal = (params: boolean) => {
    this.locationModal = params
  }

  setLocationTableLoading = (params: boolean) => {
    this.locationTableLoading = params
  }

  setLocationData = (params: Location[]) => {
    this.locationData = params
  }

  setLocationEditData = (params: Location | null) => {
    this.locationEditData = params
  }

  setLearningPaymentsEditData = (params: LearningPayments) => {
    this.learningPaymentsEditData = params
  }

  setLearningPaymentsLoading = (params: boolean) => {
    this.learningPaymentsLoadnig = params
  }

  setLearningPayments = (params: LearningPayments[]) => {
    this.laerningPayments = params
  }

  setLearningPaymentsModal = (params: boolean) => {
    this.learningPaymentsModal = params
  }

  setSelectedCustomField = (params: CustomField | null) => {
    this.selectedCustomField = params
  }

  setMakeCustomFieldModal = (params: boolean) => {
    this.makeCustomFieldModal = params
  }

  setCustomFields = (params: CustomField[] | null) => {
    this.customFields = params
  }

  setCustomFieldsLoading = (params: boolean) => {
    this.customFieldsLoading = params
  }

  setCustomFieldsTarget = (params: CustomFieldTargetType | string) => {
    this.customFieldsTarget = params
  }

  setCategoriesEditData = (params: Categories | null) => {
    this.categoriesEditData = params
  }

  setCategories = (params: CategoriesRes) => {
    this.categories = params
  }

  setCategoriesLoading = (params: boolean) => [(this.categoriesLoading = params)]

  setDirectionsModal = (params: boolean) => {
    this.directionsModal = params
  }

  setAcademicHoursEditData = (params: AcademicHoursItem | null) => {
    this.academicHoursEditData = params
  }

  setAcademicHoursLoading = (params: boolean) => {
    this.academicHoursLoading = params
  }

  setAcademicHoursModal = (params: boolean) => {
    this.academicHoursModal = params
  }

  setCoursesEditData = (params: Courses | null) => {
    this.coursesEditData = params
  }

  setCoursesLoading = (value: boolean) => {
    this.coursesLoading = value
  }

  setCourses = (params: CoursesRes) => {
    this.courses = params
  }

  setCoursesModal = (params: boolean) => {
    this.coursesModal = params
  }

  setSelectedStaffStatus = (params: StaffStatus | null) => {
    this.selectedStaffStatus = params
  }

  setStaffStatusesLoading = (params: boolean) => {
    this.staffStatusesLoading = params
  }

  setStaffStatuses = (params: StaffStatusesRes | null) => {
    this.staffStatuses = params
  }

  setMakeStatus = (params: boolean) => {
    this.makeStatus = params
  }

  setMenusLoading = (params: boolean) => {
    this.menusLoading = params
  }

  setMenus = (params: TPermission[] | null) => {
    this.menus = params
  }

  setRolePermissionsLoading = (params: boolean) => {
    this.rolePermissionsLoading = params
  }

  setRolePermissions = (params: RolePermission[] | null) => {
    this.rolePermissions = params
  }

  setSelectedRole = (params: AccessControl | null) => {
    this.selectedRole = params
  }

  setMakeRoleModal = (params: boolean) => {
    this.makeRoleModal = params
  }

  setAccessControlUsersLoading = (params: boolean) => {
    this.accessControlUsersLoading = params
  }

  setAccessControlUsers = (params: AccessControl[] | null) => {
    this.accessControlUsers = params
  }

  setFinancePriceEditData = (params: PricesItem | null) => {
    this.financePriceEditData = params
  }

  setFinancePricesIsPacket = (params: boolean) => {
    this.financePriceIsPacket = params
  }

  setFinancePricesSelectedUnit = (params: PricesUnits) => {
    this.financePriceSelectedUnit = params
  }

  setFinancePricesFilter = (params: SettingsFinancePriceParam) => {
    this.financePricesFilter = params
  }

  setFinancePricesLoading = (params: boolean) => {
    this.financePricesLoading = params
  }

  setFinancePrices = (params: GetPricesResponse) => {
    this.financePrices = params
  }

  setFinancePaymentsTypes = (params: FinancePayments[]) => {
    this.financePayments = params
  }

  setFinancePaymentsEditData = (params: FinancePayments | null) => {
    this.financePaymentsEditData = params
  }

  setFinancePaymentsLoading = (value: boolean) => {
    this.financePaymentsLoading = value
  }

  setAcademicHours = (params: AcademicHoursRes[]) => {
    this.academicHours = params
  }

  setAcademicPrices = (params: AcademicPrices[]) => {
    this.academicPrices = params
  }

  setAcademicPricesLoading = (value: boolean) => {
    this.academicPricesLoading = value
  }

  setAcademicPricesEditData = (params: AcademicPrices | null) => {
    this.academicPriceEditData = params
  }

  setFinanceDiscountsEditData = (params: FinanceDiscounts | null) => {
    this.financeDiscountsEditData = params
  }

  setFinanceDiscountsLoading = (value: boolean) => {
    this.financeDiscountsLoading = value
  }

  setFinanceDiscounts = (params: FinanceDiscounts[]) => {
    this.financeDiscounts = params
  }

  setCategoriesModal = (params: boolean) => {
    this.directionsModal = params
  }

  setIsSettingsPaymentsModal = (params: boolean) => {
    this.isSettingsPaymentsModal = params
  }

  setPaymentSegmentedValue = (params: PaymentSegmentedValues) => {
    this.paymentSegmentedValue = params
  }

  setTariffsModal = (value: boolean) => {
    this.tariffsModal = value
  }

  setAdditionalFeesModal = (value: boolean) => {
    this.additionalFeesModal = value
  }

  setFinanPriceModal = (value: boolean) => {
    this.finanPriceModal = value
  }

  setIsSettingsDiscountModal = (params: boolean) => {
    this.isSettingsDiscountModal = params
  }

  setContractHistory = (params: ContractHistoryRes | null) => {
    this.contractHistory = params
  }

  setContractHistoryFilter = (params: GetContractHistoryParams) => {
    this.contractHistoryFilter = params
  }

  setSingleContractHistory = (params: Contract | null) => {
    this.singleContractHistory = params
  }

  setContractHistoryLoading = (value: boolean) => {
    this.contractHistoryLoading = value
  }

  setSelectedContract = (params: Contract | null) => {
    this.selectedContract = params
  }

  setMakeContractModal = (value: boolean) => {
    this.makeContractModal = value
  }

  setContractsFilter = (params: GetContractsAll) => {
    this.contractsFilter = params
  }

  setContractsLoading = (value: boolean) => {
    this.contractsLoading = value
  }

  setContracts = (params: ContractRes | null) => {
    this.contracts = params
  }

  setStudentStatuses = (params: StudentStatusesRes | null) => {
    this.studentStatuses = params
  }

  setStudentStatus = (params: StudentStatus | null) => {
    this.studentStatus = params
  }

  setGamificationOffer = (gamificationOffer: GamificationOfferingResponseTypes | null) => {
    this.gamificationOffer = gamificationOffer
  }

  addContract = (params: ContractFormValues) => settingsApi.addContract(params)

  editContract = (params: EditContractReq) => settingsApi.editContract(params)

  setNewVersionModal = (value: boolean) => {
    this.isNewVersionModal = value
  }

  setMobileAppVersions = (versions: MobileAppVersionRes) => {
    this.mobileAppVersions = versions
  }

  setTotalMobileVersions = (total: number) => {
    this.totalMobileAppVersions = total
  }

  setMobileVersionsPage = (page: number) => {
    this.mobileVersionsPage = page
  }

  setMobileVersionsPerPage = (perPage: number) => {
    this.mobileVersionsPerPage = perPage
  }

  setMobileVersionFilterOptions = (options: Partial<MobileAppVersionReqParams>) => {
    this.mobileAppFilterOptions = options
  }

  getFinancePrices = (params: SettingsFinancePriceParam) =>
    settingsApi
      .getSettingsFinancePrices(params)
      .then((res) => {
        if (res.success) {
          this.setFinancePrices(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setFinancePricesLoading(false))

  addSettingsFinancePrice = (params: PricesItem) => settingsApi.addSettingsFinancePrices(params)

  editSettingsFinancePrice = (params: SettingsFinanePriceEdit) => settingsApi.editSettingsFinancePrices(params)

  deleteFinancePrices = (id: number) =>
    settingsApi
      .deleteFinancePrices(id)
      .then((res) => {
        if (res.success) {
          addSuccessNotification(mainDictionary.successfullyDeleted)
          this.setFinancePricesLoading(true)
          this.getFinancePrices({
            ...this.financePricesFilter,
            packet: this.financePriceIsPacket,
            unit: this.financePriceSelectedUnit,
          })
        }
      })
      .catch(addCatchNotification)

  addRole = (params: AddRoleParams) => settingsApi.addNewRole(params)

  editRolePermissions = (params: EditRolePermissionParams) => settingsApi.editRolePermissions(params)

  getAccessControlUsers = () =>
    settingsApi
      .getAccessControlUsers()
      .then((res) => {
        if (res.success) {
          this.setAccessControlUsers(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setAccessControlUsersLoading(false))

  getCustomFields = (params: GetCustomFieldReq) =>
    settingsApi
      .getCustomFields(params)
      .then((res) => {
        if (res.success) {
          this.setCustomFields(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setCustomFieldsLoading(false))

  addStudentCustomField = (params: CustomFieldAddValue) => settingsApi.addStudentCustomField(params)

  deleteCustomField = (id: number) => settingsApi.deleteCustomField(id)

  editCustomField = (params: CustomFieldEditValue) => settingsApi.editCustomField(params)

  editRole = (params: EditRoleParams) => settingsApi.editRole(params)

  deleteRole = (id: number) => settingsApi.deleteRole(id)

  addStaffStatus = (params: AddRoleParams) => settingsApi.addStaffStatus(params)

  getStaffStatuses = () =>
    settingsApi
      .getStaffStatuses()
      .then((res) => {
        if (res.success) {
          this.setStaffStatuses(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setStaffStatusesLoading(false))

  editStaffStatus = (params: EditRoleParams) => settingsApi.editStaffStatus(params)

  deleteStaffStatus = (id: number) => settingsApi.deleteStaffStatus(id)

  getMenus = (id: number) =>
    settingsApi
      .getMenusByRoleId(id)
      .then((res) => {
        if (res.success) {
          this.setMenus(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setMenusLoading(false))

  editMenus = (params: EditMenuParams) => settingsApi.editMenus(params)

  getAcademicHours = () =>
    settingsApi
      .getAcademicHours()
      .then((res) => {
        if (res.success) {
          this.setAcademicHours(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setAcademicHoursLoading(false))

  addAcademicHours = (params: AcademicHoursItem) => settingsApi.addAcademicHours(params)

  editAcademicHours = (params: AcademicHoursEdit) => settingsApi.editAcademicHours(params)

  deleteAcademicHours = (id: number) =>
    settingsApi
      .deleteAcademicHours(id)
      .then((res) => {
        if (res.success) {
          this.setAcademicHoursLoading(true)
          this.getAcademicHours()
        }
      })
      .catch(addCatchNotification)

  getRolePermissions = (id: number) =>
    settingsApi
      .getRolePermissions(id)
      .then((res) => {
        if (res.success) {
          this.setRolePermissions(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setRolePermissionsLoading(false))

  getAcademicPrices = () =>
    settingsApi
      .getAcademicPrices()
      .then((res) => {
        if (res.success) {
          this.setAcademicPrices(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setAcademicPricesLoading(false))

  editAcademicPrice = (params: AcademicPriceEdit) => settingsApi.editAcademicPrice(params)

  addAcademicPrice = (params: AcademicPrices) => settingsApi.addAcademicPrice(params)

  deleteAcademicPrice = (id: number) =>
    settingsApi
      .deteteAcademicPrice(id)
      .then((res) => {
        if (res.success) {
          this.setAcademicPricesLoading(true)
          this.getAcademicPrices()
        }
      })
      .catch(addCatchNotification)

  getFinanceDicounts = () =>
    settingsApi
      .getFinanceDiscounts()
      .then((res) => {
        if (res.success) {
          this.setFinanceDiscounts(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setFinanceDiscountsLoading(false))

  addFinanceDiscount = (params: FinanceDiscounts) => settingsApi.addFinanceDiscount(params)

  editFinanceDiscoiunt = (params: EditFinanceDiscount) => settingsApi.editFinanceDiscoiunt(params)

  deleteFinanceDiscount = (id: number) =>
    settingsApi
      .deleteFinanceDiscount(id)
      .then((res) => {
        if (res.success) {
          this.setFinanceDiscountsLoading(true)
          this.getFinanceDicounts()
        }
      })
      .catch(addCatchNotification)

  getMobileAppVersions = (params: Partial<MobileAppVersionReqParams>) =>
    settingsApi
      .getMobileAppVersions(params)
      .then((response) => {
        if (response.success && response.data) {
          this.setMobileAppVersions(response.data)
          this.setTotalMobileVersions(response.data.total)
        }
      })
      .catch(addCatchNotification)

  changeStudentStatus = (params: ChangeStudentReq) => settingsApi.changeStudentStatus(params)

  getContract = (params: number) =>
    settingsApi
      .getContract(params)
      .then((res) => {
        if (res.success) {
          this.setSingleContractHistory(res.data)
        }
      })
      .catch(addCatchNotification)

  getContractHistory = (params: GetContractHistoryReq) =>
    settingsApi
      .getContractHistory(params)
      .then((res) => {
        if (res.success) {
          this.setContractHistory(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setContractHistoryLoading(false))

  getStudentStatuses = () =>
    settingsApi
      .getStudentStatuses()
      .then((res) => {
        if (res.success) {
          this.setStudentStatuses(res.data)
        }
      })
      .catch(addCatchNotification)

  getStudentStatus = (id: number) =>
    settingsApi
      .getStudentStatus(id)
      .then((res) => {
        if (res.success) {
          this.setStudentStatus(res.data)
        }
      })
      .catch(addCatchNotification)

  getGamificationOffer = () =>
    settingsApi
      .getGamificationOffering()
      .then((res) => {
        if (res?.success) {
          this.setGamificationOffer(res.data)
          this.setEditorContent(res?.data?.body)
        }
      })
      .catch(addCatchNotification)

  updateGamificationOffering = (params: GamificationOfferingParams) =>
    settingsApi
      .updateGamificationOffering(params)
      .then((res) => {
        if (res?.success) {
          this.isUpdateGamificationOffering = res.success
        }
      })
      .catch(addCatchNotification)

  setEditorContent = (param: string) => {
    this.editorContent = param
  }

  editStudentStatus = (params: EditStudentReq) => settingsApi.editStudentStatus(params)

  setUpdateGamificationOffering = (value: boolean) => {
    this.isUpdateGamificationOffering = value
  }

  getContracts = (params: GetContractsAll) =>
    settingsApi
      .getContracts(params)
      .then((res) => {
        if (res.success) {
          this.setContracts(res.data)
        }
      })
      .catch((err: Error | unknown) => {
        addCatchNotification(err)
      })
      .finally(() => this.setContractsLoading(false))

  getFinancePayments = (params: PaymentSegmentedValues) =>
    settingsApi
      .getFinancePayments(params)
      .then((res) => {
        if (res.success) {
          this.setFinancePaymentsTypes(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setFinancePaymentsLoading(false))

  addFinancePayments = (params: FinancePaymentsData) => settingsApi.addFinancePayments(params)

  editFinancePayments = (params: EditFinancePayments) => settingsApi.editFinancePayments(params)

  deleteFinancePayments = (params: DeleteFinancePayments) =>
    settingsApi
      .deleteFinancePayments(params)
      .then((res) => {
        if (res.success) {
          this.setFinancePaymentsLoading(true)
          this.getFinancePayments(this.paymentSegmentedValue)
        }
      })
      .catch(addCatchNotification)

  getCourses = () =>
    settingsApi
      .getCourses()
      .then((res) => {
        if (res.success) {
          this.setCourses(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setCoursesLoading(false))

  addCourses = (params: Courses) => settingsApi.addCourses(params)

  editCourses = (params: CoursesEdit) => settingsApi.editCourses(params)

  deleteCourses = (id: number) =>
    settingsApi
      .deleteCourses(id)
      .then((res) => {
        if (res.success) {
          this.setCoursesLoading(true)
          this.getCourses()
        }
      })
      .catch(addCatchNotification)

  getCategories = () =>
    settingsApi
      .getCategories()
      .then((res) => {
        if (res.success) {
          this.setCategories(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setCategoriesLoading(false))

  addCategories = (params: Categories) => settingsApi.addCategories(params)

  editCategories = (params: CategoriesEdit) => settingsApi.editCategories(params)

  deleteCategories = (id: number) =>
    settingsApi
      .deleteCategories(id)
      .then((res) => {
        if (res.success) {
          this.setCategoriesLoading(true)
          this.getCategories()
        }
      })
      .catch(addCatchNotification)

  getLearningPayments = () =>
    settingsApi
      .getLearningPayments()
      .then((res) => {
        if (res.success) {
          this.setLearningPayments(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setLearningPaymentsLoading(false))

  addLearningPayments = (params: LearningPayments) => settingsApi.addLearningPayments(params)

  editLearningPayments = (params: LearningPaymentsEdit) => settingsApi.editLearningPayments(params)

  deleteLearningPayments = (id: number) =>
    settingsApi
      .deleteLearningPayments(id)
      .then((res) => {
        if (res.success) {
          this.setLearningPaymentsLoading(true)
          this.getLearningPayments()
        }
      })
      .catch(addCatchNotification)

  getLocation = () =>
    settingsApi
      .getLocation()
      .then((res) => {
        if (res.success) {
          this.setLocationData(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setLocationTableLoading(false))

  addLocation = (params: Location) => settingsApi.addLocation(params)

  editLocation = (params: LocationEdit) => settingsApi.editLocation(params)

  deleteLocation = (id: number) =>
    settingsApi
      .deleteLocation(id)
      .then((res) => {
        if (res.success) {
          this.setLocationTableLoading(true)
          this.getLocation()
        }
      })
      .catch(addCatchNotification)

  getExpenses = () =>
    settingsApi
      .getExpenses()
      .then((res) => {
        if (res.success) {
          this.setExpensesData(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setExpensesTableLoading(false))

  addExpenses = (params: Expenses) => settingsApi.addExpenses(params)

  editExpenses = (params: ExpensesEdit) => settingsApi.editExpenses(params)

  deleteExpenses = (id: number) =>
    settingsApi
      .deleteExpenses(id)
      .then((res) => {
        if (res.success) {
          this.setExpensesTableLoading(true)
          this.getExpenses()
        }
      })
      .catch(addCatchNotification)

  reset = () => {
    this.isUpdateGamificationOffering = false
    this.isGamificationModal = false
    this.editorContent = ""
    this.isNewVersionModal = false
    this.totalMobileAppVersions = 0
    this.mobileVersionsPage = 1
    this.mobileVersionsPerPage = 10
    this.expensesModal = false
    this.expensesTableLoading = false
    this.expensesData = null
    this.expensesEditData = null
  }
}

export const settingsStore = new SettingsStore()
