export const analyticsDictionary = {
  analytics: "Hisobotlar",
  audienceAvailabilty: "Auditoriyalar bandligi",
  teachersAvailability: "O`qituvchilar bandligi",
  daysOfWeek: "Hafta kunlari",
  hideZeros: "Nollarni yashirish",
  direction: "Yo‘nalish",
  classPeriod: "Dars davri:",
  groupsAndTours: "Guruhlar va sayohatlar",
  classPeriodForComparison: "Taqqoslash uchun dars davri",
  analyticsAmount: "Miqdori",
  teacherStatistics: "O`qituvchi statistikasi",
  hours: "Soatlar",
  calculated: "Hisoblangan",
  studyType: "O`quv turi",
  startLessonAndEndMonth: "Darslar boshlanish va tugash oyi",
  teacherStatus: "O‘qituvchi status",
}
