import { observer } from "mobx-react"
import { Flex } from "antd"
import classNames from "classnames/bind"
import { Info } from "./Info"

import styles from "./teacher.module.scss"

const cn = classNames.bind(styles)

export const Teacher = observer(() => (
  <Flex className={cn("teacher")} gap={20} justify="space-between" align="center">
    <Info />
  </Flex>
))
