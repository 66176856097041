import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { Button, Flex, Typography } from "antd"
import classNames from "classnames/bind"
import mainDictionary from "@/dictionary"
import { addCatchNotification } from "@/modules/notifications"
import { useStores } from "@/stores"
import { MakeSchedule } from "./MakeSchedule"
import { RowItem } from "./RowItem"

import styles from "./schedule.module.scss"

const cn = classNames.bind(styles)

export const Schedule = observer(() => {
  const { groupsStore } = useStores()
  const { id } = useParams()

  const handleAddSchedule = () => {
    groupsStore.setScheduleModal(true)
  }

  useEffect(() => {
    if (groupsStore.groupSchedule?.total) {
      groupsStore.groupSchedule.scheduleItems.forEach((schedule) => {
        groupsStore.setAcademicScheduleSchemaOption({
          ...groupsStore.academicScheduleSchemaOption,
          [schedule?.id]: {
            id: schedule.id,
            page: groupsStore.academicScheduleSchemaOption?.[schedule.id]?.page || 1,
            perPage: groupsStore.academicScheduleSchemaOption?.[schedule.id]?.perPage || 19,
            academicId: schedule.staff?.[0]?.id,
          },
        })

        groupsStore
          .getAcademicScheduleSchema({
            id: schedule.id,
            page: groupsStore.academicScheduleSchemaOption?.[schedule.id]?.page || 1,
            perPage: groupsStore.academicScheduleSchemaOption?.[schedule.id]?.perPage || 19,
            academicId: schedule.staff?.[0]?.id,
          })
          .then((res) => {
            if (res.success) {
              groupsStore.setAcademicScheduleSchema({
                ...groupsStore.academicScheduleSchema,
                [schedule.id]: res.data,
              })
            }
          })
          .catch(addCatchNotification)
      })
    }
  }, [groupsStore.groupSchedule?.scheduleItems])

  useEffect(() => {
    if (id) {
      const parsedId = parseInt(id, 10)

      groupsStore.setGroupScheduleLoading(true)
      groupsStore.getGroupSchedule({
        page: 1,
        perPage: 100,
        groupId: parsedId,
      })
    }
  }, [])

  return (
    <Flex gap={40} vertical className={cn("schedule")}>
      <Flex justify="space-between">
        <Typography.Title level={5}>{mainDictionary.schedule}</Typography.Title>

        <Button onClick={handleAddSchedule} type="primary">
          {mainDictionary.add}
        </Button>
      </Flex>

      <Flex vertical gap={35}>
        {groupsStore?.groupSchedule?.scheduleItems?.map((item) => <RowItem {...item} key={item.id} />)}
      </Flex>

      {groupsStore.scheduleModal && <MakeSchedule />}
    </Flex>
  )
})
