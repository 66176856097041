import { useNavigate } from "react-router-dom"
import { Flex, Tag, Typography } from "antd"
import classNames from "classnames/bind"
import { NotificationActionTypes, NotificationBodyTypes } from "@/api/notifications"
import { ROUTES } from "@/constants"
import { addCatchNotification } from "@/modules/notifications"
import { GroupPageState } from "@/pages/Groups"
import { useStores } from "@/stores"
import { groupsStore } from "@/stores/groups"
import { formatDate } from "@/utils"

import styles from "./notification.module.scss"

const cx = classNames.bind(styles)

type Props = {
  title: string
  isRead: boolean
  id: number
  createdAt?: string
  bodyType: NotificationBodyTypes
  notificationActionType?: NotificationActionTypes
  body?: string
}

export const Notification = ({ title, isRead, id, createdAt, bodyType, notificationActionType, body }: Props) => {
  return (
    <div className={cx("notification", { "not-read": !isRead })}>
      <Flex align="center" justify="space-between" className={cx("notification__wrapper")}>
        <Flex vertical gap={5}>
          <h4 className={cx("notification__title", { "not-read": !isRead })}>{title}</h4>

          <Typography.Text type="secondary">{formatDate(createdAt, undefined, true)}</Typography.Text>
        </Flex>

        <Tag color="red" className={cx("notification__badge", { "not-read": isRead })} />
      </Flex>
    </div>
  )
}
