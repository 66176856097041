import { useEffect, useMemo } from "react"
import { observer } from "mobx-react"
import { Flex, Tabs } from "antd"
import mainDictionary from "@/dictionary"
import { useStores } from "@/stores"
import { GroupPageState } from "../types"
import { GroupTop } from "./GroupTop"
import { ActionsHistory, GroupInfo, GroupLessons, Students, TeacherAttendance } from "./Tabs"

export const GroupSingle = observer(() => {
  const { groupsStore } = useStores()

  const groupTabs = useMemo(
    () => [
      {
        label: mainDictionary.infos,
        key: GroupPageState.GroupInfo,
        children: <GroupInfo />,
      },
      {
        label: mainDictionary.students,
        key: GroupPageState.Students,
        children: <Students />,
      },
      {
        label: mainDictionary.groupLessons,
        key: GroupPageState.GroupLessons,
        children: <GroupLessons />,
      },
      {
        label: mainDictionary.teacherAttendance,
        key: GroupPageState.TeacherAttendance,
        children: <TeacherAttendance />,
      },
      {
        label: mainDictionary.actionsArchive,
        key: GroupPageState.ActionsArchive,
        children: <ActionsHistory />,
      },
    ],
    [],
  )

  useEffect(
    () => () => {
      groupsStore.resetGroupSingle()
    },
    [],
  )

  return (
    <Flex vertical gap={36}>
      <GroupTop />

      <Tabs items={groupTabs} defaultActiveKey={GroupPageState.GroupInfo} />
    </Flex>
  )
})
