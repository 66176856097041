import "./helper.css"

import { observer } from "mobx-react"
import { DatePicker, Divider, Form, Modal, Select } from "antd"
import classNames from "classnames/bind"
import mainDictionary from "@/dictionary"
import { useStores } from "@/stores"

import styles from "./make-student.module.scss"

const cn = classNames.bind(styles)

export const MakeStudent = observer(() => {
  const { groupsStore } = useStores()
  const [form] = Form.useForm()

  const handleCancel = () => {
    groupsStore.setMakeStudentModal(false)
  }

  return (
    <Modal
      width={600}
      onOk={form.submit}
      okText={mainDictionary.save}
      cancelText={mainDictionary.cancel}
      onCancel={handleCancel}
      title={mainDictionary.addStudent}
      open={groupsStore.makeStudentModal}
      className={cn("make-student")}
    >
      <Divider />

      <Form form={form}>
        <Form.Item label={mainDictionary.branch} name="branch">
          <Select placeholder={mainDictionary.choose} className={cn("make-student__input")} />
        </Form.Item>

        <Form.Item label={mainDictionary.nameId} name="studentId">
          <Select showSearch placeholder={mainDictionary.choose} className={cn("make-student__input")} />
        </Form.Item>

        <Form.Item label={mainDictionary.period} name="period">
          <DatePicker.RangePicker className={cn("make-student__input")} />
        </Form.Item>
      </Form>
    </Modal>
  )
})
