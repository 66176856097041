import { useMemo } from "react"
import { observer } from "mobx-react"
import { Button, Flex, Popconfirm, Table, TableProps, Typography } from "antd"
import classNames from "classnames/bind"
import { StudentDuplications } from "@/api/groups"
import ExchangeIcon from "@/assets/icons/exchange.svg"
import mainDictionary from "@/dictionary"
import { addCatchNotification, addSuccessNotification } from "@/modules/notifications"
import { getPaginationParams } from "@/pages/utils"
import { useStores } from "@/stores"
import { MatchTypes } from "../../constants"

import styles from "./table.module.scss"

const cn = classNames.bind(styles)

export const DuplicationsTable = observer(() => {
  const { groupsStore } = useStores()

  const handleMergeClick = (item: StudentDuplications) => {
    groupsStore.setStudentDuplicationsLoading(true)
    groupsStore
      .mergeDuplicate({
        primaryStudentId: item.primaryId,
        secondaryStudentId: item.secondaryId,
      })
      .then((res) => {
        if (res.success) {
          addSuccessNotification(mainDictionary.successFullySaved)
          groupsStore.getStudentDuplications(groupsStore.studentDuplicationsFilterOptions)
        }
      })
      .catch(addCatchNotification)
  }

  const handleIgnoreClick = (item: StudentDuplications) => {
    groupsStore.setStudentDuplicationsLoading(true)
    groupsStore
      .ignoreDuplicate({
        primaryStudentId: item.primaryId,
        secondaryStudentId: item.secondaryId,
      })
      .then((res) => {
        if (res.success) {
          addSuccessNotification(mainDictionary.successFullySaved)
          groupsStore.getStudentDuplications(groupsStore.studentDuplicationsFilterOptions)
        }
      })
      .catch(addCatchNotification)
  }

  const columns: TableProps<StudentDuplications>["columns"] = useMemo(
    () => [
      {
        title: mainDictionary.mainText,
        render: (item: StudentDuplications) => (
          <Flex vertical>
            <Typography.Text>{`${item.primaryFirstName} ${item.primaryLastName}`}</Typography.Text>
            <Typography.Text>+{item.primaryPhone}</Typography.Text>
            <Typography.Text>{item.primaryEmail}</Typography.Text>
          </Flex>
        ),
      },
      {
        width: 100,
        align: "center",
        render: () => (
          <img className={cn("table__exchange")} src={ExchangeIcon} alt="exchange" width={24} height={24} />
        ),
      },
      {
        title: mainDictionary.additionalText,
        render: (item: StudentDuplications) => (
          <Flex vertical>
            <Typography.Text>{`${item.secondaryFirstName} ${item.secondaryLastName}`}</Typography.Text>
            <Typography.Text>+{item.secondaryPhone}</Typography.Text>
            <Typography.Text>{item.secondaryEmail}</Typography.Text>
          </Flex>
        ),
      },
      {
        title: mainDictionary.matches,
        width: 200,
        align: "center",
        render: (item: StudentDuplications) => <span>{MatchTypes[item.match as keyof typeof MatchTypes]}</span>,
      },
      {
        render: (item: StudentDuplications) => (
          <Flex justify="flex-end" gap={20}>
            <Popconfirm
              placement="left"
              title={mainDictionary.areYouSureToIgnore}
              onConfirm={handleIgnoreClick.bind(null, item)}
            >
              <Button size="small" className={cn("table__copy")}>
                {mainDictionary.dontCopy}
              </Button>
            </Popconfirm>

            <Popconfirm
              placement="left"
              title={mainDictionary.areYouSureToMerge}
              onConfirm={handleMergeClick.bind(null, item)}
            >
              <Button size="small" type="primary">
                {mainDictionary.upload}
              </Button>
            </Popconfirm>
          </Flex>
        ),
      },
    ],
    [],
  )

  const handlePaginationChange = (page: number, pageSize: number) => {
    groupsStore.setStudentDuplicationsFilterOptions({
      ...groupsStore.studentDuplicationsFilterOptions,
      page,
      perPage: pageSize,
    })
  }

  return (
    <div className={cn("table")}>
      <Table
        loading={groupsStore.studentDuplicationsLoading}
        columns={columns}
        dataSource={groupsStore.studentDuplications?.duplicates}
        pagination={{
          ...getPaginationParams({
            total: groupsStore.studentDuplications?.total,
          }),
          pageSize: groupsStore.studentDuplicationsFilterOptions.perPage,
          current: groupsStore.studentDuplicationsFilterOptions.page,
          onChange: handlePaginationChange,
        }}
      />
    </div>
  )
})
