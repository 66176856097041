import { makeAutoObservable } from "mobx"
import { ClassRoom } from "@/api/groups"
import { addCatchNotification } from "@/modules/notifications"
import { branchOfficeApi } from "@/pages/Controls/BranchOffice/shared/branch-office.api"
import { BranchOffice, HhOffice } from "@/pages/Controls/BranchOffice/types"
import { GetClassRoomsReq } from "@/stores/groups"
import { AddRoomType, EditRoomType, SingleBranchTabs } from "./types"

class BranchOfficeStore {
  branchOffices: BranchOffice[] | null = null
  hhOffices: HhOffice[] | null = null
  inActionBranchOffice: BranchOffice | null = null
  isOpenAddModal: boolean = false
  isOpenDeleteModal: boolean = false
  selectedBranchInfoTab: string = SingleBranchTabs.Rooms?.toString()
  branchStaffsLoading = false
  branchRoomLoading = false
  selectedRoom: ClassRoom | null = null
  makeBranchRoomModal = false
  branchPagination: GetClassRoomsReq = {
    page: 1,
    perPage: 10,
  }

  constructor() {
    makeAutoObservable(this)
  }

  setMakeBranchRoomModal = (params: boolean) => {
    this.makeBranchRoomModal = params
  }

  setSelectedRoom = (params: ClassRoom | null) => {
    this.selectedRoom = params
  }

  setBranchRoomLoading = (params: boolean) => {
    this.branchRoomLoading = params
  }

  setBranchPagination = (params: GetClassRoomsReq) => {
    this.branchPagination = params
  }

  setSelectedBranchInfoTab = (selectedBranchInfoTab: string) => {
    this.selectedBranchInfoTab = selectedBranchInfoTab
  }

  setBranchStaffsLoading = (branchStaffsLoading: boolean) => {
    this.branchStaffsLoading = branchStaffsLoading
  }

  getBranchOffices = () =>
    branchOfficeApi.getOffices().then((response) => {
      if (response.success) {
        this.setBranchOffices(response.data.offices)
      }
    })

  editRoom = (params: EditRoomType) => branchOfficeApi.editRoom(params)

  addRoom = (params: AddRoomType) => branchOfficeApi.addRoom(params)

  deleteRoom = (id: number) => branchOfficeApi.deleteRoom(id)

  getOfficesHh = () =>
    branchOfficeApi
      .getOfficesHh()
      .then((response) => {
        if (response.success) {
          this.setHhOffices(response.data.hOffices)
        }
      })
      .catch(addCatchNotification)

  setBranchOffices = (branchOffices: BranchOffice[]) => {
    this.branchOffices = branchOffices
  }

  setHhOffices = (hhOffices: HhOffice[]) => {
    this.hhOffices = hhOffices
  }

  setOpenAddModalStatus = (status: boolean) => {
    this.isOpenAddModal = status
  }

  setOpenDeleteModalStatus = (status: boolean) => {
    this.isOpenDeleteModal = status
  }

  setInActionBranchOffice = (inActionBranchOffice: BranchOffice | null) => {
    this.inActionBranchOffice = inActionBranchOffice
  }

  reset = () => {
    this.branchOffices = null
    this.hhOffices = null
    this.inActionBranchOffice = null
    this.isOpenAddModal = false
    this.isOpenDeleteModal = false
  }
}

export const branchOfficeStore = new BranchOfficeStore()
