import { CustomFieldType } from "@/api/settings"
import mainDictionary from "@/dictionary"

export enum IsAuto {
  Active = 1,
  InActive,
}

export enum TabsKeys {
  CurrentOffer = "1",
  History = "2",
}

export const fulfillmentTypeOptions = [
  {
    label: mainDictionary.auto,
    value: IsAuto.Active,
  },
  {
    label: mainDictionary.byHand,
    value: IsAuto.InActive,
  },
]

export const reasons = [
  {
    label: mainDictionary.groupOver,
    value: 1,
  },
  {
    label: mainDictionary.finishThemSelves,
    value: 2,
  },
  {
    label: mainDictionary.finishGroupAndThemselves,
    value: 3,
  },
  {
    label: mainDictionary.didNotStudy,
    value: 4,
  },
  {
    label: mainDictionary.debtor,
    value: 5,
  },
]

export const faceIdTypes = [
  {
    label: mainDictionary.willDelete,
    value: IsAuto.InActive,
  },
  {
    label: mainDictionary.notBlocked,
    value: IsAuto.Active,
  },
]

export const statisticsCondition = [
  {
    label: mainDictionary.count,
    value: IsAuto.Active,
  },
  {
    label: mainDictionary.notCount,
    value: IsAuto.InActive,
  },
]

export const ALLOWED_BODY = {
  ALLOWED_TAGS: ["b", "i", "em", "strong", "p", "h1", "h2", "ul", "li", "a"],
  ALLOWED_ATTR: ["href", "target"],
}

export enum RoleTabs {
  StateOfStaff = 1,
  Roles,
  RolePermissions,
  Menu,
}

export const customFieldTypes = [
  {
    label: CustomFieldType.String,
    value: CustomFieldType.String,
  },
  {
    label: CustomFieldType.Number,
    value: CustomFieldType.Number,
  },
  {
    label: CustomFieldType.Boolean,
    value: CustomFieldType.Boolean,
  },
  {
    label: CustomFieldType.Array,
    value: CustomFieldType.Array,
  },
  {
    label: CustomFieldType.Text,
    value: CustomFieldType.Text,
  },
  {
    label: CustomFieldType.Date,
    value: CustomFieldType.Date,
  },
]
