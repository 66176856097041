import { useEffect } from "react"
import { observer } from "mobx-react"
import { Flex } from "antd"
import classNames from "classnames/bind"
import { useBoolean } from "usehooks-ts"
import { Button } from "@/ant"
import { GroupsEndpoints } from "@/api/groups/endpoints"
import mainDictionary from "@/dictionary"
import { KinescopeAddVideoModal } from "@/modules/kinescope"
import { permissionObjectTypes, PermissionOptions, useCheckPermission } from "@/modules/permission"
import { useStores } from "@/stores"
import { GroupByOrder, VIDEO_KEYS } from "@/stores/groups"

import styles from "./video-top.module.scss"

const cn = classNames.bind(styles)

type Props = {
  groupId: number
  pathname: string
  pageName?: string
}

export const VideoTop = observer(({ groupId, pathname, pageName }: Props) => {
  const { groupsStore } = useStores()
  const {
    value: isVisibleAddVideo,
    setFalse: setVisibleAddVideoFalse,
    toggle: visibleAddVideoToggle,
  } = useBoolean(false)

  const [isAvailableAdd] = useCheckPermission({
    // TODO
    module: permissionObjectTypes.allGroups,
    option: PermissionOptions.Create,
  })
  const [isAvailableMyGroupsAdd] = useCheckPermission({
    module: permissionObjectTypes.myGroupsVideoUpload,
    option: PermissionOptions.Create,
  })

  const handleOpenAddModal = () => {
    visibleAddVideoToggle()
  }

  useEffect(() => {
    groupsStore.setCurrentVideosPage(1)
    groupsStore.setVideosPage(1)
  }, [])

  useEffect(() => {
    groupsStore.getLessons({
      id: groupId.toString(),
      orderBy: GroupByOrder.Desc,
    })
  }, [])

  return (
    <div className={cn("videos-top__wrapper")}>
      {isVisibleAddVideo && (
        <KinescopeAddVideoModal
          groupId={groupId}
          onClose={setVisibleAddVideoFalse}
          endpoint={{ creat: GroupsEndpoints.Video, update: GroupsEndpoints.Video }}
          pageLink={pathname}
          pageName={pageName}
        />
      )}

      <Flex justify="flex-end">
        {(isAvailableAdd || isAvailableMyGroupsAdd) && (
          <Button type="primary" onClick={handleOpenAddModal} disabled={isVisibleAddVideo}>
            {mainDictionary.add}
          </Button>
        )}
      </Flex>
    </div>
  )
})
