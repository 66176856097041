import { Dispatch, SetStateAction } from "react"
import { makeAutoObservable } from "mobx"
import {
  Category,
  Certificate,
  ChangeStudentLogin,
  Contracts,
  FilterResponse,
  IGetStudentGroupsRes,
  IGetXlsFileResponse,
  IStudents,
  IStudentSmsHistory,
  SingleStudentParams,
  StatusResponse,
  StudentCustomField,
  StudentParams,
} from "@/api/students"
import { studentApi } from "@/api/students/students"
import { addCatchNotification } from "@/modules/notifications"
import { StudentStatus } from "@/pages/Users/Students/types"
import { OrderBy } from "@/utils"
import { OnDownloadProgressType } from "../groups/types"
import {
  ChangeCertificateParams,
  ChangeStudentPrivateInfoReq,
  downloadOptionType,
  EditStudentCustomFieldData,
  GetScheduleInfoParams,
  GetStudentCertificateReq,
  IChangeStudentStatus,
  IGetStudentGroups,
  KeyType,
  ResendContractParams,
  SelfContractData,
  TStudentInfo,
} from "./types"

class Student {
  perPage = 10
  page = 1
  keys: KeyType = ["firstName", "lastName", "hId", "middleName"]
  value = ""
  orderBy: OrderBy = OrderBy.ASC
  categoryId: number | undefined = undefined
  total = 0
  statusId: number | undefined = 1
  systemStatus: StudentStatus | null = StudentStatus.Active
  students: IStudents[] = []
  singleStudent: IStudents | null = null
  studentGroup: number | null = null
  officeIds: number[] | null = null
  filterOffices: FilterResponse[] | null = null
  filterGroups: FilterResponse[] | null = null
  filterCourses: FilterResponse[] | null = null

  categories: Category[] = []
  changeLogin = false
  changePassword = false
  studentSmsHistoryModal = false
  studentSmsSendModal = false
  studentSmsHistory: IStudentSmsHistory | null = null
  studentStatus: StatusResponse[] | [] = []
  singleStudentCategory: Category[] | [] = []
  hasPhoto: boolean | null = null
  addToGroupModal = false
  studentGroups: IGetStudentGroupsRes | null = null
  privateInfoModal = false
  downloadModal = false
  downloadableFiles: IGetXlsFileResponse | null = null
  hasPrivateInfo: boolean | null = null
  studentSchedules = false
  infoActions = false
  certificates: Certificate[] | null = null
  infoActionModal = false
  infoRes: string | null = null
  isGeneralInfo = false
  isCreatingStudent = false
  contractConfirmationModal = false
  selfContractModal = false
  agentControlModal = false
  studentContracts: Contracts[] = []
  studentContractsLoader = false
  additionalFilter = false
  singleStudentLoader = false
  returnModal = false
  addPaymentModal = false
  additionalSpaceFormDisabled = true
  studentCustomFields: StudentCustomField[] | null = null
  studentCustomFieldsLoader = false

  constructor() {
    makeAutoObservable(this)
  }

  setAdditionalSpaceFormDisabled = (params: boolean) => {
    this.additionalSpaceFormDisabled = params
  }

  setStudentCustomFieldsLoader = (params: boolean) => {
    this.studentCustomFieldsLoader = params
  }

  setStudentCustomFields = (params: StudentCustomField[] | null) => {
    this.studentCustomFields = params
  }

  setAddPaymentModal = (params: boolean) => {
    this.addPaymentModal = params
  }

  setReturnModal = (params: boolean) => {
    this.returnModal = params
  }

  setSingleStudentLoader = (params: boolean) => {
    this.singleStudentLoader = params
  }

  setAdditionalFilter = (params: boolean) => {
    this.additionalFilter = params
  }

  setStudentContractsLoader = (params: boolean) => {
    this.studentContractsLoader = params
  }

  setStudentContracts = (params: Contracts[]) => {
    this.studentContracts = params
  }

  setSelfContractModal = (params: boolean) => {
    this.selfContractModal = params
  }

  setAgentControlModal = (params: boolean) => {
    this.agentControlModal = params
  }

  setContractConfirmationModal = (params: boolean) => {
    this.contractConfirmationModal = params
  }

  setInfoActionModal = (params: boolean) => {
    this.infoActionModal = params
  }

  setInfoRes = (params: string | null) => {
    this.infoRes = params
  }

  setInfoActions = (params: boolean) => {
    this.infoActions = params
  }

  setStudentSchedules = (params: boolean) => {
    this.studentSchedules = params
  }

  setCertificates = (params: Certificate[] | null) => {
    this.certificates = params
  }

  setFilterOffices = (params: FilterResponse[] | null) => {
    this.filterOffices = params
  }

  setFilterCourses = (params: FilterResponse[] | null) => {
    this.filterCourses = params
  }

  setFilterGroups = (params: FilterResponse[] | null) => {
    this.filterGroups = params
  }

  setHasPrivateInfo = (params: boolean | null) => {
    this.hasPrivateInfo = params
  }

  setDownloadableFiles = (params: IGetXlsFileResponse | null) => {
    this.downloadableFiles = params
  }

  setDownload = (params: boolean) => {
    this.downloadModal = params
  }

  setPrivateInfoModal = (params: boolean) => {
    this.privateInfoModal = params
  }

  resetStudentGroups = () => {
    this.studentGroups = null
  }

  sendContract = (params: SelfContractData) => studentApi.sendContract(params)

  resetFilter = () => {
    this.page = 1
    this.value = ""
    this.categoryId = undefined
    this.studentGroup = null
    this.officeIds = null
    this.statusId = StudentStatus.Active
    this.systemStatus = StudentStatus.Active
    this.hasPhoto = null
    this.hasPrivateInfo = null
  }

  setAddToGroupModal = (params: boolean) => {
    this.addToGroupModal = params
  }

  setStudentGroup = (params: number) => {
    this.studentGroup = params
  }

  setOfficeIds = (params: number[] | null) => {
    this.officeIds = params
  }

  resendContract = (params: ResendContractParams) => studentApi.resendContract(params)

  getContracts = (params: number) =>
    studentApi
      .getContracts(params)
      .then((res) => {
        if (res.success) {
          this.setStudentContracts(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setStudentContractsLoader(false))

  setStudentStatuses = (params: StudentStatus | null) => {
    this.systemStatus = params
  }

  updateStudent = (params: TStudentInfo) => studentApi.updateStudentStatus(params)

  setStudentGroups = (studentGroups: IGetStudentGroupsRes | null) => {
    this.studentGroups = studentGroups
  }

  setIsGeneralInfo = (value: boolean) => {
    this.isGeneralInfo = value
  }

  setIsCreatingStudent = (value: boolean) => {
    this.isCreatingStudent = value
  }

  changeStudentPrivateInfo = (params: ChangeStudentPrivateInfoReq) => studentApi.changeStudentInfo(params)

  getStudentGroups = (params: IGetStudentGroups) =>
    studentApi
      .getStudentGroup(params)
      .then((res) => {
        if (res.success) {
          this.setStudentGroups(res.data)
        }
      })
      .catch(addCatchNotification)

  getStudents = (params: StudentParams) =>
    studentApi
      .getStudents(params)
      .then((res) => {
        if (res.success) {
          this.setStudents(res.data.students)
          this.setTotal(res.data.total)
        }

        return res
      })
      .catch(addCatchNotification)

  getSingleStudent = (params: SingleStudentParams) =>
    studentApi
      .getSingleStudent(params)
      .then((res) => {
        if (res.success) {
          this.setSingleStudent(res.data)
        }

        return res
      })
      .catch(addCatchNotification)
      .finally(() => this.setSingleStudentLoader(false))

  getCategories = () =>
    studentApi
      .getCategory()
      .then((res) => {
        if (res.success) {
          this.setCategories(res.data.categories)
          this.setStatus(res.data.statuses)
          this.setFilterGroups(res.data?.groups || null)
          this.setFilterOffices(res.data?.offices || null)
          this.setFilterCourses(res.data?.courses || null)
        }

        return res
      })
      .catch(addCatchNotification)

  getScheduleInfo = (params: GetScheduleInfoParams) =>
    studentApi
      .getScheduleInfoParams(params)
      .then((res) => {
        if (res.success) {
          this.setInfoRes(res.data)
        }
      })
      .catch(addCatchNotification)

  getStudentCustomFields = (id: number) =>
    studentApi
      .getStudentCustomFields(id)
      .then((res) => {
        if (res.success) {
          this.setStudentCustomFields(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setStudentCustomFieldsLoader(false))

  getStudentCertificate = (params: GetStudentCertificateReq) =>
    studentApi
      .getStudentCertificates(params)
      .then((res) => {
        if (res.success) {
          this.setCertificates(res.data.certificates)
        }
      })
      .catch(addCatchNotification)

  getXlsFiles = async (params?: StudentParams, setPercent?: Dispatch<SetStateAction<number>>) => {
    const onDownloadProgress: OnDownloadProgressType = (e) => {
      const percent = Math.floor((e.loaded / (e?.event?.total || 1)) * 100)

      setPercent?.(percent)
    }

    const options: downloadOptionType = { responseType: "json", onDownloadProgress }
    const response = await studentApi.getXlsFiles(params, options)

    return response
  }

  changeStudentCertificate = (params: ChangeCertificateParams) => studentApi.changeStudentCertificate(params)

  getStudentSmsHistory = (id: number) =>
    studentApi
      .getStudentSmsHistory(id)
      .then((res) => {
        if (res.success) {
          this.setStudentHistory(res.data)
        }

        return res
      })
      .catch(addCatchNotification)

  setTotal = (total: number) => {
    this.total = total
  }

  setStudentHistory = (params: IStudentSmsHistory) => {
    this.studentSmsHistory = params
  }

  setCategories = (params: Category[]) => {
    this.categories = params
  }

  setStatus = (params: StatusResponse[]) => {
    this.studentStatus = params
  }

  setStatusId = (id: number) => {
    if (!id) {
      this.statusId = undefined

      return
    }

    this.statusId = id
  }

  setSingleStudentCategory = (category: Category[]) => {
    this.singleStudentCategory = category
  }

  changeStudentLogin = (params: ChangeStudentLogin) => studentApi.changeLogin(params)

  resetPassword = (params: SingleStudentParams) => studentApi.resetPassword(params)

  setStudents = (params: IStudents[]) => {
    this.students = params
  }

  setCategoryId = (id: number) => {
    if (!id) {
      this.categoryId = undefined

      return
    }

    this.categoryId = id
  }

  setSearchValue = (text: string) => {
    this.value = text
  }

  setSingleStudent = (params: IStudents | null) => {
    this.singleStudent = params
  }

  setChangeLogin = (params: boolean) => {
    this.changeLogin = params
  }

  setChangePassword = (params: boolean) => {
    this.changePassword = params
  }

  setStudentSmsHistoryModal = (params: boolean) => {
    this.studentSmsHistoryModal = params
  }

  setStudentSmsSendModal = (params: boolean) => {
    this.studentSmsSendModal = params
  }

  setStudentId = (params: IStudents | null) => {
    this.singleStudent = params
  }

  editStudentCustomField = (params: EditStudentCustomFieldData) => studentApi.editStudentCustomField(params)

  changeStudentStatus = (params: IChangeStudentStatus) => studentApi.changeStudentStatus(params)

  setPage = (page: number) => {
    this.page = page
  }

  setPerPage = (perPage: number) => {
    this.perPage = perPage
  }

  setStudentPhoto = (value: boolean | null) => {
    this.hasPhoto = value
  }

  reset = () => {
    this.categories = []
    this.categoryId = undefined
    this.changeLogin = false
    this.changePassword = false
    this.orderBy = OrderBy.ASC
    this.page = 1
    this.perPage = 10
    this.singleStudent = null
    this.singleStudentCategory = []
    this.statusId = undefined
    this.students = []
    this.studentSmsHistory = null
    this.studentSmsHistoryModal = false
    this.studentSmsSendModal = false
    this.studentStatus = []
    this.systemStatus = null
    this.total = 0
    this.value = ""
    this.isGeneralInfo = false
    this.isCreatingStudent = false
  }
}

export const studentStore = new Student()
