import { observer } from "mobx-react"
import { Avatar, Flex, Typography } from "antd"
import classNames from "classnames/bind"
import mainDictionary from "@/dictionary"
import { useStores } from "@/stores"

import styles from "./group-mentors.module.scss"
const cn = classNames.bind(styles)

export const GroupMentors = observer(() => {
  const { groupsStore } = useStores()

  return (
    <Flex vertical gap={12} className={cn("group-mentors")}>
      <Typography.Title level={4}>{mainDictionary.groupMentors}</Typography.Title>

      <Flex gap={23} wrap>
        {groupsStore?.groupInfo?.staff?.map((item) =>
          item.staff?.map((staff) => (
            <Flex align="center" gap={12} key={staff.id} vertical>
              <Avatar size={60} src="https://avatars.githubusercontent.com/u/12314121?v=4" />

              <Flex vertical gap={4}>
                <Typography.Text className={cn("group-mentors__position")}>{staff.role?.name}</Typography.Text>
                <Typography.Text className={cn("group-mentors__name")}>
                  {staff.firstName} <br /> {staff.lastName}
                </Typography.Text>
              </Flex>
            </Flex>
          )),
        )}
      </Flex>
    </Flex>
  )
})
