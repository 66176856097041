import {
  ChangeAcademicHourParams,
  DuplicationsActionReq,
  EditExamInfo,
  EditScheduleReq,
  GetClassRoomsReq,
  GetExamChatsReq,
  GetExamsReq,
  GetGroupAcademicHours,
  GetGroupSchedulesReq,
  GetGroupStudentsReq,
  GetHomeworkReq,
  GetStudentDuplicationsReq,
  GetStudentSchemaReq,
  GetTeacherAttendanceReq,
  GetTeacherScheduleSchemaReq,
  GroupEditParams,
  GroupMakeFormValues,
  IGetGroupsRequest,
  IGetHomeworks,
  IGetHomeworkUploadUrl,
  IGetIncompleteStudentsRequest,
  IGetJournalsReq,
  IGetLessonsRequest,
  IGetVideosRequest,
  IUpdateVideoRequest,
  MakeExamInfo,
  PriceRequest,
  ScheduleItemFormValues,
} from "@/stores/groups"
import { Endpoints } from "../endpoints"
import { INetworkConfig, Instance } from "../instance"
import { AcademicPrices } from "../settings"
import { IStudents } from "../students"
import { IResponse } from "../types"
import { GroupsEndpoints } from "./endpoints"
import {
  ClassRoomsRes,
  DateSchemaRes,
  Exam,
  ExamChat,
  GetExamRes,
  GetHomeworkRes,
  GetJournalListRes,
  GetTeacherAttendanceRes,
  GroupAcademicHour,
  GroupDetail,
  GroupInfo,
  GroupParameters,
  GroupScheduleRes,
  GroupStatistics,
  GroupStudentsRes,
  Homework,
  HomeworkInfoCountRes,
  IGetHomeworkFileUrlRes,
  IGetStudyMonthRes,
  IGroup,
  IGroupsResponse,
  IHomeworkResponse,
  ILessonsResponse,
  IStudyMonth,
  IVideo,
  IVideosResponse,
  LearningType,
  PriceRes,
  StudentDuplicationsRes,
} from "./types"

const config: INetworkConfig = {
  baseURL: `${Endpoints.Base}`,
}

class GroupsApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getGroups = (params?: IGetGroupsRequest): Promise<IResponse<IGroupsResponse>> =>
    this.get(GroupsEndpoints.Groups, { params })

  getCoursePrices = (params: PriceRequest): Promise<IResponse<PriceRes>> => this.get(GroupsEndpoints.Prices, { params })

  getStudyMonth = (): Promise<IResponse<IStudyMonth[]>> =>
    this.get(`${GroupsEndpoints.Exams}${GroupsEndpoints.StudyMonths}`)

  getLearningTypes = (): Promise<IResponse<LearningType[]>> => this.get(GroupsEndpoints.LearningTypes)

  createGroup = (params: GroupMakeFormValues): Promise<IResponse<IGroup>> => this.post(GroupsEndpoints.Groups, params)

  deleteGroup = (params: number): Promise<IResponse<IGroup>> => this.delete(`${GroupsEndpoints.Groups}/${params}`)

  editGroup = (params: GroupEditParams): Promise<IResponse<IGroup>> =>
    this.put(`${GroupsEndpoints.Groups}/${params.id}${GroupsEndpoints.Parameters}`, params.data)

  getGroupInfo = (id: number): Promise<IResponse<GroupInfo>> =>
    this.get(`${GroupsEndpoints.Groups}/${id}${GroupsEndpoints.Info}`)

  getGroupParameters = (id: number): Promise<IResponse<GroupParameters>> =>
    this.get(`${GroupsEndpoints.Groups}/${id}${GroupsEndpoints.Parameters}`)

  getGroupStatistics = (id: number): Promise<IResponse<GroupStatistics>> =>
    this.get(`${GroupsEndpoints.Groups}/${id}${GroupsEndpoints.Statistics}`)

  getGroupDetail = (id: number): Promise<IResponse<GroupDetail>> =>
    this.get(`${GroupsEndpoints.Groups}/${id}${GroupsEndpoints.Detail}`)

  getGroupSchedule = (params: GetGroupSchedulesReq): Promise<IResponse<GroupScheduleRes>> =>
    this.get(GroupsEndpoints.ScheduleItems, { params })

  getClassRooms = (params: GetClassRoomsReq): Promise<IResponse<ClassRoomsRes>> =>
    this.get(GroupsEndpoints.ClassRooms, { params })

  addScheduleItem = (params: ScheduleItemFormValues): Promise<IResponse<GroupScheduleRes>> =>
    this.post(GroupsEndpoints.ScheduleItems, params)

  editSchedule = (params: EditScheduleReq): Promise<IResponse<GroupScheduleRes>> =>
    this.put(`${GroupsEndpoints.ScheduleItems}/${params.id}`, params.data)

  deleteSchedule = (id: number): Promise<IResponse<GroupScheduleRes>> =>
    this.delete(`${GroupsEndpoints.ScheduleItems}/${id}`)

  getGroupStudents = (params: GetGroupStudentsReq): Promise<IResponse<GroupStudentsRes>> =>
    this.get(`${GroupsEndpoints.Groups}/${params.id}${GroupsEndpoints.Students}`, { params })

  getStudentSchema = (params: GetStudentSchemaReq): Promise<IResponse<DateSchemaRes>> =>
    this.get(`${GroupsEndpoints.ScheduleItems}/${params.id}${GroupsEndpoints.StudentSchema}`, { params })

  getStudentDuplications = (params: GetStudentDuplicationsReq): Promise<IResponse<StudentDuplicationsRes>> =>
    this.get(GroupsEndpoints.StudentDuplications, { params })

  mergeDuplicate = (params: DuplicationsActionReq): Promise<IResponse<StudentDuplicationsRes>> =>
    this.post(`${GroupsEndpoints.StudentDuplications}${GroupsEndpoints.Merge}`, params)

  ignoreDuplicate = (params: DuplicationsActionReq): Promise<IResponse<StudentDuplicationsRes>> =>
    this.post(`${GroupsEndpoints.StudentDuplications}${GroupsEndpoints.Ignore}`, params)

  getAcademicSchedule = (params: GetTeacherScheduleSchemaReq): Promise<IResponse<DateSchemaRes>> =>
    this.get(`${GroupsEndpoints.ScheduleItems}/${params.id}${GroupsEndpoints.AcademicSchema}`, { params })

  getAllVideos = (params: IGetVideosRequest): Promise<IResponse<IVideosResponse>> =>
    this.get(GroupsEndpoints.Video, { params })

  updateVideo = (params: IUpdateVideoRequest): Promise<IResponse<IVideo>> =>
    this.put(`${GroupsEndpoints.Video}/${params.id}`, params.data)

  getLessons = (params: IGetLessonsRequest): Promise<IResponse<ILessonsResponse>> => {
    const { id, ...data } = params

    return this.get(`${GroupsEndpoints.Groups}/${id}${GroupsEndpoints.Lessons}`, { params: data })
  }

  deleteVideo = (id: number): Promise<IResponse<IVideo>> => this.delete(`${GroupsEndpoints.Video}/${id}`)

  getHomework = (params: GetHomeworkReq): Promise<IResponse<GetHomeworkRes>> =>
    this.get(`${GroupsEndpoints.Groups}/${params.id}${GroupsEndpoints.Homework}`, { params })

  getExams = (params: GetExamsReq): Promise<IResponse<GetExamRes>> =>
    this.get(`${GroupsEndpoints.Groups}/${params.id}${GroupsEndpoints.Exams}`, { params })

  getHomeworkUploadUrl = (data: IGetHomeworkUploadUrl): Promise<IResponse<IGetHomeworkFileUrlRes>> =>
    this.post(GroupsEndpoints.HomeWorkUpload, data)

  addExam = (params: MakeExamInfo): Promise<IResponse<Exam>> => this.post(GroupsEndpoints.Exams, params)

  editExam = (params: EditExamInfo): Promise<IResponse<Exam>> => {
    const { id, data } = params
    const { deadline, ...restOdData } = data

    return this.patch(`${GroupsEndpoints.Exams}/${id}`, restOdData)
  }

  getSingleExam = (params: number): Promise<IResponse<Exam>> => this.get(`${GroupsEndpoints.Exams}/${params}`)

  getJournalFilterObjects = (groupId: number): Promise<IResponse<IGetStudyMonthRes>> =>
    this.get(`${GroupsEndpoints.Journal}${GroupsEndpoints.FilterObjects}`, {
      params: { groupId },
    })

  getJournals = (params: IGetJournalsReq): Promise<IResponse<GetJournalListRes>> =>
    this.get(GroupsEndpoints.Journal, { params })

  deleteHomework = (id: number): Promise<IResponse<Homework>> => this.delete(`${GroupsEndpoints.Homework}/${id}`)

  getHomeworkCount = (id: number): Promise<IResponse<HomeworkInfoCountRes>> =>
    this.get(`${GroupsEndpoints.HomeworkChat}${GroupsEndpoints.Count}`, { params: { homeworkId: id } })

  getHomeworkChats = (params: IGetHomeworks): Promise<IResponse<IHomeworkResponse>> => {
    const { id, orderBy, ...data } = params.data

    return this.get(`${GroupsEndpoints.Groups}/${id}${GroupsEndpoints.HomeworkChats}`, {
      params: data,
    })
  }

  getIncompleteHomeworkStudents = (params: IGetIncompleteStudentsRequest): Promise<IResponse<IStudents[]>> =>
    this.get(
      `${GroupsEndpoints.Groups}/${params.groupId}${GroupsEndpoints.NotSubmittedHomeworkStudents}/${params.homeworkId}`,
    )

  getExamChatsCount = (id: number): Promise<IResponse<HomeworkInfoCountRes>> =>
    this.get(`${GroupsEndpoints.Exams}/${id}${GroupsEndpoints.Chats}${GroupsEndpoints.Count}`)

  getExamChats = (params: GetExamChatsReq): Promise<IResponse<ExamChat[]>> =>
    this.get(`${GroupsEndpoints.Exams}/${params.id}${GroupsEndpoints.Chats}${GroupsEndpoints.Results}`, { params })

  changeAcademicHour = (params: ChangeAcademicHourParams): Promise<IResponse<AcademicPrices>> =>
    this.put(`${GroupsEndpoints.Groups}/${params.id}${GroupsEndpoints.AcademicPrices}`, params)

  getGroupAcademicHours = (params: GetGroupAcademicHours): Promise<IResponse<GroupAcademicHour[]>> =>
    this.get(`${GroupsEndpoints.Groups}/${params.id}${GroupsEndpoints.AcademicPrices}`, { params })

  getTeacherAttendance = (params: GetTeacherAttendanceReq): Promise<IResponse<GetTeacherAttendanceRes>> =>
    this.get(`${GroupsEndpoints.Groups}/${params.id}${GroupsEndpoints.StaffAttendances}`)
}

export const groupsApi = new GroupsApi(config)
