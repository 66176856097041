import { Link, useParams } from "react-router-dom"
import { ROUTES } from "@/constants"
import { lessonTimeFormatter } from "@/pages/Groups/constants"
import { JournalTableDays } from "@/stores/groups"
import { getDay } from "@/utils/date"
import { useCheckMyGroup } from "@/utils/hooks/useCheckMyGroup"

import styles from "../table.module.scss"

export const TableTitle = (
  item: JournalTableDays,
  isAvailableToCheckAllHomeworks?: boolean,
  isAvailableToCheckMyHomeworks?: boolean,
) => {
  const { id, staffId, scheduleId } = useParams()
  const academic = useCheckMyGroup()

  // const route = (academic ? ROUTES.myHomeworkSingle : ROUTES.homeworkSingle)
  //   .replace(':id', id!)
  //   .replace(':staffId', staffId!)
  //   .replace(':lessonId', item.lessonId?.toString()!)
  //   .replace(':homeworkId', item.homeworkId?.toString()!)
  //   .replace(':scheduleId', scheduleId!)

  // const homeworkChatRouteWithAddition = `${route}?journal=true`

  if (item.title) {
    return <h4 className={styles["journal-table__title"]}>{item?.title}</h4>
  }

  if (item?.homeworkId && item?.lessonId && (isAvailableToCheckAllHomeworks || isAvailableToCheckMyHomeworks)) {
    return (
      <Link className={styles["journal-table__header-wrapper"]} to={"/"}>
        <h4>{item.month && item?.day && `${item?.month} / ${item?.day || "-"}`}</h4>
        <h4>{getDay(item?.date!)}</h4>
        <h4>
          {lessonTimeFormatter(item?.beginTime!)} - {lessonTimeFormatter(item?.endTime!)}
        </h4>
      </Link>
    )
  }

  return (
    <div className={styles["journal-table__header-wrapper"]}>
      <h4>{item.month && item?.day && `${item?.month} / ${item?.day || "-"}`}</h4>
      <h4>{getDay(item?.date!)}</h4>
      <h4>
        {lessonTimeFormatter(item?.beginTime!)} - {lessonTimeFormatter(item?.endTime!)}
      </h4>
    </div>
  )
}
