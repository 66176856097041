import { Link } from "react-router-dom"
import { observer } from "mobx-react"
import { Table } from "antd"
import { ColumnsType } from "antd/es/table"
import { ROUTES } from "@/constants"
import mainDictionary from "@/dictionary"
import { BranchOfficeGridActionButtons } from "@/pages/Controls/BranchOffice"
import { BranchOffice } from "@/pages/Controls/BranchOffice/types"
import { getRowIndex } from "@/utils"
import { branchOfficeStore } from "../shared/branch-office.store"

type Props = {
  isLoading: boolean
}

export const BranchOfficeGrid = observer(({ isLoading }: Props) => {
  const { branchOffices } = branchOfficeStore
  const branchOfficesTableColumnWithActions: ColumnsType<BranchOffice> = [
    {
      title: "#",
      width: 50,
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (value, branchOffice, index) => <span>{getRowIndex(1, 10, index)}</span>,
    },
    {
      title: mainDictionary.branchOfficeName,
      dataIndex: "name",
      key: "name",
      render: (value, branchOffice) => (
        <Link to={ROUTES.controlBranchOfficeSingle.replace(":id", branchOffice?.id?.toString())}>
          {branchOffice?.name}
        </Link>
      ),
    },
    {
      title: mainDictionary.actions,
      dataIndex: "actions",
      key: "actions",
      align: "center",
      width: 100,
      render: (value, branchOffice) => <BranchOfficeGridActionButtons branchOffice={branchOffice} />,
    },
  ]

  return (
    <Table<BranchOffice>
      columns={branchOfficesTableColumnWithActions}
      dataSource={branchOffices || []}
      loading={!branchOffices || isLoading}
      pagination={false}
    />
  )
})
