import { UploadFile } from "antd"
import { RcFile } from "antd/es/upload"
import dayjs from "dayjs"
import { stage } from "@/api/endpoints"
import mainDictionary from "@/dictionary"

export const dateFormatter = (date: string): string => dayjs(date).format("DD-MMMM-YYYY HH:mm")

export const makeFileUrl = (partUrl: string): string | undefined => {
  if (!partUrl) {
    return
  }

  return new URL(partUrl, stage.cdnHost).href
}

export const makeHolliPhoto = (partUrl: string): string | undefined => {
  if (!partUrl) {
    return
  }

  return new URL(partUrl, stage.crmLink).href
}

export const extractPathname = (url?: string): string | undefined => {
  if (!url) {
    return
  }

  const pathname = new URL(url).pathname

  return pathname
}

export const makeHomeworkFileUrl = (partUrl: string): string | undefined => {
  if (!partUrl) {
    return
  }

  return `${stage.cdnHost}/${partUrl}`
}

export const getFullName = <T extends { firstName?: string; lastName?: string; middleName?: string }>(
  users: T,
): string => [users?.firstName, users?.lastName, users?.middleName].filter(Boolean).join(" ") || "Noma'lum"

export const getRowIndex = (page: number, perPage: number, index: number) => (page - 1) * perPage + index + 1

export const getViseRowIndex = (page: number, perPage: number, index: number, totalItems: number) =>
  totalItems - ((page - 1) * perPage + index)

export const calcUploadChunkSize = (fileSize: number): number => {
  const size = fileSize / 1024 / 1024

  if (size > 1000) {
    return 8
  }

  if (size > 500) {
    return 6
  }

  if (size > 100) {
    return 4
  }

  if (size > 50) {
    return 2
  }

  return 1
}

export const handleImagePreview = async (file: UploadFile) => {
  let src = file.url as string

  if (!src) {
    src = await new Promise((resolve) => {
      const reader = new FileReader()

      reader.readAsDataURL(file.originFileObj as RcFile)
      reader.onload = () => resolve(reader.result as string)
    })
  }
  const image = new Image()

  image.src = src
  const imgWindow = window.open(src)

  imgWindow?.document.write(image.outerHTML)
}

export const makeCertificateUrl = (partUrl: string): string | undefined => {
  if (!partUrl) {
    return
  }

  return `${stage.studentAppUrl}/public/certificate/${partUrl}`
}

export const extractContractPathname = (url?: string): string | undefined => {
  if (!url) {
    return
  }

  const pathname = new URL(url).pathname

  return pathname.startsWith("/") ? pathname.slice(1) : pathname
}

export function hasTextContent(htmlString: string): boolean {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, "text/html")

  const body = doc.body

  const checkNodesForText = (node: Node): boolean => {
    if (node.nodeType === Node.TEXT_NODE) {
      return !!node.textContent?.trim()
    }

    if (node.nodeType === Node.ELEMENT_NODE) {
      for (const child of Array.from(node.childNodes)) {
        if (checkNodesForText(child)) {
          return true
        }
      }
    }

    return false
  }

  return checkNodesForText(body)
}

export const getFormattedNumberWithSpace = (number: number) => {
  if (isNaN(number)) {
    return 0
  }

  return `${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ${mainDictionary.sum}`
}
