export enum SettingsEndpoints {
  ControlActions = "/control-actions",
  UpdateStudentGroupActionControl = "/update-student-group-action-control",
  SettingsGamificationOffering = "/oferta/get-oferta",
  SettingsGamificationOfferingUpdate = "/oferta/update-oferta",
  FinanceDiscounts = "/discounts",
  AcademicPrices = "/academic-prices",
  AcademicHours = "/academic-hours",
  SettingsFinancePrices = "/prices",
  FinancePayments = "/payments",
  AccessControlRoles = "/access-control/roles",
  Permissions = "/permissions",
  Menus = "/menus",
  StaffStatuses = "/staff/statuses",
  CustomFields = "/custom-fields",
  Student = "/student",
  Group = "/group",
  Courses = "/courses",
  Categories = "/categories",
  LearningPayments = "/learning-types",
  Location = "/locations",
  Expenses = "/cost-items/types",
}
