import { Endpoints, INetworkConfig, Instance, IResponse, ISuccessResponse } from "@/api"
import {
  AcademicPaymentRequest,
  AcademicPaymentResponse,
  DebtorsRequestParams,
  DebtorsResponse,
  Expenses,
  ExpensesRequestParams,
  ExpensesType,
  ExpensesUpdateParams,
  PaymentMethod,
  PaymentsRes,
  PaymentsType,
  PriceReqParams,
  PriceResponse,
  Remains,
  RemainsRequestParams,
  RevenueAndPaymentsReqParams,
  TuitionFeesRequestParams,
  TuitionFeesResponse,
} from "@/types"
import { PaymentEndpoints } from "./endpoints"

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class PaymentsApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getRevenueAndPayments = (params: RevenueAndPaymentsReqParams): Promise<IResponse<PaymentsRes>> =>
    this.get(PaymentEndpoints.Payments, { params })

  getRemains = (params?: RemainsRequestParams): Promise<IResponse<Remains>> =>
    this.get(`${PaymentEndpoints.Payments}${PaymentEndpoints.Balances}`, { params })

  getPaymentsTypes = (): Promise<IResponse<PaymentsType[]>> =>
    this.get(`${PaymentEndpoints.Payments}${PaymentEndpoints.Types}`)

  getPaymentPrices = (params?: PriceReqParams): Promise<IResponse<PriceResponse>> =>
    this.get(`${PaymentEndpoints.Prices}`, { params })

  getTuitionFees = (params?: TuitionFeesRequestParams): Promise<IResponse<TuitionFeesResponse>> =>
    this.get(PaymentEndpoints.GroupsPayments, { params })

  getDebtors = (params: DebtorsRequestParams): Promise<IResponse<DebtorsResponse>> =>
    this.get(`${PaymentEndpoints.Payments}${PaymentEndpoints.Debtors}`, { params })

  addExpense = (params: ExpensesUpdateParams): Promise<IResponse<ISuccessResponse>> =>
    this.post(PaymentEndpoints.CostItems, params)

  getExpenses = (params: ExpensesRequestParams): Promise<IResponse<Expenses>> =>
    this.get(PaymentEndpoints.CostItems, { params })

  getExpenseTypes = (): Promise<IResponse<ExpensesType[]>> =>
    this.get(`${PaymentEndpoints.CostItems}${PaymentEndpoints.Types}`)

  updateExpense = (params: ExpensesUpdateParams): Promise<IResponse<ISuccessResponse>> =>
    this.put(`${PaymentEndpoints.CostItems}/${params.id}`, params)

  deleteExpense = (id: number): Promise<IResponse<ISuccessResponse>> =>
    this.delete(`${PaymentEndpoints.CostItems}/${id}`)

  getAcademicPayments = (params?: AcademicPaymentRequest): Promise<IResponse<AcademicPaymentResponse>> =>
    this.get(PaymentEndpoints.AcademicPayments, { params })

  getTransactionTypes = (): Promise<IResponse<PaymentsType[]>> =>
    this.get(`${PaymentEndpoints.Payments}${PaymentEndpoints.Types}`)

  getPaymentMethods = (): Promise<IResponse<PaymentMethod[]>> =>
    this.get(`${PaymentEndpoints.Payments}${PaymentEndpoints.Methods}`)
}

export const paymentsApi = new PaymentsApi(config)
