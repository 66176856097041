import {
  AppleOutlined,
  AreaChartOutlined,
  AuditOutlined,
  BarChartOutlined,
  BellOutlined,
  BookOutlined,
  BranchesOutlined,
  CameraOutlined,
  ClockCircleOutlined,
  CoffeeOutlined,
  CommentOutlined,
  ControlOutlined,
  DatabaseOutlined,
  DollarOutlined,
  DribbbleOutlined,
  FileTextOutlined,
  FontSizeOutlined,
  FundProjectionScreenOutlined,
  GroupOutlined,
  LayoutOutlined,
  MailOutlined,
  MobileOutlined,
  MoneyCollectOutlined,
  PhoneOutlined,
  PrinterOutlined,
  RedditOutlined,
  RiseOutlined,
  ScheduleOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  SnippetsOutlined,
  StarOutlined,
  TwitchOutlined,
  UngroupOutlined,
  UserOutlined,
  VerifiedOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons"
import { Badge } from "antd"
import classNames from "classnames/bind"
import { ROUTES } from "@/constants"
import mainDictionary from "@/dictionary"
import { permissionObjectTypes } from "@/modules/permission"

import styles from "./menu.module.scss"

const cn = classNames.bind(styles)

export const staffControlSettings = {
  [permissionObjectTypes.staffControl]: {
    icon: <ControlOutlined />,
    link: ROUTES.controls,
  },
  [permissionObjectTypes.staffControlRoles]: {
    link: ROUTES.singleRole,
  },
}

export const mainMenuLists = (count: number) => [
  // {
  //   label: mainDictionary.myGroups,
  //   key: ROUTES.myGroups,
  //   name: permissionObjectTypes.myGroups,
  //   icon: <UngroupOutlined />,
  // },
  // {
  //   label: mainDictionary.teachers,
  //   key: ROUTES.teachers,
  //   icon: <UserOutlined />,
  //   name: permissionObjectTypes.usersTeachers,
  //   children: [
  //     {
  //       label: mainDictionary.teachers,
  //       icon: <UserOutlined />,
  //       key: ROUTES.teachers,
  //       name: permissionObjectTypes.usersTeachers,
  //     },
  //   ],
  // },
  {
    label: mainDictionary.groups,
    key: `${permissionObjectTypes.allGroups}-${ROUTES.groups}`,
    icon: <GroupOutlined />,
    name: permissionObjectTypes.schooling,
    children: [
      {
        label: mainDictionary.groups,
        key: ROUTES.groups,
        name: permissionObjectTypes.allGroups,
      },
      {
        label: mainDictionary.addingToGroups,
        key: "adding-to-groups",
        name: permissionObjectTypes.allGroups,
      },
      {
        label: mainDictionary.studentDuplications,
        key: ROUTES.studentDuplications,
        name: permissionObjectTypes.allGroups,
      },
    ],
  },
  {
    label: mainDictionary.users,
    icon: <UserOutlined />,
    key: ROUTES.users,
    name: permissionObjectTypes.users,
    children: [
      {
        label: mainDictionary.staffs,
        key: ROUTES.usersStaff,
        name: permissionObjectTypes.usersStaff,
      },
      // {
      //   label: mainDictionary.academics,
      //   icon: <UserOutlined />,
      //   key: ROUTES.usersAcademic,
      //   name: permissionObjectTypes.usersAcademic,
      // },
      {
        label: mainDictionary.students,
        key: ROUTES.students,
        name: permissionObjectTypes.usersStudents,
      },
    ],
  },
  {
    label: mainDictionary.studyDepartment,
    key: ROUTES.studyDepartment,
    icon: <StarOutlined />,
    name: permissionObjectTypes.study,
    children: [
      {
        label: mainDictionary.courses,
        key: ROUTES.studyDepartmentCourses,
        name: permissionObjectTypes.studyCourses,
      },
      {
        label: mainDictionary.homework,
        key: ROUTES.studyDepartmentHomeworks,
        name: permissionObjectTypes.studyHomeworkStatistics,
      },
      {
        label: mainDictionary.teachersKPI,
        key: ROUTES.studyDepartmentTeachersKPI,
        name: permissionObjectTypes.academicsKPI,
      },
      {
        label: mainDictionary.skillClasses,
        key: ROUTES.studyDepartmentSkillClasses,
        name: permissionObjectTypes.skillClasses,
      },
      // {
      //   label: mainDictionary.teachersStatistics,
      //   key: ROUTES.studyDepartmentTeacherStatistics,
      //   icon: <BarChartOutlined />,
      //   name: permissionObjectTypes.studyHomeworkStatistics,
      // },
      // {
      //   label: mainDictionary.homeworkBase,
      //   key: `${ROUTES.studyDepartmentHomeworks}-1`,
      //   icon: <DatabaseOutlined />,
      //   name: permissionObjectTypes.studyHomeworkDb,
      //   children: [
      //     {
      //       label: mainDictionary.homework,
      //       key: ROUTES.studyDepartmentHomeworkDataBase,
      //       icon: <PrinterOutlined />,
      //       name: permissionObjectTypes.studyHomeworkDb,
      //     },
      //     {
      //       label: mainDictionary.draft,
      //       key: ROUTES.studyDepartmentHomeworkDataBaseDraft,
      //       icon: <FontSizeOutlined />,
      //       name: permissionObjectTypes.studyHomeworkDb,
      //     },
      //   ],
      // },
    ],
  },
  {
    label: mainDictionary.Gamification,
    key: ROUTES.gamification,
    icon: <RedditOutlined />,
    name: permissionObjectTypes.gamification,
    children: [
      {
        label: mainDictionary.statistics,
        key: ROUTES.gamificationStatistics,
        name: permissionObjectTypes.gamificationUnits,
      },
      {
        label: mainDictionary.levels,
        key: ROUTES.gamificationLevels,
        name: permissionObjectTypes.gamificationLevels,
      },
      {
        label: mainDictionary.actions,
        key: ROUTES.gamificationActions,
        name: permissionObjectTypes.gamificationActions,
      },
      {
        label: mainDictionary.connect,
        key: ROUTES.gamificationConnect,
        name: permissionObjectTypes.gamificationGroups,
      },
      {
        label: mainDictionary.theBestOnes,
        key: ROUTES.gamificationTheBestOnes,
        name: permissionObjectTypes.theBestOnes,
      },
    ],
  },
  {
    label: mainDictionary.management,
    key: `root${ROUTES.management}`,
    icon: <ControlOutlined />,
    name: permissionObjectTypes.management,
    children: [
      {
        className: "report-wrapper",
        label: (
          <Badge
            showZero
            count={count}
            overflowCount={99}
            color="danger"
            className={cn("badge")}
            style={{
              right: "-20px",
            }}
          >
            <span className={cn("report-text")}>{mainDictionary.reports}</span>
          </Badge>
        ),
        key: ROUTES.management,
        name: permissionObjectTypes.managementReports,
        index: true,
      },
      {
        label: mainDictionary.autoCall,
        key: ROUTES.managementAutoCall,
        name: permissionObjectTypes.managementAutoCall,
      },
    ],
  },
  {
    label: mainDictionary.analytics,
    key: ROUTES.analytics,
    icon: <SnippetsOutlined />,
    name: permissionObjectTypes.profile,
    children: [
      {
        label: mainDictionary.audienceAvailabilty,
        key: ROUTES.analyticsAudienceAvailability,
        name: permissionObjectTypes.profile,
      },
      {
        label: mainDictionary.teachersAvailability,
        key: ROUTES.analyticsTeachersAvailability,
        name: permissionObjectTypes.profile,
      },
      {
        label: mainDictionary.teachersStatistics,
        key: ROUTES.analyticsTeachersStatistics,
        name: permissionObjectTypes.profile,
      },
      {
        label: mainDictionary.statistics,
        key: ROUTES.analyticsStatistics,
        name: permissionObjectTypes.profile,
      },
    ],
  },
  {
    label: mainDictionary.schedule,
    key: ROUTES.schedule,
    icon: <ScheduleOutlined />,
    // Should be change
    name: permissionObjectTypes.finance,
    children: [
      {
        label: mainDictionary.emptyRooms,
        key: ROUTES.emptyRooms,
        // Should be change
        name: permissionObjectTypes.finance,
      },
    ],
  },
  {
    label: mainDictionary.finance,
    key: `${ROUTES.finance}`,
    icon: <DollarOutlined />,
    name: permissionObjectTypes.finance,
    children: [
      {
        label: mainDictionary.revenueAndPayments,
        key: ROUTES.revenueAndPayments,
        name: permissionObjectTypes.studentPayments,
      },
      {
        label: mainDictionary.expenses,
        key: ROUTES.expenses,
        name: permissionObjectTypes.expenses,
      },
      {
        label: mainDictionary.remains,
        key: ROUTES.remains,
        name: permissionObjectTypes.studentBalances,
      },
      {
        label: mainDictionary.tuitionFees,
        key: ROUTES.tuitionFees,
        name: permissionObjectTypes.groupPayments,
      },
      {
        label: mainDictionary.debtors,
        key: ROUTES.debtors,
        name: permissionObjectTypes.paymentsDue,
      },
      {
        label: mainDictionary.paymentsForTeachers,
        key: ROUTES.paymentsForTeachers,
        name: permissionObjectTypes.academicPayments,
      },
    ],
  },
  {
    label: mainDictionary.special,
    key: ROUTES.special,
    icon: <LayoutOutlined />,
    name: permissionObjectTypes.profile,
    children: [
      {
        label: mainDictionary.outstandingPayments,
        key: ROUTES.specialOutstandingPaymants,
        name: permissionObjectTypes.profile,
      },
      {
        label: mainDictionary.balancesPeriod,
        key: ROUTES.specialBalancesPeriod,
        name: permissionObjectTypes.profile,
      },
      {
        label: mainDictionary.paidTime,
        key: ROUTES.specialPaidTime,
        name: permissionObjectTypes.profile,
      },
    ],
  },
  {
    label: mainDictionary.controls,
    key: `${ROUTES.controls}${permissionObjectTypes.staffControl}`,
    icon: <ControlOutlined />,
    name: permissionObjectTypes.staffControl,
    children: [
      {
        label: mainDictionary.audit,
        key: ROUTES.audits,
        name: permissionObjectTypes.audits,
      },
      {
        label: mainDictionary.faceIdLogs,
        key: ROUTES.faceId,
        name: permissionObjectTypes.staffControlFaceIdLogs,
      },
      {
        label: mainDictionary.offices,
        key: ROUTES.controlBranchOffice,
        name: permissionObjectTypes.staffControlBranchOffice,
      },
      {
        label: mainDictionary.faceIdDevices,
        key: ROUTES.faceIdDevices,
        name: permissionObjectTypes.staffControlDevices,
      },
    ],
  },
  {
    label: mainDictionary.market,
    key: ROUTES.market,
    icon: <ShoppingCartOutlined />,
    name: permissionObjectTypes.store,
    children: [
      {
        label: mainDictionary.transactions,
        key: ROUTES.marketTransactions,
        name: permissionObjectTypes.storeTransactions,
      },
      {
        label: mainDictionary.categories,
        key: ROUTES.marketCategories,
        name: permissionObjectTypes.storeCategories,
      },
      {
        label: mainDictionary.accessories,
        key: ROUTES.marketAccessories,
        name: permissionObjectTypes.storeItems,
      },
    ],
  },
  {
    label: mainDictionary.settings,
    key: ROUTES.settings,
    icon: <SettingOutlined />,
    name: permissionObjectTypes.settings,
    children: [
      {
        // Missed from back
        label: mainDictionary.gamificationOffer,
        key: ROUTES.settingsPublicOffering,
        name: permissionObjectTypes.settingsGamificationOffer,
      },
      {
        label: mainDictionary.controls,
        key: ROUTES.controls,
        name: permissionObjectTypes.staff,
      },
      {
        // Missed
        label: mainDictionary.studentStatuses,
        key: ROUTES.settingsStudentStatuses,
        name: permissionObjectTypes.students,
      },
      {
        label: mainDictionary.notifications,
        key: ROUTES.notificationsSended,
        name: permissionObjectTypes.notifications,
      },
      {
        // Missed
        label: mainDictionary.contracts,
        key: ROUTES.contracts,
        name: permissionObjectTypes.contracts,
      },
      {
        // Missed
        label: mainDictionary.mobileApp,
        key: ROUTES.settingsMobileApp,
        name: permissionObjectTypes.settingsMobileVersions,
      },
      {
        // Missed
        label: mainDictionary.finance,
        key: ROUTES.settingsFinance,
        name: permissionObjectTypes.profile,
      },
      {
        label: mainDictionary.staffs,
        key: ROUTES.settingsStaff,
        name: permissionObjectTypes.settingsStaff,
      },
      {
        label: mainDictionary.company,
        key: ROUTES.settingsCompany,
        name: permissionObjectTypes.settingsCompany,
      },
      {
        label: mainDictionary.customFields,
        key: ROUTES.settingsCustomFields,
        name: permissionObjectTypes.settingsCustomFields,
      },
    ],
  },
  {
    label: mainDictionary.events,
    key: `${ROUTES.competitions}-1`,
    icon: <FundProjectionScreenOutlined />,
    name: permissionObjectTypes.events,
    children: [
      {
        label: mainDictionary.masterClasses,
        key: ROUTES.masterClass,
        name: permissionObjectTypes.eventsMasterClasses,
      },
      {
        label: mainDictionary.competitions,
        key: ROUTES.competitions,
        name: permissionObjectTypes.eventsCompetitions,
      },
    ],
  },
  {
    label: mainDictionary.profile,
    key: ROUTES.profile,
    icon: <UserOutlined />,
    name: permissionObjectTypes.profile,
  },
]

export const menuWithOutChildren = ["/groups", "/my-groups", "/profile"]
