import { useMemo } from "react"
import { observer } from "mobx-react"
import classNames from "classnames/bind"
import { IVideo } from "@/api/groups"
import { StoreTitle, Table as AntTable } from "@/components/Table"
import { VideoStatus } from "@/modules/kinescope"
import { videoColumns } from "@/pages/Groups/constants"
import { getPaginationParams } from "@/pages/utils"
import { getPerPages } from "@/shared"
import { useStores } from "@/stores"
import { IGetVideosRequest, VIDEO_KEYS } from "@/stores/groups"

import styles from "./table.module.scss"

const cn = classNames.bind(styles)

interface IProps {
  groupId: number
}

export const VideoTable = observer(({ groupId }: IProps) => {
  const { groupsStore, kinescopeStore } = useStores()
  const perPageFromLocalStorage = getPerPages()
  const uploadFilesVisible = kinescopeStore.uploads[groupId]?.filter((upload) => upload.uploadFile.isVisible) || []

  const uploadVideos: IVideo[] = useMemo(() => {
    if (!kinescopeStore.uploads[groupId]) {
      return []
    }

    return uploadFilesVisible.map((upload) => {
      const currentDate = new Date().toString()
      const lesson = groupsStore.lessons?.lessons.find((lesson) => lesson.id === upload.uploadFile.lessonId)
      const { origName, progress, file, currentStatus, start, abort } = upload.uploadFile
      const status = !progress || (progress && progress < 100) ? VideoStatus.Uploading : VideoStatus.Processing
      const percentage = progress !== undefined ? progress : undefined

      return {
        url: origName,
        lesson: lesson || {
          id: 0,
          name: "none",
          createdAt: currentDate,
          isMyLesson: true,
        },
        filename: origName,
        size: file.size,
        id: upload.id,
        createdAt: currentDate,
        origName,
        hasLesson: true,
        status,
        percentage,
        duration: 0,
        statusHistories: upload.statusHistories,
        updatedAt: currentDate,
        isUploading: true,
        currentStatus,
        onStart: start,
        onAbort: abort,
      }
    })
  }, [kinescopeStore.uploads[groupId], uploadFilesVisible?.length, groupId])

  const dataSource = () => {
    const uploadIds = uploadVideos.map((item) => item.id)
    const uploadedVideoIds = groupsStore.videos?.videos?.map((item) => item.id)

    uploadIds.forEach((item) => {
      if (uploadedVideoIds?.includes(item)) {
        kinescopeStore.removeFromUploads(groupId, item)
      }
    })

    return [...uploadVideos, ...(groupsStore.videos?.videos || [])]
  }

  const handlePaginationChange = (page: number) => {
    groupsStore.setCurrentVideosPage(page)
    const option: IGetVideosRequest = {
      value: groupsStore.key,
      page,
      perPage: groupsStore.videosPerPage,
      keys: VIDEO_KEYS,
      groupId,
    }

    groupsStore.setVideoLoader(true)

    groupsStore.getAllVideos(option)
  }

  const handleShowPageSizeChanger = (page: number, pageSize: number) => {
    groupsStore.setVideosPerPage(pageSize)
    groupsStore.setVideosPage(page)
    groupsStore.setCurrentVideosPage(page)
  }

  return (
    <AntTable<IVideo>
      columns={videoColumns}
      dataSource={dataSource()}
      className={cn("group-video-table")}
      storeTitle={StoreTitle.GroupVideos}
      rowKey="id"
      onStoreChange={handleShowPageSizeChanger}
      loading={groupsStore.videoLoader}
      key={uploadVideos.length}
      pagination={{
        ...getPaginationParams({
          total: groupsStore.videos?.total,
          onChange: handlePaginationChange,
          notShowSizeChanger: false,
          defaultPageSize: groupsStore.videosPerPage,
          pageSize: perPageFromLocalStorage?.groupVideos,
          current: groupsStore.videosCurrentPage,
        }),
      }}
    />
  )
})
