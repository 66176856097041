import { useEffect } from "react"
import { useLocation, useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { KinescopePlayerModal } from "@/modules/kinescope"
import { PlayerModal } from "@/modules/Player"
import { getPerPages } from "@/shared"
import { useStores } from "@/stores"
import { GroupByOrder, VIDEO_KEYS } from "@/stores/groups"
import { EditVideoParams } from "./EditVideoParams"
import { StatusHistory } from "./StatusHistory"
import { VideoTable } from "./Table"
import { VideoTop } from "./VideoTop"

const INTERVAL_DURATION = 5_000

interface IProps {
  groupId: number
  groupName?: string
}

export const Videos = observer(({ groupName, groupId }: IProps) => {
  const { groupsStore, kinescopeStore } = useStores()
  const perPageFromLocalStorage = getPerPages()
  const { id } = useParams()
  const { pathname } = useLocation()
  const hasUploadingFiles = !!kinescopeStore.uploads[groupId]?.length

  const getVideos = () => {
    const videoOption = {
      value: groupsStore.key,
      keys: VIDEO_KEYS,
      groupId: parseInt(id!, 10),
      page: groupsStore.videoPage,
      perPage: groupsStore.videosPerPage,
      orderBy: GroupByOrder.Desc,
    }

    groupsStore
      .getAllVideos(videoOption)
      .then(() => {
        kinescopeStore.removeWaitProcessingFiles(groupsStore.uploadedVideoIds, groupId)
      })
      .finally(() => {
        groupsStore.setVideoLoader(false)
      })
  }

  useEffect(() => {
    getVideos()
  }, [groupsStore.key, id, groupsStore.videosPerPage])

  useEffect(() => {
    groupsStore.setVideosPerPage(perPageFromLocalStorage?.groupVideos! || 10)
  }, [])

  const handleKinescopePlayerClose = () => {
    groupsStore.setIsKinescopePlayerOpen(false)
  }

  useEffect(() => {
    const intervalId = hasUploadingFiles ? window.setInterval(getVideos, INTERVAL_DURATION) : null

    return () => {
      intervalId && clearInterval(intervalId)
    }
  }, [hasUploadingFiles])

  return (
    <div>
      <VideoTop groupId={groupId} pathname={pathname} pageName={groupName} />
      <VideoTable groupId={groupId} />

      {groupsStore.addVideo && <EditVideoParams />}
      {groupsStore.statusHistoryModal && <StatusHistory />}
      {groupsStore.isVideoPlayerOpen && <PlayerModal />}

      {groupsStore.isKinescopePlayerOpen && (
        <KinescopePlayerModal
          isOpen
          title={groupsStore.selectedVideo?.lesson.name}
          videoId={groupsStore.selectedVideo?.url!}
          onCancel={handleKinescopePlayerClose}
          width={800}
        />
      )}
    </div>
  )
})
