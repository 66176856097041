import { ROUTES } from "@/constants"
import { AvailableComponent } from "@/modules/AvailableComponent"
import { permissionObjectTypes } from "@/modules/permission"
import { Debtors, Expenses, PaymentsForTeachers, Remains, RevenueAndPayments, TuitionFees } from "./lazy"

export const financeRouters = [
  {
    path: ROUTES.finance,
    children: [
      {
        path: ROUTES.revenueAndPayments,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.finance}>
            <RevenueAndPayments />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.expenses,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.expenses}>
            <Expenses />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.remains,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.studentBalances}>
            <Remains />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.tuitionFees,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.groupPayments}>
            <TuitionFees />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.debtors,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.paymentsDue}>
            <Debtors />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.paymentsForTeachers,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.academicPayments}>
            <PaymentsForTeachers />
          </AvailableComponent>
        ),
      },
    ],
  },
]
