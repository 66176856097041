import { lazy } from "react"
import { handleCatchChunkError } from "@/utils"

export const RevenueAndPayments = lazy(() =>
  import("./RevenueAndPayments/RevenueAndPayments")
    .then(({ RevenueAndPayments }) => ({ default: RevenueAndPayments }))
    .catch(handleCatchChunkError),
)

export const Expenses = lazy(() =>
  import("./Expenses/Expenses").then(({ Expenses }) => ({ default: Expenses })).catch(handleCatchChunkError),
)

export const Remains = lazy(() =>
  import("./Remains/Remains").then(({ Remains }) => ({ default: Remains })).catch(handleCatchChunkError),
)

export const TuitionFees = lazy(() =>
  import("./TuitionFees/TuitionFees")
    .then(({ TuitionFees }) => ({ default: TuitionFees }))
    .catch(handleCatchChunkError),
)

export const Debtors = lazy(() =>
  import("./Debtors/Debtors").then(({ Debtors }) => ({ default: Debtors })).catch(handleCatchChunkError),
)

export const PaymentsForTeachers = lazy(() =>
  import("./PaymentForTeachers/PaymentsForTeachers")
    .then(({ PaymentsForTeachers }) => ({ default: PaymentsForTeachers }))
    .catch(handleCatchChunkError),
)
