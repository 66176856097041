import { useEffect, useMemo } from "react"
import { Link, useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { DeleteOutlined, EditOutlined, InfoCircleOutlined, MoreOutlined } from "@ant-design/icons"
import { Button, Flex, Popconfirm, Popover, Table, TableProps, Tooltip } from "antd"
import classNames from "classnames/bind"
import { Homework as HomeworkType } from "@/api/groups"
import CheckedIcon from "@/assets/icons/checked-homework.svg"
import UserIcon from "@/assets/icons/user-icon.svg"
import WaitingIcon from "@/assets/icons/waiting.svg"
import { ROUTES } from "@/constants"
import mainDictionary from "@/dictionary"
import { addSuccessNotification } from "@/modules/notifications"
import { getPaginationParams } from "@/pages/utils"
import { useStores } from "@/stores"
import { formatDate, getRowIndex } from "@/utils"
import { HomeworkInfo } from "./HomeworkInfo"

import styles from "./homework.module.scss"

const cn = classNames.bind(styles)

export const Homework = observer(() => {
  const { groupsStore } = useStores()
  const { id } = useParams()

  const handleInfoClick = (homework: HomeworkType) => {
    groupsStore.setHomeworkInfo(homework.homeworkInfo)
    groupsStore.setHomeworkInfoModal(true)
  }

  const handleDelete = (homeworkId: number) => {
    if (!id) return

    groupsStore.setHomeworkLoading(true)

    const parsedId = parseInt(id, 10)

    groupsStore.deleteHomework(homeworkId).then((res) => {
      if (res.success) {
        addSuccessNotification(mainDictionary.successfullyDeleted)

        groupsStore.getHomework({
          ...groupsStore.homeworkFilterOptions,
          id: parsedId,
        })
      }
    })
  }

  const columns: TableProps<HomeworkType>["columns"] = useMemo(
    () => [
      {
        title: mainDictionary.order,
        width: 50,
        render: (item: HomeworkType, record: HomeworkType, index: number) => (
          <span>
            {getRowIndex(
              groupsStore.homeworkFilterOptions?.page || 1,
              groupsStore.homeworkFilterOptions?.perPage || 10,
              index,
            )}
          </span>
        ),
      },
      {
        title: mainDictionary.theme,
        render: (item: HomeworkType) => {
          if (!id || !item?.lesson?.id) return

          return (
            <Link
              className={cn("homework__title", {
                "homework__title--waiting": item?.waitingHomeworkCount,
              })}
              to={ROUTES.homeworkSingle
                ?.replace(":id", id)
                .replace(":lessonId", item?.lesson?.id?.toString())
                .replace(":homeworkId", item?.id?.toString())}
            >
              {item?.lesson?.name || "-"}
            </Link>
          )
        },
      },
      {
        title: (
          <Tooltip title={mainDictionary.studentsCount} placement="bottom">
            <img src={UserIcon} alt="students" />
          </Tooltip>
        ),
        width: 50,
        render: (item: HomeworkType) => <span>{item?.studentsCount || 0}</span>,
      },
      {
        title: (
          <Tooltip title={mainDictionary.homeworkWhichIsWaiting} placement="bottom">
            <img src={WaitingIcon} alt="waiting" />
          </Tooltip>
        ),
        width: 50,
        render: (item: HomeworkType) => <span>{item.waitingHomeworkCount || 0}</span>,
      },
      {
        title: (
          <Tooltip title={mainDictionary.checkedHomeworkCount} placement="bottom">
            <img src={CheckedIcon} alt="checked" />
          </Tooltip>
        ),
        width: 50,
        render: (item: HomeworkType) => <span>{item.acceptedHomeworkCount || 0}</span>,
      },
      {
        title: mainDictionary.givenTime,
        width: 145,
        align: "center",
        render: (item: HomeworkType) => <span>{formatDate(item.createdAt, undefined, true)}</span>,
      },
      {
        title: mainDictionary.deadline,
        width: 145,
        align: "center",
        render: (item: HomeworkType) => <span>{formatDate(item.deadline, undefined, true)}</span>,
      },
      {
        title: mainDictionary.lessonDate,
        width: 145,
        align: "center",
        render: (item: HomeworkType) => <span>{formatDate(item?.lesson?.createdAt, undefined, true)}</span>,
      },
      {
        width: 80,
        align: "center",
        render: (item: HomeworkType) => (
          <Popover
            placement="left"
            content={
              <Flex vertical gap={10}>
                <Button type="text" icon={<EditOutlined />}>
                  {mainDictionary.edit}
                </Button>

                <Button onClick={handleInfoClick.bind(null, item)} type="text" icon={<InfoCircleOutlined />}>
                  {mainDictionary.more}
                </Button>

                <Popconfirm
                  placement="left"
                  title={mainDictionary.areYouSureToDelete}
                  onConfirm={handleDelete.bind(null, item.id)}
                >
                  <Button type="text" danger icon={<DeleteOutlined />}>
                    {mainDictionary.delete}
                  </Button>
                </Popconfirm>
              </Flex>
            }
          >
            <Button type="text" icon={<MoreOutlined />} />
          </Popover>
        ),
      },
    ],
    [groupsStore.homeworkFilterOptions],
  )

  const handlePaginationChange = (page: number, perPage: number) => {
    groupsStore.setHomeworkFilterOptions({
      ...groupsStore.homeworkFilterOptions,
      page,
      perPage,
    })
  }

  useEffect(() => {
    if (!id) return

    const parsedId = parseInt(id, 10)

    if (parsedId) {
      groupsStore.setHomeworkLoading(true)

      groupsStore.getHomework({
        ...groupsStore.homeworkFilterOptions,
        id: parsedId,
      })
    }
  }, [groupsStore.homeworkFilterOptions])

  return (
    <Flex className={cn("homework")} vertical gap={20}>
      <Flex justify="flex-end">
        <Button type="primary">{mainDictionary.addHomework}</Button>
      </Flex>

      <Table
        loading={groupsStore.homeworkLoading}
        columns={columns}
        dataSource={groupsStore.homework?.homework || []}
        pagination={{
          ...getPaginationParams({
            total: groupsStore.homework?.total || 0,
          }),
          showSizeChanger: true,
          onChange: handlePaginationChange,
        }}
      />

      {groupsStore.homeworkInfoModal && <HomeworkInfo />}
    </Flex>
  )
})
