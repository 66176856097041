export function convertSeconds(seconds?: number): string {
  if (!seconds) {
    return "0"
  }

  const hours: number = Math.floor(seconds / 3600)
  const minutes: number = Math.floor((seconds % 3600) / 60)

  const result = [...(hours > 0 ? [`${hours} soat `] : []), ...(minutes > 0 ? [`${minutes} minut `] : [])]

  return result.join(" ")
}
