import { useStores } from "@/stores"
import { Divider, Flex, Form, Input, InputNumber, Modal, Select } from "antd"
import { observer } from "mobx-react"
import mainDictionary from "@/dictionary"
import styles from "./group-make.module.scss"
import classNames from "classnames/bind"
import { useEffect, useMemo, useState } from "react"
import { branchOfficeStore } from "@/pages/Controls/BranchOffice"
import { useBoolean } from "usehooks-ts"
import { groupStatus } from "../constants"
import { GroupMakeFormValues } from "@/stores/groups"
import { addCatchNotification, addSuccessNotification } from "@/modules/notifications"
import { getPerPages } from "@/shared"

const cn = classNames.bind(styles)

export const GroupMake = observer(() => {
  const { groupsStore, gamificationStore, studyDepartment, notificationStore } = useStores()
  const [form] = Form.useForm()
  const { value: isFetchingCourses, setTrue: setFetchingCourses, setFalse: setNotFetchingCourses } = useBoolean(false)
  const [categoryId, setCategoryId] = useState<number>()
  const perPageFromLocalStorage = getPerPages()
  const { value: isLoading, setTrue: setLoading, setFalse: setNotLoading } = useBoolean(false)

  const branches = useMemo(
    () =>
      branchOfficeStore.branchOffices?.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    [branchOfficeStore.branchOffices],
  )

  const learningTypes = useMemo(
    () =>
      groupsStore.learningTypes?.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    [groupsStore.learningTypes],
  )

  const users = useMemo(
    () =>
      notificationStore.users?.map((item) => ({
        label: item.name,
        value: item.userId,
      })),
    [notificationStore.users],
  )

  const courses = useMemo(() => {
    const courses = studyDepartment.allCourses?.courses?.map((item) => ({
      label: item.name,
      value: item.id,
    }))

    return courses || []
  }, [studyDepartment.allCourses])

  const categories = useMemo(
    () =>
      gamificationStore.categories?.categories?.map((item) => ({
        label: item.displayName,
        value: item.id,
      })),
    [gamificationStore.categories],
  )

  const handleCategoryChange = (value: number) => {
    setCategoryId(value)

    form.setFieldValue("courseId", [])
  }

  const handleCancel = () => {
    groupsStore.setGroupMakeModal(false)
    groupsStore.setSelectedGroupForEdit(null)
  }

  const handleSuccess = () => {
    handleCancel()

    addSuccessNotification(mainDictionary.successFullySaved)

    if (groupsStore.groupParameters?.groupId) {
      groupsStore.getGroupParameters(groupsStore.groupParameters?.groupId)

      return
    }

    groupsStore.getGroups({
      ...groupsStore.filterOptions,
      page: 1,
      perPage: perPageFromLocalStorage?.groupsPerPage || 10,
    })
  }

  const handleFinish = (values: GroupMakeFormValues) => {
    const { description, ...data } = values

    setLoading()

    if (groupsStore.selectedGroupForEdit?.id) {
      groupsStore
        .editGroup({
          id: groupsStore.selectedGroupForEdit.id,
          data,
        })
        .then((res) => {
          if (res.success) {
            handleSuccess()
          }
        })
        .catch(addCatchNotification)
        .finally(() => setNotLoading())

      return
    }

    groupsStore
      .createGroup(data)
      .then((res) => {
        if (res.success) {
          handleSuccess()
        }
      })
      .catch(addCatchNotification)
      .finally(() => setNotLoading())
  }

  const prices = useMemo(
    () =>
      // @ts-ignore
      groupsStore.coursePrices?.prices?.map((item) => ({
        label: item.value.toLocaleString("Fr-fr"),
        value: item.id,
      })),
    [groupsStore.coursePrices],
  )

  useEffect(() => {
    branchOfficeStore.getBranchOffices()
    gamificationStore.getCategories({
      page: 1,
      perPage: 100,
    })

    notificationStore.getStaff({
      page: 1,
      perPage: 10,
    })

    groupsStore.getLearningTypes()

    groupsStore.getCoursePrices({
      page: 1,
      perPage: 100,
    })
  }, [])

  useEffect(() => {
    const selectedGroup = groupsStore.selectedGroupForEdit
    if (selectedGroup) {
      form.setFieldsValue({
        name: selectedGroup.name,
        categoryId: selectedGroup.categoryId,
        courseId: selectedGroup.courseId,
        assigneeId: selectedGroup.assigneeId,
        learningTypeId: selectedGroup.learningTypeId,
        status: selectedGroup.status,
        studentsCount: selectedGroup.studentsCount,
        officeId: selectedGroup.officeId,
        priceId: selectedGroup.priceId,
      })

      setCategoryId(selectedGroup.categoryId)
    }
  }, [groupsStore.selectedGroupForEdit])

  useEffect(() => {
    const selectedGroup = groupsStore.groupParameters

    if (selectedGroup) {
      form.setFieldsValue({
        name: selectedGroup.groupName,
        categoryId: selectedGroup.categoryId,
        courseId: selectedGroup.courseId,
        assigneeId: selectedGroup.assigneeId,
        learningTypeId: selectedGroup.learningTypeId,
        status: selectedGroup.status,
        studentsCount: selectedGroup.studentsCount,
        officeId: selectedGroup.officeId,
        priceId: selectedGroup.priceId,
      })

      setCategoryId(selectedGroup.categoryId)
    }
  }, [groupsStore.groupParameters])

  useEffect(() => {
    if (categoryId) {
      setFetchingCourses()

      studyDepartment
        .getCourses({
          page: 1,
          perPage: 100,
          categoryIds: [categoryId],
        })
        .finally(() => setNotFetchingCourses())
    }
  }, [categoryId])

  return (
    <Modal
      width={600}
      className={cn("group-make")}
      title={groupsStore.selectedGroupForEdit ? mainDictionary.editGroup : mainDictionary.addGroup}
      onCancel={handleCancel}
      onOk={form.submit}
      open={groupsStore.groupMakeModal}
      okButtonProps={{ loading: isLoading }}
      okText={mainDictionary.save}
      cancelText={mainDictionary.cancel}
    >
      <Divider />
      <Form onFinish={handleFinish} form={form} layout="vertical">
        <Flex gap={17} justify="space-between">
          <Form.Item
            rules={[{ required: true, message: mainDictionary.shouldFill }]}
            className={cn("group-make__item")}
            name="name"
            label={mainDictionary.groupName}
          >
            <Input className={cn("group-make__input")} placeholder={mainDictionary.group} />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: mainDictionary.shouldFill }]}
            className={cn("group-make__item")}
            name="officeId"
            label={mainDictionary.branch}
          >
            <Select options={branches} placeholder={mainDictionary.branch} />
          </Form.Item>
        </Flex>

        <Flex gap={17} justify="space-between">
          <Form.Item
            rules={[{ required: true, message: mainDictionary.shouldFill }]}
            className={cn("group-make__item")}
            name="categoryId"
            label={mainDictionary.category}
          >
            <Select
              onChange={handleCategoryChange}
              options={categories}
              className={cn("group-make__input")}
              placeholder={mainDictionary.category}
            />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: mainDictionary.shouldFill }]}
            className={cn("group-make__item")}
            name="courseId"
            label={mainDictionary.course}
          >
            <Select
              disabled={isFetchingCourses}
              options={courses}
              className={cn("group-make__input")}
              placeholder={mainDictionary.course}
            />
          </Form.Item>
        </Flex>

        <Flex gap={17} justify="space-between">
          <Form.Item
            rules={[{ required: true, message: mainDictionary.shouldFill }]}
            className={cn("group-make__item")}
            name="assigneeId"
            label={mainDictionary.agent}
          >
            <Select
              filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
              showSearch
              options={users}
              className={cn("group-make__input")}
              placeholder={mainDictionary.agent}
            />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: mainDictionary.shouldFill }]}
            className={cn("group-make__item")}
            name="priceId"
            label={mainDictionary.primaryPrice}
          >
            <Select options={prices} placeholder={mainDictionary.primaryPrice} />
          </Form.Item>
        </Flex>

        <Flex gap={17} justify="space-between">
          <Form.Item
            rules={[{ required: true, message: mainDictionary.shouldFill }]}
            className={cn("group-make__item")}
            name="studentsCount"
            label={mainDictionary.countOfSeats}
          >
            <InputNumber min={1} className={cn("group-make__input")} placeholder={mainDictionary.countOfSeats} />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: mainDictionary.shouldFill }]}
            className={cn("group-make__item")}
            name="status"
            label={mainDictionary.groupStatus}
          >
            <Select options={groupStatus.slice(1)} placeholder={mainDictionary.groupStatus} />
          </Form.Item>
        </Flex>

        <Form.Item
          rules={[{ required: true, message: mainDictionary.shouldFill }]}
          className={cn("group-make__status")}
          name="learningTypeId"
          label={mainDictionary.type}
        >
          <Select placeholder={mainDictionary.learningTypes} options={learningTypes} />
        </Form.Item>
      </Form>
      <Divider />
    </Modal>
  )
})
