import { ROUTES } from "@/constants"
import { AvailableComponent } from "@/modules/AvailableComponent"
import { permissionObjectTypes } from "@/modules/permission"
import { CustomFields } from "./CustomFields"
import {
  Company,
  MobileAppVersions,
  SettingsFinance,
  SettingsGamificationOffering,
  SettingsGamificationOfferingUpdate,
  Staff,
  StudentStatuses,
  StudentStatusesMake,
} from "./lazy"

export const settingsRouters = [
  {
    path: ROUTES.settings,
    children: [
      {
        path: ROUTES.settingsPublicOffering,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settingsCompany}>
            <SettingsGamificationOffering />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.settingsPublicOfferingUpdate,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settingsCompany}>
            <SettingsGamificationOfferingUpdate />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.settingsStudentStatuses,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settingsStudentStatuses}>
            <StudentStatuses />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.settingsStudentStatusesEdit,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settingsStudentStatuses}>
            <StudentStatusesMake />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.settingsMobileApp,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settingsMobileVersions}>
            <MobileAppVersions />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.settingsFinance,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.profile}>
            <SettingsFinance />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.settingsStaff,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settingsStaff}>
            <Staff />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.settingsCompany,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settingsCompany}>
            <Company />
          </AvailableComponent>
        ),
      },
      {
        path: ROUTES.settingsCustomFields,
        element: (
          <AvailableComponent permissionName={permissionObjectTypes.settingsCustomFields}>
            <CustomFields />
          </AvailableComponent>
        ),
      },
    ],
  },
]
