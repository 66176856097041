import { SegmentedOptions } from "antd/es/segmented"
import mainDictionary from "@/dictionary"

export enum FinanceKyes {
  Prices = "1",
  Discounts = "2",
  AdditionalFees = "3",
  Categories = "4",
  Paymens = "5",
  Tariffs = "6",
  CalcAuto = "7",
  OtherInfo = "8",
}

export enum PricesUnits {
  Day = "day",
  Minute = "minute",
  Calendar = "Calendar",
}

export enum pricesSegmentedValues {
  ForHoursPrice = "1",
  ForDaysPrice = "2",
  ForCalendarPrice = "3",
}

export const pricesSegmentedItems: SegmentedOptions<PricesUnits> = [
  {
    label: mainDictionary.forHoursPrice,
    value: PricesUnits.Minute,
  },
  {
    label: mainDictionary.forDaysPrice,
    value: PricesUnits.Day,
  },
  {
    label: mainDictionary.forCalendarPrice,
    value: PricesUnits.Calendar,
    disabled: true,
  },
]

export enum pricesFilterSegmentedValue {
  Daily = "1",
  Package = "2",
}

export const pricesFilterSegmentedItems = [
  {
    label: mainDictionary.settingsDaily,
    value: false,
  },
  {
    label: mainDictionary.settingsPackage,
    value: true,
  },
]

export enum PaymentSegmentedValues {
  PaymentType = "types",
  PaymentMethod = "methods",
  Tag = "3",
}

export const paymentsSegmentedItems: SegmentedOptions<PaymentSegmentedValues> = [
  {
    label: mainDictionary.paymentType,
    value: PaymentSegmentedValues.PaymentType,
  },
  {
    label: mainDictionary.paymentMethod,
    value: PaymentSegmentedValues.PaymentMethod,
  },
  {
    label: mainDictionary.tag,
    value: PaymentSegmentedValues.Tag,
    disabled: true,
  },
]

export enum FinanceDiscountsTypes {
  Fixed = "fixed",
  Percent = "percent",
}

export const financeDiscountsTypes = [
  {
    label: mainDictionary.fixed,
    value: FinanceDiscountsTypes.Fixed,
  },
  {
    label: mainDictionary.percent,
    value: FinanceDiscountsTypes.Percent,
  },
]

export const enum DayType {
  LESSON = "lesson",
  FREE_LESSON = "free_lesson",
  CHARGEABLE_ABSENCE = "chargeable_absence",
  NONCHARGEABLE_ABSENCE = "nonchargeable_absence",
}

export const dayTypes = [
  {
    label: mainDictionary.dayTypeLesson,
    value: DayType.LESSON,
  },
  {
    label: mainDictionary.freeLesson,
    value: DayType.FREE_LESSON,
  },
  {
    label: mainDictionary.chargeableAbsence,
    value: DayType.CHARGEABLE_ABSENCE,
  },
  {
    label: mainDictionary.nonChargeableAbsence,
    value: DayType.NONCHARGEABLE_ABSENCE,
  },
]

export const academicPriceInitialValues = [{ minStudentCount: 0, value: null }]

export const MIN_LENGTH = 1

export const COMMA_REGEX = /,/g

export enum FinancePaymentsMethods {
  UZUM = "uzum",
  PAYME = "payme",
  CLICK = "click",
  PAYNET = "paynet",
}

export const financePaymentsMethodsSelect = [
  {
    label: mainDictionary.grapes,
    value: FinancePaymentsMethods.UZUM,
  },
  {
    label: mainDictionary.payme,
    value: FinancePaymentsMethods.PAYME,
  },
  {
    label: mainDictionary.click,
    value: FinancePaymentsMethods.CLICK,
  },
  {
    label: mainDictionary.paynet,
    value: FinancePaymentsMethods.PAYNET,
  },
]

export enum CoursesItems {
  Marketing = 1,
  Programming = 2,
  Design = 3,
}

export const coursesItemsSelect = [
  {
    label: mainDictionary.marketing,
    value: CoursesItems.Marketing,
  },
  {
    label: mainDictionary.programming,
    value: CoursesItems.Programming,
  },
  {
    label: mainDictionary.design,
    value: CoursesItems.Design,
  },
]
