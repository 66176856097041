import { useEffect, useMemo } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { MoreOutlined } from "@ant-design/icons"
import { Button, Flex, Table, TableProps, Tag } from "antd"
import classNames from "classnames/bind"
import { Exam as ExamType, ExamStatus } from "@/api/groups"
import UserIcon from "@/assets/icons/user-icon.svg"
import XIcon from "@/assets/icons/x.svg"
import { ROUTES } from "@/constants"
import mainDictionary from "@/dictionary"
import { examStatuses, lessonTimeFormatter } from "@/pages/Groups/constants"
import { useStores } from "@/stores"
import { formatDate, fullTimeToFormattedDate, getViseRowIndex } from "@/utils"

import styles from "./exam.module.scss"
const cn = classNames.bind(styles)

export const Exam = observer(() => {
  const { groupsStore } = useStores()
  const { id } = useParams()
  const navigate = useNavigate()

  const handleAddClick = () => {
    if (!id) return

    navigate(ROUTES.examCreate.replace(":id", id))
  }

  const columns: TableProps<ExamType>["columns"] = useMemo(
    () => [
      {
        title: mainDictionary.order,
        width: 50,
        render: (item: ExamType, record: ExamType, index: number) => (
          <span>
            {getViseRowIndex(
              groupsStore.examsFilters?.page,
              groupsStore?.examsFilters?.perPage,
              index,
              groupsStore.exams?.total || 1,
            )}
          </span>
        ),
      },
      {
        title: mainDictionary.topic,
        render: (item: ExamType) => <span>{item.name}</span>,
      },
      {
        title: <img src={UserIcon} alt="user" />,
        width: 50,
        align: "center",
        render: (item: ExamType) => <span>{item?.studentsCount}</span>,
      },
      {
        title: <img src={XIcon} alt="x" />,
        width: 50,
        align: "center",
        render: (item: ExamType) => <span>{item?.failedStudentsCount}</span>,
      },
      {
        title: mainDictionary.status,
        width: 60,
        align: "center",
        render: (item: ExamType) => (
          <Flex justify="center">
            <Tag color={item.status === ExamStatus.InProgress ? "success" : "default"} className={cn("exam__tag")}>
              {examStatuses[item?.status]}
            </Tag>
          </Flex>
        ),
      },
      {
        title: mainDictionary.lessonTime,
        align: "center",
        width: 145,
        render: (item: ExamType) => (
          <span>
            {`${fullTimeToFormattedDate(item?.lessonDate as string)} ${lessonTimeFormatter?.(item?.lessonBeginTime)}`}
          </span>
        ),
      },
      {
        title: mainDictionary.givenTime,
        align: "center",
        width: 145,
        render: (item: ExamType) => <span>{formatDate(item?.createdAt, undefined, true)}</span>,
      },
      {
        title: mainDictionary.announcedDate,
        width: 140,
        render: (item: ExamType) => <span>{formatDate(item?.announcedAt, undefined, true)}</span>,
      },
      {
        width: 50,
        render: (item: ExamType) => <Button type="text" icon={<MoreOutlined />} />,
      },
    ],
    [],
  )

  useEffect(() => {
    if (!id) return

    const parsedId = parseInt(id, 10)

    groupsStore.setExamsLoading(true)

    groupsStore.getExams({
      ...groupsStore.examsFilters,
      id: parsedId,
    })
  }, [groupsStore.examsFilters])

  return (
    <Flex className={cn("exam")} vertical gap={20}>
      <Flex justify="flex-end">
        <Button onClick={handleAddClick} type="primary">
          {mainDictionary.newExam}
        </Button>
      </Flex>

      <Table
        className={cn("exam__table")}
        pagination={false}
        loading={groupsStore.examsLoading}
        columns={columns}
        dataSource={groupsStore.exams?.exams || []}
      />
    </Flex>
  )
})
