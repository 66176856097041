import { observer } from "mobx-react"
import classNames from "classnames/bind"
import { StoreTitle, Table } from "@/components/Table"
import { getPaginationParams } from "@/pages/utils"
import { getPerPages } from "@/shared"
import { useStores } from "@/stores"
import { formattedColumns } from "./util"

import styles from "./table.module.scss"

const cn = classNames.bind(styles)

interface ITableProps {
  isMyGroup?: boolean
}

export const GroupTable = observer(({ isMyGroup }: ITableProps) => {
  const { groupsStore } = useStores()
  const perPageFromLocalStorage = getPerPages()

  const handleTablePaginationChange = (value: number, perPage: number) => {
    const info = {
      ...groupsStore.filterOptions,
      page: value,
      perPage,
    }

    groupsStore.setFilterOption(info)
    groupsStore.getGroups(info)
  }

  const handleShowSizeChange = (page: number, perPage: number) => {
    groupsStore.setFilterOption({
      ...groupsStore.filterOptions,
      perPage,
      page,
    })
  }

  return (
    <Table
      columns={
        formattedColumns({
          availableColumns: groupsStore.columnsSettingsValues,
        }) || []
      }
      key={groupsStore.columnsSettingsValues.length}
      rowKey="id"
      className={cn("group-table")}
      scroll={{ x: 1600 }}
      dataSource={groupsStore.groups?.groups}
      onStoreChange={handleShowSizeChange}
      storeTitle={isMyGroup ? StoreTitle.MyGroupPerPage : StoreTitle.GroupsPerPage}
      loading={groupsStore.groupsTableLoading}
      pagination={{
        current: groupsStore.filterOptions?.page || 1,
        total: groupsStore.groups?.total!,
        onChange: handleTablePaginationChange,
        ...getPaginationParams({
          total: groupsStore.groups?.total!,
          notShowSizeChanger: false,
        }),
        showSizeChanger: true,
        pageSize: isMyGroup ? perPageFromLocalStorage?.myGroupsPerPage : perPageFromLocalStorage?.groupsPerPage!,
      }}
    />
  )
})
