import { useNavigate, useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { CaretRightOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Flex, Popover, Typography } from "antd"
import classNames from "classnames/bind"
import { DayType } from "@/api/groups"
import { ROUTES } from "@/constants"
import mainDictionary from "@/dictionary"
import { getDayOfMonth, getDayOfWeek } from "@/utils"

import styles from "./calendar-day.module.scss"

const cn = classNames.bind(styles)

type Props = {
  category?: DayType
  date?: string
  academicId?: number
  scheduleId: number
  page?: number
  perPage?: number
}

export const CalendarDay = observer(({ category, date, academicId, scheduleId, page, perPage }: Props) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const handleStartLesson = () => {
    if (!id || !academicId || !scheduleId) return

    const route = `${ROUTES.groupAttendance.replace(":id", id).replace(":scheduleId", scheduleId.toString()).replace(":academicId", academicId?.toString())}?date=${date}&${page ? `page=${page}` : ""}&${perPage ? `perPage=${perPage}` : ""}`

    navigate(route)
  }

  return (
    <Popover
      content={
        <Flex vertical gap={10}>
          <Flex gap={12}>
            <EditOutlined />
            <Button block type="text">
              {mainDictionary.edit}
            </Button>
          </Flex>
          <Flex gap={12} align="center">
            <CaretRightOutlined style={{ color: "#0EB182" }} />
            <Button onClick={handleStartLesson} className={cn("calendar__edit")} type="text">
              {mainDictionary.startLesson}
            </Button>
          </Flex>
        </Flex>
      }
      trigger="click"
    >
      <Flex className={cn("calendar-day", category)} vertical gap={4}>
        <Typography.Text className={cn("calendar-day__week")}>{getDayOfWeek(date) || "Se"}</Typography.Text>
        <Typography.Text className={cn("calendar-day__day")}>{getDayOfMonth(date) || "15"}</Typography.Text>
      </Flex>
    </Popover>
  )
})
