import { makeAutoObservable } from "mobx"
import {
  ClassRoomsRes,
  DateSchemaRes,
  Exam,
  ExamChat,
  ExamChatsStatus,
  GetExamRes,
  GetHomeworkRes,
  GetJournalListRes,
  GetTeacherAttendanceRes,
  GroupAcademicHour,
  GroupDetail,
  GroupInfo,
  GroupParameters,
  groupsApi,
  GroupSchedule,
  GroupScheduleRes,
  GroupStatistics,
  GroupStatisticsAcademic,
  GroupStudentsRes,
  HomeworkChatsStatus,
  HomeworkInfo,
  HomeworkInfoCountRes,
  IGetStudyMonthRes,
  IGroup,
  IGroupsResponse,
  IGroupStatus,
  IHomeworkResponse,
  ILessonsResponse,
  IVideo,
  IVideosResponse,
  LearningType,
  PriceRes,
  StudentDuplicationsRes,
} from "@/api/groups"
import { IStudents } from "@/api/students"
import mainDictionary from "@/dictionary"
import { UploadedVideoId } from "@/modules/kinescope/types"
import { addCatchNotification } from "@/modules/notifications"
import { GroupLessonTabs } from "@/pages/Groups/constants"
import {
  ChangeAcademicHourParams,
  ColumnSettings,
  DuplicationsActionReq,
  EditExamInfo,
  EditScheduleReq,
  GetClassRoomsReq,
  GetExamChatsReq,
  GetExamsReq,
  GetGroupAcademicHours,
  GetGroupSchedulesReq,
  GetGroupStudentsReq,
  GetHomeworkReq,
  GetStudentDuplicationsReq,
  GetStudentSchemaReq,
  GetTeacherAttendanceReq,
  GetTeacherScheduleSchemaReq,
  GroupEditParams,
  GroupMakeFormValues,
  IGetGroupsRequest,
  IGetHomeworks,
  IGetHomeworkUploadUrl,
  IGetIncompleteStudentsRequest,
  IGetJournalsReq,
  IGetLessonsRequest,
  IGetVideosRequest,
  ITotalColumns,
  ITotalData,
  IUpdateVideoRequest,
  JournalTableDays,
  MakeExamInfo,
  PriceRequest,
  ScheduleItemFormValues,
} from "./types"

class GroupsStore {
  // Boolean
  columnsSettingsModal = false
  groupsTableLoading = false
  scheduleModal = false
  groupMakeModal = false
  groupInfoLoader = false
  groupParametersLoading = true
  groupStatisticsLoading = true
  groupDetailLoading = true
  groupScheduleLoading = true
  statusHistoryModal = false
  groupStudentsLoading = true
  makeStudentModal = false
  videoLoader = false
  isKinescopePlayerOpen = false
  isVideoPlayerOpen = false
  addVideo = false
  studentDuplicationsLoading = false
  attendanceLoading = false
  homeworkLoading = false
  examsLoading = false
  homeworkStatusIcon = true
  journalLoader = false
  journalTableLoading = false
  homeworkInfoModal = false
  homeworkChatsLoading = false
  notDoneStudentsLoading = false
  examChatsLoading = false
  academicHoursModal = false
  teacherAttendanceLoading = false

  // Data
  columnsSettingsValues: ColumnSettings[] = []
  groups: IGroupsResponse | null = null
  selectedOption: string | IGroupStatus | null = IGroupStatus.Active
  coursePrices: PriceRes | null = null
  learningTypes: LearningType[] | null = null
  selectedGroupForEdit: IGroup | null = null
  groupInfo: GroupInfo | null = null
  uploadedVideoIds: UploadedVideoId[] = []
  groupParameters: GroupParameters | null = null
  groupStatistics: GroupStatistics | null = null
  homeworkInfo: HomeworkInfo | null = null
  groupDetail: GroupDetail | null = null
  groupSchedule: GroupScheduleRes | null = null
  videos: IVideosResponse | null = null
  classRooms: ClassRoomsRes | null = null
  selectedSchedule: GroupSchedule | null = null
  groupStudents: GroupStudentsRes | null = null
  studentSchema: Record<number, DateSchemaRes> | null = null
  academicScheduleSchema: Record<number, DateSchemaRes> | null = null
  attendanceSchema: DateSchemaRes | null = null
  studentDuplications: StudentDuplicationsRes | null = null
  activeGroupLessonsTab: GroupLessonTabs = GroupLessonTabs.Homework
  selectedVideo: IVideo | null = null
  lessons: ILessonsResponse | null = null
  homework: GetHomeworkRes | null = null
  exams: GetExamRes | null = null
  singleExam: Exam | null = null
  journalDays: JournalTableDays[] | null = null
  journals: GetJournalListRes | null = null
  journalTable: ITotalColumns[] | null = null
  homeworkCount: HomeworkInfoCountRes | null = null
  examChatsCount: HomeworkInfoCountRes | null = null
  homeworkChats: IHomeworkResponse | null = null
  homeworkTabs: HomeworkChatsStatus | string = HomeworkChatsStatus.WAITING
  examTabs: ExamChatsStatus | string = ExamChatsStatus.WAITING
  notDoneStudents: IStudents[] | null = null
  examChats: ExamChat[] | null = null
  selectedGroupAcademic: GroupStatisticsAcademic | null = null
  selectedAcademicGroupPrices: GroupAcademicHour[] | null = null
  teacherAttendance: GetTeacherAttendanceRes | null = null

  // Filters
  videosCurrentPage = 1
  videosPage = 1
  filterOptions: IGetGroupsRequest = {
    page: 1,
    keys: ["hName"],
    status: IGroupStatus.Active,
  }

  key: string | null = null
  videoPage = 1
  videosPerPage = 10

  homeworkFilterOptions: GetHomeworkReq = {
    page: 1,
    perPage: 10,
  }

  journalObjectFilters: IGetStudyMonthRes | null = null

  examsFilters: GetExamsReq = {
    page: 1,
    perPage: 100,
  }

  studentDuplicationsFilterOptions: GetStudentDuplicationsReq = {
    page: 1,
    perPage: 10,
  }

  teacherAttendancePagination: GetTeacherAttendanceReq = {
    page: 1,
    perPage: 10,
  }

  attendanceFilterOptions: GetTeacherScheduleSchemaReq | null = null

  studentSchemaFilterOption: null | Record<number, GetStudentSchemaReq> = null
  academicScheduleSchemaOption: null | Record<number, GetTeacherScheduleSchemaReq> = null

  constructor() {
    makeAutoObservable(this)
  }

  // Setters

  setGroupDetailLoading = (params: boolean) => {
    this.groupDetailLoading = params
  }

  setTeacherAttendanceLoading = (params: boolean) => {
    this.teacherAttendanceLoading = params
  }

  setTeacherAttendancePagination = (params: GetTeacherAttendanceReq) => {
    this.teacherAttendancePagination = params
  }

  setExamTabs = (params: string) => {
    this.examTabs = params
  }

  setExamChatsCount = (params: HomeworkInfoCountRes | null) => {
    this.examChatsCount = params
  }

  setSelectedGroupAcademic = (params: GroupStatisticsAcademic | null) => {
    this.selectedGroupAcademic = params
  }

  setTeacherAttendance = (params: GetTeacherAttendanceRes | null) => {
    this.teacherAttendance = params
  }

  setAcademicHoursModal = (params: boolean) => {
    this.academicHoursModal = params
  }

  setSelectedAcademicGroupPrices = (params: GroupAcademicHour[] | null) => {
    this.selectedAcademicGroupPrices = params
  }

  setExamChats = (params: ExamChat[] | null) => {
    this.examChats = params
  }

  setExamChatsLoading = (params: boolean) => {
    this.examChatsLoading = params
  }

  setHomeworkChatsLoading = (params: boolean) => {
    this.homeworkChatsLoading = params
  }

  setNotDoneStudents = (params: IStudents[] | null) => {
    this.notDoneStudents = params
  }

  setNotDoneStudentsLoading = (params: boolean) => {
    this.notDoneStudentsLoading = params
  }

  setHomeworkTabs = (params: string) => {
    this.homeworkTabs = params
  }

  setHomeworkChats = (params: IHomeworkResponse | null) => {
    this.homeworkChats = params
  }

  setJournalLoader = (params: boolean) => {
    this.journalLoader = params
  }

  setJournalTableLoading = (params: boolean) => {
    this.journalTableLoading = params
  }

  setHomeworkInfoModal = (params: boolean) => {
    this.homeworkInfoModal = params
  }

  setHomeworkStatusIcon = (params: boolean) => {
    this.homeworkStatusIcon = params
  }

  setHomeworkInfo = (params: HomeworkInfo | null) => {
    this.homeworkInfo = params
  }

  setExamsLoading = (params: boolean) => {
    this.examsLoading = params
  }

  setHomeworkCount = (params: HomeworkInfoCountRes | null) => {
    this.homeworkCount = params
  }

  setExamsFilter = (params: GetExamsReq) => {
    this.examsFilters = params
  }

  setExams = (params: GetExamRes | null) => {
    this.exams = params
  }

  setHomeworkFilterOptions = (params: GetHomeworkReq) => {
    this.homeworkFilterOptions = params
  }

  setHomework = (params: GetHomeworkRes | null) => {
    this.homework = params
  }

  setHomeworkLoading = (params: boolean) => {
    this.homeworkLoading = params
  }

  setAttendanceLoading = (params: boolean) => {
    this.attendanceLoading = params
  }

  setAttendanceSchema = (params: DateSchemaRes | null) => {
    this.attendanceSchema = params
  }

  setAttendanceFilterOptions = (params: GetTeacherScheduleSchemaReq | null) => {
    this.attendanceFilterOptions = params
  }

  setActiveGroupLessonsTab = (params: GroupLessonTabs) => {
    this.activeGroupLessonsTab = params
  }

  setSelectedVideo = (video: IVideo | null) => {
    this.selectedVideo = video
  }

  setAcademicScheduleSchema = (params: Record<number, DateSchemaRes> | null) => {
    this.academicScheduleSchema = params
  }

  setStatusHistoryModal = (params: boolean) => {
    this.statusHistoryModal = params
  }

  setIsVideoPlayerOpen = (params: boolean) => {
    this.isVideoPlayerOpen = params
  }

  setAcademicScheduleSchemaOption = (params: null | Record<number, GetTeacherScheduleSchemaReq>) => {
    this.academicScheduleSchemaOption = params
  }

  setCurrentVideosPage = (params: number) => {
    this.videosCurrentPage = params
  }

  setMakeStudentModal = (params: boolean) => {
    this.makeStudentModal = params
  }

  setStudentDuplications = (params: StudentDuplicationsRes | null) => {
    this.studentDuplications = params
  }

  setVideosPage = (params: number) => {
    this.videosPage = params
  }

  setStudentSchema = (params: Record<number, DateSchemaRes> | null) => {
    this.studentSchema = params
  }

  setVideoLoader = (params: boolean) => {
    this.videoLoader = params
  }

  setUploadedVideoIds = (ids: UploadedVideoId[]) => {
    this.uploadedVideoIds = ids
  }

  setIsKinescopePlayerOpen = (params: boolean) => {
    this.isKinescopePlayerOpen = params
  }

  setStudentDuplicationsLoading = (params: boolean) => {
    this.studentDuplicationsLoading = params
  }

  setVideosPerPage = (params: number) => {
    this.videosPerPage = params
  }

  setAddVideoModal = (params: boolean) => {
    this.addVideo = params
  }

  setStudentDuplicationsFilterOptions = (params: GetStudentDuplicationsReq) => {
    this.studentDuplicationsFilterOptions = params
  }

  setStudentSchemaFilterOption = (params: null | Record<number, GetStudentSchemaReq>) => {
    this.studentSchemaFilterOption = params
  }

  setGroupStudentsLoading = (params: boolean) => {
    this.groupStudentsLoading = params
  }

  setGroupStudents = (params: GroupStudentsRes | null) => {
    this.groupStudents = params
  }

  setSelectedSchedule = (params: GroupSchedule | null) => {
    this.selectedSchedule = params
  }

  setClassRooms = (params: ClassRoomsRes | null) => {
    this.classRooms = params
  }

  setGroupDetail = (params: GroupDetail | null) => {
    this.groupDetail = params
  }

  setKey = (params: string) => {
    this.key = params
  }

  setGroupParametersLoading = (params: boolean) => {
    this.groupParametersLoading = params
  }

  setGroupStatisticsLoading = (params: boolean) => {
    this.groupStatisticsLoading = params
  }

  setGroupStatistics = (params: GroupStatistics | null) => {
    this.groupStatistics = params
  }

  setGroupInfoLoader = (params: boolean) => {
    this.groupInfoLoader = params
  }

  setGroupInfo = (params: GroupInfo | null) => {
    this.groupInfo = params
  }

  setSelectedOption = (value: string | IGroupStatus | null) => {
    this.selectedOption = value
  }

  setGroups = (params: IGroupsResponse | null) => {
    this.groups = params
  }

  setSingleExam = (params: Exam | null) => {
    this.singleExam = params
  }

  setGroupsTableLoading = (params: boolean) => {
    this.groupsTableLoading = params
  }

  setSelectedGroupForEdit = (params: IGroup | null) => {
    this.selectedGroupForEdit = params
  }

  setJournalTable = (params: ITotalColumns[] | null) => {
    this.journalTable = params
  }

  setLearningTypes = (params: LearningType[] | null) => {
    this.learningTypes = params
  }

  setCoursePrices = (params: PriceRes | null) => {
    this.coursePrices = params
  }

  setGroupParameters = (params: GroupParameters | null) => {
    this.groupParameters = params
  }

  setGroupMakeModal = (params: boolean) => {
    this.groupMakeModal = params
  }

  setJournalObjectFilterObjects = (params: IGetStudyMonthRes | null) => {
    this.journalObjectFilters = params
  }

  setScheduleModal = (params: boolean) => {
    this.scheduleModal = params
  }

  setColumnsSettingsValues = (params: ColumnSettings[]) => {
    this.columnsSettingsValues = params
  }

  setColumnsSettingsModal = (params: boolean) => {
    this.columnsSettingsModal = params
  }

  setJournals = (params: GetJournalListRes | null) => {
    this.journals = params
  }

  setFilterOption = (params: IGetGroupsRequest) => {
    this.filterOptions = params
  }

  setGroupScheduleLoading = (params: boolean) => {
    this.groupScheduleLoading = params
  }

  setGroupSchedule = (params: GroupScheduleRes | null) => {
    this.groupSchedule = params
  }

  // API

  getStudentSchema = (params: GetStudentSchemaReq) => groupsApi.getStudentSchema(params)

  getAcademicScheduleSchema = (params: GetTeacherScheduleSchemaReq) => groupsApi.getAcademicSchedule(params)

  getGroupStudents = (params: GetGroupStudentsReq) =>
    groupsApi
      .getGroupStudents(params)
      .then((res) => {
        if (res.success) {
          this.setGroupStudents(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setGroupStudentsLoading(false))

  createExam = (params: MakeExamInfo) => groupsApi.addExam(params)

  editExam = (params: EditExamInfo) => groupsApi.editExam(params)

  getSingleExam = (params: number) =>
    groupsApi
      .getSingleExam(params)
      .then((res) => {
        if (res.success) {
          this.setSingleExam(res.data)
        }
      })
      .catch(addCatchNotification)

  getExamChats = (params: GetExamChatsReq) =>
    groupsApi
      .getExamChats(params)
      .then((res) => {
        if (res.success) {
          this.setExamChats(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setExamChatsLoading(false))

  getHomeworkChats = (params: IGetHomeworks) =>
    groupsApi
      .getHomeworkChats(params)
      .then((res) => {
        if (res.success) {
          this.setHomeworkChats(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setHomeworkChatsLoading(false))

  getIncompleteHomeworkStudents = (params: IGetIncompleteStudentsRequest) =>
    groupsApi
      .getIncompleteHomeworkStudents(params)
      .then((res) => {
        if (res.success) {
          this.setNotDoneStudents(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setNotDoneStudentsLoading(false))

  getExamChatsCount = (id: number) =>
    groupsApi
      .getExamChatsCount(id)
      .then((res) => {
        if (res.success) {
          this.setExamChatsCount(res.data)
        }
      })
      .catch(addCatchNotification)

  getExams = (params: GetExamsReq) =>
    groupsApi
      .getExams(params)
      .then((res) => {
        if (res.success) {
          this.setExams(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setExamsLoading(false))

  getHomeworkCount = (params: number) =>
    groupsApi
      .getHomeworkCount(params)
      .then((res) => {
        if (res.success) {
          this.setHomeworkCount(res.data)
        }
      })
      .catch(addCatchNotification)

  getTeacherAttendance = (params: GetTeacherAttendanceReq) =>
    groupsApi
      .getTeacherAttendance(params)
      .then((res) => {
        if (res.success) {
          this.setTeacherAttendance(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setTeacherAttendanceLoading(false))

  getHomework = (params: GetHomeworkReq) =>
    groupsApi
      .getHomework(params)
      .then((res) => {
        if (res.success) {
          this.setHomework(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setHomeworkLoading(false))

  getAllVideos = (params: IGetVideosRequest) =>
    groupsApi
      .getAllVideos(params)
      .then((res) => {
        if (res.success) {
          this.videos = res.data
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setVideoLoader(false))

  getLessons = (params: IGetLessonsRequest) =>
    groupsApi
      .getLessons(params)
      .then((res) => {
        if (res.success) {
          this.lessons = res.data
        }
      })
      .catch(addCatchNotification)

  updateVideo = (params: IUpdateVideoRequest) => groupsApi.updateVideo(params)

  deleteVideo = (params: number) => groupsApi.deleteVideo(params)

  deleteHomework = (id: number) => groupsApi.deleteHomework(id)

  mergeDuplicate = (params: DuplicationsActionReq) => groupsApi.mergeDuplicate(params)

  ignoreDuplicate = (params: DuplicationsActionReq) => groupsApi.ignoreDuplicate(params)

  getStudentDuplications = (params: GetStudentDuplicationsReq) =>
    groupsApi
      .getStudentDuplications(params)
      .then((res) => {
        if (res.success) {
          this.setStudentDuplications(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setStudentDuplicationsLoading(false))

  changeAcademicHour = (params: ChangeAcademicHourParams) => groupsApi.changeAcademicHour(params)

  getGroupDetail = (id: number) =>
    groupsApi
      .getGroupDetail(id)
      .then((res) => {
        if (res.success) {
          this.setGroupDetail(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setGroupDetailLoading(false))

  getGroupAcademicHours = (params: GetGroupAcademicHours) =>
    groupsApi
      .getGroupAcademicHours(params)
      .then((res) => {
        if (res.success) {
          this.setSelectedAcademicGroupPrices(res.data)
        }
      })
      .catch(addCatchNotification)

  editSchedule = (params: EditScheduleReq) => groupsApi.editSchedule(params)

  deleteSchedule = (id: number) => groupsApi.deleteSchedule(id)

  getGroups = (params?: IGetGroupsRequest) =>
    groupsApi
      .getGroups(params)
      .then((res) => {
        if (res.success) {
          this.setGroups(res.data)
        }

        return res
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.groupsTableLoading = false
      })

  addGroupScheduleItem = (params: ScheduleItemFormValues) => groupsApi.addScheduleItem(params)

  getClassRooms = (params: GetClassRoomsReq) =>
    groupsApi
      .getClassRooms(params)
      .then((res) => {
        if (res.success) {
          this.setClassRooms(res.data)
        }
      })
      .catch(addCatchNotification)

  getLearningTypes = () =>
    groupsApi
      .getLearningTypes()
      .then((res) => {
        if (res.success) {
          this.setLearningTypes(res.data)
        }
      })
      .catch(addCatchNotification)

  createGroup = (params: GroupMakeFormValues) => groupsApi.createGroup(params)

  deleteGroup = (id: number) => groupsApi.deleteGroup(id)

  editGroup = (data: GroupEditParams) => groupsApi.editGroup(data)

  getGroupInfo = (id: number) =>
    groupsApi
      .getGroupInfo(id)
      .then((res) => {
        if (res.success) {
          this.setGroupInfo(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setGroupInfoLoader(false))

  getGroupStatistics = (id: number) =>
    groupsApi
      .getGroupStatistics(id)
      .then((res) => {
        if (res.success) {
          this.setGroupStatistics(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setGroupStatisticsLoading(false))

  getCoursePrices = (params: PriceRequest) =>
    groupsApi
      .getCoursePrices(params)
      .then((res) => {
        if (res.success) {
          this.setCoursePrices(res.data)
        }
      })
      .catch(addCatchNotification)

  getGroupParameters = (id: number) =>
    groupsApi
      .getGroupParameters(id)
      .then((res) => {
        if (res.success) {
          this.setGroupParameters(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setGroupParametersLoading(false))

  getJournals = (params: IGetJournalsReq) =>
    groupsApi
      .getJournals(params)
      .then((res) => {
        if (res.success) {
          const updatedDates = res.data.days.map((item) => ({
            ...item,
            lessonId: item.lessonId,
          }))

          this.journalDays = [
            ...updatedDates,
            { title: mainDictionary.attendanceTitle },
            { title: mainDictionary.homeworkColumn },
          ]
          this.journals = res.data

          const additionalInfo = [
            {
              firstName: mainDictionary.haveLesson,
              lastName: mainDictionary.countOfStudentWhoHasInLesson,
              attendanceAverage: res?.data?.additionalStats.attendancePercentage,
            },
            {
              firstName: mainDictionary.acceptHomework,
              lastName: mainDictionary.countOfStudentWhoWasAcceptedTheirHomework,
              homeworkAverage: res.data?.additionalStats?.homeworkPercentage,
            },
          ]

          const data: ITotalData[] = [...res.data.students!, ...additionalInfo!]

          const columns: ITotalColumns[] = data?.map((item: ITotalData) => ({
            date: res.data?.days,
            student: {
              ...item,
              stats: item?.stats?.length
                ? [...item.stats, { attendance: item?.attendance }, { homework: item?.homework }]
                : item.stats,
            },
            stats: res.data?.additionalStats!,
          }))

          this.setJournalTable(columns)
        }
      })
      .catch(addCatchNotification)
      .finally(() => {
        this.setJournalTableLoading(false)
        this.setJournalLoader(false)
      })

  getHomeworkUrl = (data: IGetHomeworkUploadUrl) => groupsApi.getHomeworkUploadUrl(data)

  getJournalFilterObjects = (id: number) =>
    groupsApi.getJournalFilterObjects(id).then((res) => {
      if (res.success) {
        this.setJournalObjectFilterObjects(res.data)
      }
    })

  getGroupSchedule = (params: GetGroupSchedulesReq) =>
    groupsApi
      .getGroupSchedule(params)
      .then((res) => {
        if (res.success) {
          this.setGroupSchedule(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => this.setGroupScheduleLoading(false))

  // Reset

  resetJournalObjectFilters = () => {
    this.journalObjectFilters = null
  }

  resetGroupSingle = () => {
    this.setGroupInfo(null)
    this.setGroupParameters(null)
    this.setGroupStatistics(null)
    this.setGroupDetail(null)
    this.setGroupSchedule(null)
  }
}

export const groupsStore = new GroupsStore()
