import { Flex, Tooltip } from "antd"
import classNames from "classnames/bind"
import { IVideo } from "@/api/groups"
import VideoIcon from "@/assets/icons/video-icon.svg"
import mainDictionary from "@/dictionary"
import { VideoStatus } from "@/modules/kinescope"
import { useOnLine } from "@/shared/hooks"
import { useStores } from "@/stores"

import styles from "./video-name.module.scss"

const cn = classNames.bind(styles)

type Props = {
  video: IVideo
}

export const VideoName = ({ video }: Props) => {
  const { groupsStore } = useStores()
  const { isOnline } = useOnLine()
  const status = video.currentStatus || video.status
  const isStatusDone = status === VideoStatus.Done
  const tooltipTitle = isOnline ? video?.origName : mainDictionary.noInternet

  const handleClick = () => {
    groupsStore.setSelectedVideo(video)
    groupsStore.setIsKinescopePlayerOpen(true)
  }

  return (
    <Tooltip placement="topLeft" title={tooltipTitle}>
      <Flex align="center" onClick={isOnline && isStatusDone ? handleClick : undefined} gap={8}>
        <img src={VideoIcon} alt="play" />
        <span
          className={cn("video-name", {
            "video-name--success": isStatusDone,
            "video-name--not-done": !isStatusDone,
          })}
        >
          {video.origName}
        </span>
      </Flex>
    </Tooltip>
  )
}
