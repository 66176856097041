import { Endpoints, INetworkConfig, Instance, IResponse, ISuccessResponse } from "@/api"
import { PaymentSegmentedValues } from "@/pages/Settings/SettingsFinance/constants"
import { IStaffInfo } from "@/stores/auth/types"
import {
  AddRoleParams,
  ChangeStudentReq,
  ContractFormValues,
  CustomFieldAddValue,
  CustomFieldEditValue,
  EditContractReq,
  EditMenuParams,
  EditRoleParams,
  EditRolePermissionParams,
  EditStudentReq,
  GetContractHistoryReq,
  GetContractsAll,
  GetCustomFieldReq,
  GetPricesResponse,
  PricesItem,
} from "@/stores/settings"
import { MobileAppVersionReq, MobileAppVersionRes } from "@/types"
import { IRole, TPermission } from "../controls"
import { SettingsEndpoints } from "./endpoints"
import {
  AcademicHoursEdit,
  AcademicHoursItem,
  AcademicHoursRes,
  AcademicPriceEdit,
  AcademicPrices,
  AccessControl,
  Categories,
  CategoriesEdit,
  CategoriesRes,
  Contract,
  ContractHistoryRes,
  ContractRes,
  Courses,
  CoursesEdit,
  CoursesRes,
  CustomField,
  CustomFieldTargetType,
  DeleteFinancePayments,
  EditFinanceDiscount,
  EditFinancePayments,
  Expenses,
  ExpensesEdit,
  FinanceDiscounts,
  FinancePayments,
  FinancePaymentsData,
  GamificationOfferingParams,
  GamificationOfferingResponseTypes,
  LearningPayments,
  LearningPaymentsEdit,
  Location,
  LocationEdit,
  RolePermission,
  SettingsFinancePriceParam,
  SettingsFinanePriceEdit,
  StaffStatusesRes,
  StudentStatus,
  StudentStatusesRes,
} from "./types"

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class SettingsApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getAcademicHours = (): Promise<IResponse<AcademicHoursRes[]>> => this.get(SettingsEndpoints.AcademicHours)

  addAcademicHours = (params: AcademicHoursItem): Promise<IResponse<ISuccessResponse>> =>
    this.post(SettingsEndpoints.AcademicHours, params)

  editAcademicHours = (params: AcademicHoursEdit): Promise<IResponse<ISuccessResponse>> =>
    this.put(`${SettingsEndpoints.AcademicHours}/${params.id}`, params.data)

  deleteAcademicHours = (id: number): Promise<IResponse<ISuccessResponse>> =>
    this.delete(`${SettingsEndpoints.AcademicHours}/${id}`)

  getAcademicPrices = (): Promise<IResponse<AcademicPrices[]>> => this.get(SettingsEndpoints.AcademicPrices)

  editAcademicPrice = (params: AcademicPriceEdit): Promise<IResponse<AcademicPrices>> =>
    this.put(`${SettingsEndpoints.AcademicPrices}/${params.id}`, params.data)

  deteteAcademicPrice = (id: number): Promise<IResponse<AcademicPrices>> =>
    this.delete(`${SettingsEndpoints.AcademicPrices}/${id}`)

  addAcademicPrice = (params: AcademicPrices): Promise<IResponse<AcademicPrices>> =>
    this.post(SettingsEndpoints.AcademicPrices, params)

  addFinanceDiscount = (params: FinanceDiscounts): Promise<IResponse<FinanceDiscounts>> =>
    this.post(SettingsEndpoints.FinanceDiscounts, params)

  editFinanceDiscoiunt = (params: EditFinanceDiscount): Promise<IResponse<FinanceDiscounts>> =>
    this.put(`${SettingsEndpoints.FinanceDiscounts}/${params.id}`, params.data)

  deleteFinanceDiscount = (id: number): Promise<IResponse<FinanceDiscounts>> =>
    this.delete(`${SettingsEndpoints.FinanceDiscounts}/${id}`)

  getFinanceDiscounts = (): Promise<IResponse<FinanceDiscounts[]>> => this.get(SettingsEndpoints.FinanceDiscounts)

  getSettingsFinancePrices = (params: SettingsFinancePriceParam): Promise<IResponse<GetPricesResponse>> =>
    this.get(SettingsEndpoints.SettingsFinancePrices, { params })

  addSettingsFinancePrices = (params: PricesItem): Promise<IResponse<GetPricesResponse>> =>
    this.post(SettingsEndpoints.SettingsFinancePrices, params)

  editSettingsFinancePrices = (params: SettingsFinanePriceEdit): Promise<IResponse<GetPricesResponse>> =>
    this.put(`${SettingsEndpoints.SettingsFinancePrices}/${params.id}`, params.data)

  deleteFinancePrices = (id: number): Promise<IResponse<GetPricesResponse>> =>
    this.delete(`${SettingsEndpoints.SettingsFinancePrices}/${id}`)

  changeStudentStatus = (params: ChangeStudentReq): Promise<IResponse<StudentStatus>> =>
    this.put(`${SettingsEndpoints.ControlActions}${SettingsEndpoints.UpdateStudentGroupActionControl}`, params)

  getGamificationOffering = (): Promise<IResponse<GamificationOfferingResponseTypes>> =>
    this.get(SettingsEndpoints.SettingsGamificationOffering)

  getStudentStatuses = (): Promise<IResponse<StudentStatusesRes>> => this.get(SettingsEndpoints.ControlActions)

  getStudentStatus = (id: number): Promise<IResponse<StudentStatus>> =>
    this.get(`${SettingsEndpoints.ControlActions}/${id}`)

  editStudentStatus = (info: EditStudentReq): Promise<IResponse<StudentStatus>> =>
    this.put(`${SettingsEndpoints.ControlActions}/${info.id}`, info.data)

  updateGamificationOffering = (params: GamificationOfferingParams): Promise<IResponse<IStaffInfo>> =>
    this.put(SettingsEndpoints.SettingsGamificationOfferingUpdate, params)

  getContracts = (params: GetContractsAll): Promise<IResponse<ContractRes>> =>
    this.get(Endpoints.ContractsAll, { params })

  addContract = (params: ContractFormValues): Promise<IResponse<ContractRes>> =>
    this.post(Endpoints.ContractsCreate, params)

  editContract = (params: EditContractReq): Promise<IResponse<ContractRes>> =>
    this.put(`${Endpoints.Contracts}/${params.id}`, params.data)

  getContractHistory = (params: GetContractHistoryReq): Promise<IResponse<ContractHistoryRes>> =>
    this.get(`${Endpoints.Contracts}/${params.id}${Endpoints.History}`, { params: params.params })

  getContract = (params: number): Promise<IResponse<Contract>> => this.get(`${Endpoints.Contracts}/${params}`)

  getMobileAppVersions = (params: Partial<MobileAppVersionReq>): Promise<IResponse<MobileAppVersionRes>> =>
    this.get(Endpoints.MobileAppVersions, { params })

  addNewMobileAppVersion = (params: MobileAppVersionReq): Promise<IResponse<ISuccessResponse>> =>
    this.post(Endpoints.CreateMobileAppVersion, params)

  getFinancePayments = (params: PaymentSegmentedValues): Promise<IResponse<FinancePayments[]>> =>
    this.get(`${SettingsEndpoints.FinancePayments}/${params}`)

  addFinancePayments = (params: FinancePaymentsData): Promise<IResponse<ISuccessResponse>> =>
    this.post(`${SettingsEndpoints.FinancePayments}/${params.type}`, params.data)

  editFinancePayments = (params: EditFinancePayments): Promise<IResponse<ISuccessResponse>> =>
    this.put(`${SettingsEndpoints.FinancePayments}/${params.type}/${params.id}`, params.data)

  deleteFinancePayments = (params: DeleteFinancePayments): Promise<IResponse<ISuccessResponse>> =>
    this.delete(`${SettingsEndpoints.FinancePayments}/${params.type}/${params.id}`)

  getAccessControlUsers = (): Promise<IResponse<AccessControl[]>> => this.get(SettingsEndpoints.AccessControlRoles)

  addNewRole = (params: AddRoleParams): Promise<IResponse<IRole>> =>
    this.post(SettingsEndpoints.AccessControlRoles, params)

  editRole = (params: EditRoleParams): Promise<IResponse<IRole>> =>
    this.put(`${SettingsEndpoints.AccessControlRoles}/${params.id}`, params.data)

  deleteRole = (id: number): Promise<IResponse<IRole>> => this.delete(`${SettingsEndpoints.AccessControlRoles}/${id}`)

  getRolePermissions = (id: number): Promise<IResponse<RolePermission[]>> =>
    this.get(`${SettingsEndpoints.AccessControlRoles}/${id}${SettingsEndpoints.Permissions}`)

  editRolePermissions = (params: EditRolePermissionParams): Promise<IResponse<IRole>> =>
    this.patch(`${SettingsEndpoints.AccessControlRoles}/${params.id}${SettingsEndpoints.Permissions}`, params.data)

  getMenusByRoleId = (id: number): Promise<IResponse<TPermission[]>> =>
    this.get(`${SettingsEndpoints.AccessControlRoles}/${id}${SettingsEndpoints.Menus}`)

  editMenus = (params: EditMenuParams): Promise<IResponse<TPermission[]>> =>
    this.patch(`${SettingsEndpoints.AccessControlRoles}/${params.id}${SettingsEndpoints.Menus}`, params.data)

  getStaffStatuses = (): Promise<IResponse<StaffStatusesRes>> => this.get(SettingsEndpoints.StaffStatuses)

  addStaffStatus = (params: AddRoleParams): Promise<IResponse<StaffStatusesRes>> =>
    this.post(SettingsEndpoints.StaffStatuses, params)

  editStaffStatus = (params: EditRoleParams): Promise<IResponse<StaffStatusesRes>> =>
    this.put(`${SettingsEndpoints.StaffStatuses}/${params.id}`, params.data)

  deleteStaffStatus = (id: number): Promise<IResponse<StaffStatusesRes>> =>
    this.delete(`${SettingsEndpoints.StaffStatuses}/${id}`)

  getCustomFields = (params: GetCustomFieldReq): Promise<IResponse<CustomField[]>> =>
    this.get(`${SettingsEndpoints.CustomFields}`, { params })

  addStudentCustomField = (params: CustomFieldAddValue): Promise<IResponse<CustomField>> =>
    this.post(SettingsEndpoints.CustomFields, params)

  deleteCustomField = (id: number): Promise<IResponse<CustomField>> =>
    this.delete(`${SettingsEndpoints.CustomFields}/${id}`)

  editCustomField = (params: CustomFieldEditValue): Promise<IResponse<CustomField>> =>
    this.put(`${SettingsEndpoints.CustomFields}/${params.id}`, params.data)

  getCourses = (): Promise<IResponse<CoursesRes>> => this.get(`${SettingsEndpoints.Courses}`)

  addCourses = (params: Courses): Promise<IResponse<ISuccessResponse>> => this.post(SettingsEndpoints.Courses, params)

  editCourses = (params: CoursesEdit): Promise<IResponse<ISuccessResponse>> =>
    this.put(`${SettingsEndpoints.Courses}/${params.id}`, params.data)

  deleteCourses = (id: number): Promise<IResponse<ISuccessResponse>> =>
    this.delete(`${SettingsEndpoints.Courses}/${id}`)

  getCategories = (): Promise<IResponse<CategoriesRes>> => this.get(SettingsEndpoints.Categories)

  addCategories = (params: Categories): Promise<IResponse<ISuccessResponse>> =>
    this.post(SettingsEndpoints.Categories, params)

  editCategories = (params: CategoriesEdit): Promise<IResponse<ISuccessResponse>> =>
    this.put(`${SettingsEndpoints.Categories}/${params.id}`, params.data)

  deleteCategories = (id: number): Promise<IResponse<ISuccessResponse>> =>
    this.delete(`${SettingsEndpoints.Categories}/${id}`)

  getLearningPayments = (): Promise<IResponse<LearningPayments[]>> => this.get(SettingsEndpoints.LearningPayments)

  addLearningPayments = (params: LearningPayments): Promise<IResponse<ISuccessResponse>> =>
    this.post(SettingsEndpoints.LearningPayments, params)

  editLearningPayments = (params: LearningPaymentsEdit): Promise<IResponse<ISuccessResponse>> =>
    this.put(`${SettingsEndpoints.LearningPayments}/${params.id}`, params.data)

  deleteLearningPayments = (id: number): Promise<IResponse<ISuccessResponse>> =>
    this.delete(`${SettingsEndpoints.LearningPayments}/${id}`)

  getLocation = (): Promise<IResponse<Location[]>> => this.get(SettingsEndpoints.Location)

  addLocation = (params: Location): Promise<IResponse<ISuccessResponse>> =>
    this.post(SettingsEndpoints.Location, params)

  editLocation = (params: LocationEdit): Promise<IResponse<ISuccessResponse>> =>
    this.put(`${SettingsEndpoints.Location}/${params.id}`, params.data)

  deleteLocation = (id: number): Promise<IResponse<ISuccessResponse>> =>
    this.delete(`${SettingsEndpoints.Location}/${id}`)

  getExpenses = (): Promise<IResponse<Expenses[]>> => this.get(SettingsEndpoints.Expenses)

  addExpenses = (params: Expenses): Promise<IResponse<ISuccessResponse>> =>
    this.post(SettingsEndpoints.Expenses, params)

  editExpenses = (params: ExpensesEdit): Promise<IResponse<ISuccessResponse>> =>
    this.put(`${SettingsEndpoints.Expenses}/${params.id}`, params.data)

  deleteExpenses = (id: number): Promise<IResponse<ISuccessResponse>> =>
    this.delete(`${SettingsEndpoints.Expenses}/${id}`)
}

export const settingsApi = new SettingsApi(config)
