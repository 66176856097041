import { Flex, Typography } from "antd"
import classNames from "classnames/bind"
import { IGroup } from "@/api/groups"
import mainDictionary from "@/dictionary"
import { ColumnSettings } from "@/stores/groups"
import { GroupColumns } from "../types"

import styles from "./table.module.scss"
import { TableActions } from "./TableActions"
import { Link } from "react-router-dom"
import { ROUTES } from "@/constants"
import { groupStatus } from ".."
import { fullTimeToFormattedDate } from "@/utils"

const cn = classNames.bind(styles)

const DEFAULT_ACTION_VALUE = 0

type ColumnsTypes = {
  availableColumns: ColumnSettings[]
}

export const formattedColumns = ({ availableColumns }: ColumnsTypes): any => {
  const columnsValues = availableColumns.filter((item) => item.isChecked)?.map((item) => item.value)

  const columns = [
    {
      title: mainDictionary.groups,
      value: GroupColumns.Groups,
      render: (item: IGroup) => (
        <Link
          title={item.name}
          to={ROUTES.singleGroup.replace(":id", item.id?.toString())}
          className={cn("group-table__name")}
        >
          {item?.name}
        </Link>
      ),
      fixed: "left",
      width: 300,
    },
    {
      title: mainDictionary.branch,
      value: GroupColumns.Branch,
      width: 150,
      render: (item: IGroup) => <Typography.Text>{item?.officeName || "-"}</Typography.Text>,
    },
    {
      title: mainDictionary.course,
      value: GroupColumns.Course,
      width: 150,
      render: (item: IGroup) => <Typography.Text>{item?.courseName || "-"}</Typography.Text>,
    },
    {
      title: mainDictionary.category,
      value: GroupColumns.Category,
      align: "center",
      width: 130,
      render: (item: IGroup) => <Typography.Text>{item?.categoryName || "-"}</Typography.Text>,
    },
    {
      title: mainDictionary.type,
      value: GroupColumns.Type,
      align: "center",
      width: 150,
      render: (item: IGroup) => <Typography.Text>{item.learningTypeName || "-"}</Typography.Text>,
    },
    {
      title: mainDictionary.groupSchedule,
      value: GroupColumns.Schedule,
      width: 200,
      render: (item: IGroup) => {
        const schedule = item.scheduleItems

        return (
          <Flex vertical gap={5}>
            {schedule.map((item) => (
              <Flex key={item.id} className={cn("group-table__schedule")} vertical>
                <span>{`${item.beginTime.slice(0, 5)}-${item.endTime.slice(0, 5)}`}</span>
                <span>{convertWeekDays(item.weekdays)}</span>
                <span>{`${fullTimeToFormattedDate(item.startDate)}-${fullTimeToFormattedDate(item.endDate)}`}</span>
              </Flex>
            ))}
          </Flex>
        )
      },
    },
    {
      title: mainDictionary.teachers,
      value: GroupColumns.Teachers,
      width: 150,
      align: "center",
      render: (item: IGroup) => (
        <Flex vertical gap={4}>
          {item?.academics?.length
            ? item.academics?.map((item) => <span key={item.id}>{`${item.firstName} ${item.lastName}`}</span>)
            : "-"}
        </Flex>
      ),
    },
    {
      title: mainDictionary.room,
      value: GroupColumns.Room,
      align: "center",
      width: 150,
      render: (item: IGroup) => (
        <Flex vertical gap={4}>
          {item?.classrooms?.length ? item.classrooms?.map((item) => <span key={item.id}>{item.name}</span>) : "-"}
        </Flex>
      ),
    },
    // {
    //   title: mainDictionary.debtorAndPayment,
    //   value: GroupColumns.Debtor,
    //   render: () => (
    //     <Flex>
    //       <Tag color="red">4</Tag>
    //       <Tag color="green">16</Tag>
    //     </Flex>
    //   ),
    // },
    {
      value: GroupColumns.ResponsiblePerson,
      align: "center",
      width: 150,
      render: (item: IGroup) => <span>{item.assigneeName}</span>,
      title: mainDictionary.responsiblePerson,
    },
    {
      value: GroupColumns.GroupStatus,
      width: 100,
      align: "center",
      render: (item: IGroup) => {
        const status = groupStatus.find((status) => status.value === item.status)
        return <Typography.Text>{status?.label || "-"}</Typography.Text>
      },
      title: mainDictionary.groupStatus,
    },
    {
      value: GroupColumns.GroupCapacity,
      width: 150,
      align: "center",
      title: mainDictionary.groupCapacity,
      render: (item: IGroup) => <span>{item.studentsCount}</span>,
    },
    {
      render: (item: IGroup) => <TableActions {...item} />,
      value: DEFAULT_ACTION_VALUE,
      width: 120,
      fixed: "right",
    },
  ]

  return columns.filter((item) => columnsValues.includes(item.value) || item.value === DEFAULT_ACTION_VALUE) || []
}

export function convertWeekDays(weekdays: number) {
  const weekDaysWithNumber = [64, 32, 16, 8, 4, 2, 1]
  const weekDaysWithString = ["Ya", "Sha", "Ju", "Pa", "Ch", "Se", "Du"]
  const result = []

  let index = 0
  while (weekdays > 0) {
    if (weekdays >= weekDaysWithNumber[index]) {
      weekdays -= weekDaysWithNumber[index]
      result.unshift(weekDaysWithString[index])
    }
    index += 1
  }

  return result.join("-")
}
