import { ROUTES } from "@/constants"
import { AvailableComponent } from "@/modules/AvailableComponent"
import { permissionObjectTypes } from "@/modules/permission"
import { GroupSingle } from "./GroupSingle"
import { ExamMake, ExamSingle, GroupAttendance, Groups, HomeworkSingle } from "./lazy"
import { StudentDuplications } from "./StudentDuplications"

export const groupRouter = [
  {
    path: ROUTES.groups,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.schooling}>
        <Groups />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.studentDuplications,
    element: (
      // TODO
      <AvailableComponent permissionName={permissionObjectTypes.allGroups}>
        <StudentDuplications />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.singleGroup,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroups}>
        <GroupSingle />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.groupAttendance,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroups}>
        <GroupAttendance />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.examCreate,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroups}>
        <ExamMake />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.examEdit,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroups}>
        <ExamMake />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.homeworkSingle,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroups}>
        <HomeworkSingle />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.examSingle,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.allGroups}>
        <ExamSingle />
      </AvailableComponent>
    ),
  },
]
