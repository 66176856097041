import { useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { MinusOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, DatePicker, Flex, Form, Modal, Select } from "antd"
import classNames from "classnames/bind"
import dayjs from "dayjs"
import { useBoolean } from "usehooks-ts"
import mainDictionary from "@/dictionary"
import { addCatchNotification, addSuccessNotification } from "@/modules/notifications"
import { useStores } from "@/stores"
import { AcademicPrices } from "@/stores/groups"
import { formatDate } from "@/utils"

import styles from "./academic-hour.module.scss"

const cn = classNames.bind(styles)

type FormValues = {
  hours: AcademicPrices[]
}

export const AcademicHour = observer(() => {
  const { groupsStore, settingsStore } = useStores()
  const [form] = Form.useForm()
  const { id } = useParams()
  const { value: isLoading, setTrue: setLoading, setFalse: stopLoading } = useBoolean()

  const handleCancel = () => {
    groupsStore.setAcademicHoursModal(false)
    groupsStore.setSelectedGroupAcademic(null)
  }

  const handleFinish = (values: FormValues) => {
    const formattedValues = values.hours?.map((item) => ({
      id: item.id,
      from: item?.from ? formatDate(item.from, true) : null,
    }))

    if (!groupsStore.selectedGroupAcademic?.id || !id) return

    const parsedId = parseInt(id, 10)

    setLoading()

    const info = {
      academicId: groupsStore.selectedGroupAcademic?.id,
      academicPrices: formattedValues,
      id: parsedId,
    }

    groupsStore
      .changeAcademicHour(info)
      .then((res) => {
        if (res.success) {
          addSuccessNotification(mainDictionary.successFullySaved)
          groupsStore.getGroupStatistics(parsedId)
          handleCancel()
        }
      })
      .catch(addCatchNotification)
      .finally(() => stopLoading())
  }

  const title = useMemo(
    () =>
      `${groupsStore.groupInfo?.name} uchun ${mainDictionary.teacher?.toLowerCase()} ${groupsStore.selectedGroupAcademic?.firstName} ${groupsStore.selectedGroupAcademic?.lastName} ${mainDictionary.edit.toLowerCase()}`,
    [],
  )

  const hourOptions = useMemo(() => {
    const hours = settingsStore.academicPrices?.map((item) => ({
      label: `${item?.academicPriceValues?.[0]?.value} / ${item?.academicHourNotation}`,
      value: item.id,
    }))

    return hours
  }, [settingsStore.academicPrices])

  useEffect(() => {
    settingsStore.getAcademicPrices()
  }, [])

  useEffect(() => {
    if (!id || !groupsStore.selectedGroupAcademic?.id) return

    groupsStore.getGroupAcademicHours({
      id: parseInt(id, 10),
      academicId: groupsStore.selectedGroupAcademic?.id,
    })
  }, [])

  useEffect(() => {
    if (groupsStore.selectedAcademicGroupPrices) {
      form.setFieldsValue({
        hours: groupsStore.selectedAcademicGroupPrices?.map((item) => ({
          id: item.id,
          from: dayjs(item.from),
        })),
      })
    }
  }, [groupsStore.selectedAcademicGroupPrices])

  return (
    <Modal
      okText={mainDictionary.save}
      cancelText={mainDictionary.cancel}
      title={title}
      onCancel={handleCancel}
      open={groupsStore.academicHoursModal}
      className={cn("academic-hour")}
      onOk={form.submit}
      okButtonProps={{ loading: isLoading }}
    >
      <Form onFinish={handleFinish} form={form} layout="vertical" initialValues={{ hours: [{}] }}>
        <Form.List name="hours">
          {(fields, { add, remove }) => (
            <div>
              {fields.map((field, index) => (
                <Flex vertical gap={12} key={field.key}>
                  <Flex
                    className={cn("academic-hour__wrapper", {
                      "academic-hour__wrapper--addition": index !== fields.length - 1,
                    })}
                    justify={index ? "flex-end" : "flex-start"}
                    align="center"
                    gap={10}
                  >
                    {!!index && (
                      <Form.Item name={[field.name, "from"]} label={mainDictionary.date}>
                        <DatePicker />
                      </Form.Item>
                    )}
                    <Form.Item
                      rules={[{ required: true, message: mainDictionary.shouldFill }]}
                      label={mainDictionary.optionsForAcademics}
                      name={[field.name, "id"]}
                    >
                      <Select options={hourOptions} placeholder={mainDictionary.choose} />
                    </Form.Item>

                    <Flex gap={8}>
                      {!!index && (
                        <Button
                          size="small"
                          icon={<MinusOutlined />}
                          type="primary"
                          danger
                          className={cn("academic-hour__button")}
                          shape="circle"
                          onClick={remove.bind(null, field.name)}
                        />
                      )}

                      {index === fields?.length - 1 && (
                        <Button size="small" icon={<PlusOutlined />} type="primary" shape="circle" onClick={add} />
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              ))}
            </div>
          )}
        </Form.List>
      </Form>
    </Modal>
  )
})
