import { makeAutoObservable } from "mobx"
import { scheduleApi } from "@/api/schedule/schedule.ts"
import { addCatchNotification } from "@/modules/notifications"
import { EmptyRooms, EmptyRoomsRequestParams } from "@/types"

class ScheduleStore {
  emptyRooms: EmptyRooms[] | null = null
  officeId: number | null = null
  filterOptions: EmptyRoomsRequestParams = {
    officeId: this.officeId,
  }

  constructor() {
    makeAutoObservable(this)
  }

  setEmptyRooms = (data: EmptyRooms[]) => {
    this.emptyRooms = data
  }

  setOfficeId = (officeId: number) => {
    this.officeId = officeId
  }

  setFilterOption = (options: EmptyRoomsRequestParams) => {
    this.filterOptions = options
  }

  getEmptyRooms = (params: EmptyRoomsRequestParams) =>
    scheduleApi
      .getEmptyRooms(params)
      .then((response) => {
        if (response.success && response.data) {
          this.setEmptyRooms(response.data)
        }
      })
      .catch(addCatchNotification)

  reset = () => {
    this.emptyRooms = null
    this.officeId = null
    this.filterOptions = {
      officeId: this.officeId,
    }
  }
}

export const scheduleStore = new ScheduleStore()
