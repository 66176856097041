import "./helper.css"

import { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { Button, Flex, Form, Segmented, Select, Skeleton, Typography } from "antd"
import classNames from "classnames/bind"
import mainDictionary from "@/dictionary"
import { studentTypes } from "@/pages/Groups/constants"
import { useStores } from "@/stores"
import { formatDateWithDots, makeFileUrl } from "@/utils"
import { MakeStudent } from "./MakeStudent"
import { Student } from "./Student"

import styles from "./students.module.scss"

const cn = classNames.bind(styles)

export const Students = observer(() => {
  const [selectedTab, setSelectedTab] = useState(1)
  const { groupsStore } = useStores()
  const { id } = useParams()
  const [form] = Form.useForm()
  const [activeScheduleItem, setActiveScheduleItem] = useState<number | null>(null)

  const scheduleItems = useMemo(
    () =>
      groupsStore?.groupSchedule?.scheduleItems?.map((item) => ({
        label: `${item.beginTime.slice(0, 5)} - ${item.endTime.slice(0, 5)}`,
        value: item.id,
      })),
    [groupsStore?.groupSchedule?.scheduleItems],
  )

  const handleScheduleChange = (value: number) => {
    setActiveScheduleItem(value)
  }

  const handleAddStudent = () => {
    groupsStore.setMakeStudentModal(true)
  }

  useEffect(() => {
    if (id) {
      const parsedId = parseInt(id, 10)

      groupsStore.setGroupScheduleLoading(true)
      groupsStore.getGroupSchedule({
        page: 1,
        perPage: 100,
        groupId: parsedId,
      })
    }
  }, [])

  useEffect(() => {
    if (scheduleItems?.length) {
      form.setFieldsValue({
        scheduleItemId: scheduleItems[0].value,
      })

      setActiveScheduleItem(scheduleItems[0].value)
    }
  }, [scheduleItems?.length])

  useEffect(() => {
    if (activeScheduleItem && id) {
      groupsStore.setGroupStudentsLoading(true)

      groupsStore.getGroupStudents({
        page: 1,
        perPage: 100,
        id: parseInt(id, 10),
        scheduleItemId: activeScheduleItem,
        statusId: selectedTab,
      })
    }
  }, [activeScheduleItem, selectedTab])

  useEffect(() => {
    if (!activeScheduleItem) return

    if (groupsStore.groupStudents?.students) {
      groupsStore.groupStudents.students.forEach((student) => {
        groupsStore
          .getStudentSchema({
            id: activeScheduleItem,
            page: groupsStore.studentSchemaFilterOption?.[student.id]?.page || 1,
            perPage: groupsStore.studentSchemaFilterOption?.[student.id]?.perPage || 20,
            studentId: student.id,
          })
          .then((res) => {
            if (res.success) {
              groupsStore.setStudentSchema({
                ...groupsStore.studentSchema,
                [student.id]: res.data,
              })
            }
          })
      })
    }
  }, [groupsStore.groupStudents])

  return (
    <Flex gap={24} vertical className={cn("students")}>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap={32}>
          <Typography.Title level={5}>
            {mainDictionary.students}({groupsStore.groupStudents?.total || 0})
          </Typography.Title>

          <Segmented options={studentTypes} value={selectedTab} onChange={setSelectedTab} />
        </Flex>

        <Flex gap={10}>
          <Form form={form}>
            <Form.Item name="scheduleItemId">
              <Select onChange={handleScheduleChange} options={scheduleItems} className={cn("students__select")} />
            </Form.Item>
          </Form>
          <Button onClick={handleAddStudent} type="primary">
            {mainDictionary.addStudent}
          </Button>
        </Flex>
      </Flex>

      {groupsStore.groupStudentsLoading ? (
        <Skeleton active />
      ) : (
        <Flex gap={16} vertical>
          {groupsStore?.groupStudents?.students?.map((student) => (
            <Student
              fromDate={formatDateWithDots(student.startedDate)}
              endDate={formatDateWithDots(student.endedDate)}
              name={`${student.firstName} ${student.lastName}`}
              key={student.id}
              totalDebtInHour={student.totalDebtInHour}
              totalPaidInCurrency={student.totalPaidInCurrency}
              totalDebtInCurrency={student.totalDebtInCurrency}
              currentDebtInHour={student.currentDebtInHour}
              totalPaidInHour={student.totalPaidInHour}
              img={makeFileUrl(student.photo)}
              studentId={student.id}
            />
          ))}
        </Flex>
      )}

      {groupsStore.makeStudentModal && <MakeStudent />}
    </Flex>
  )
})
