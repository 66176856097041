import { useEffect } from "react"
import { observer } from "mobx-react"
import { PauseOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Flex, Typography } from "antd"
import classNames from "classnames/bind"
import mainDictionary from "@/dictionary"
import { addCatchNotification } from "@/modules/notifications"
import { getPerPages } from "@/shared"
import { useStores } from "@/stores"
import { ColumnsSettings } from "./ColumnSettings"
import { COLUMN_SETTINGS, groupColumns } from "./constants"
import { Filter } from "./Filter"
import { GroupTable } from "./Table"

import styles from "./groups.module.scss"
import { GroupMake } from "./GroupMake"

const cn = classNames.bind(styles)

export const Groups = observer(() => {
  const { groupsStore } = useStores()
  const perPageFromLocalStorage = getPerPages()

  const handleColumnsSettingsClick = () => {
    groupsStore.setColumnsSettingsModal(true)
  }

  const handleOpenGroupMakeModal = () => {
    groupsStore.setGroupMakeModal(true)
  }

  useEffect(() => {
    try {
      const columnsSettings = JSON.parse(window.localStorage.getItem(COLUMN_SETTINGS) || JSON.stringify(groupColumns))

      groupsStore.setColumnsSettingsValues(columnsSettings)
    } catch (err: Error | unknown) {
      addCatchNotification(err)
    }

    const info = {
      perPage: perPageFromLocalStorage?.groupsPerPage || 10,
      ...groupsStore.filterOptions,
    }

    groupsStore.setFilterOption(info)
  }, [])

  return (
    <Flex vertical gap={16}>
      <Flex justify="space-between">
        <Typography.Title level={3}>{mainDictionary.groups}</Typography.Title>

        <Button onClick={handleOpenGroupMakeModal} type="primary" icon={<PlusOutlined />}>
          {mainDictionary.add}
        </Button>
      </Flex>
      <Flex className={cn("groups")} vertical>
        <Filter />

        <Flex className={cn("groups__inner")} vertical gap={16}>
          <Flex justify="flex-end">
            <Button onClick={handleColumnsSettingsClick} icon={<PauseOutlined />}>
              {mainDictionary.columnSettings}
            </Button>
          </Flex>
          <GroupTable />
        </Flex>

        {groupsStore.columnsSettingsModal && <ColumnsSettings />}
        {groupsStore.groupMakeModal && <GroupMake />}
      </Flex>
    </Flex>
  )
})
