import { makeAutoObservable } from "mobx"

class AnalyticsStore {
  isStatisticsFilter = false

  setIsStaisticsFilter = (params: boolean) => {
    this.isStatisticsFilter = params
  }

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {}
}

export const analyticsStore = new AnalyticsStore()
