import { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { Form, FormProps, Input, Modal, Select } from "antd"
import classnames from "classnames/bind"
import mainDictionary from "@/dictionary"
import { addCatchNotification, addNotification, NotificationMessageType } from "@/modules/notifications"
import { useStores } from "@/stores"
import { GroupByOrder, VIDEO_KEYS, VideoFormType } from "@/stores/groups"
import { formatDate } from "@/utils"
import { useCheckMyGroup } from "@/utils/hooks"

import styles from "./add-video.module.scss"

const cn = classnames.bind(styles)

export const EditVideoParams = observer(() => {
  const { groupsStore } = useStores()
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const { id } = useParams()
  const academic = useCheckMyGroup()
  const lessons = useMemo(
    () =>
      groupsStore.lessons?.lessons.map((item) => ({
        label: (
          <div className={cn("lesson")}>
            <span className={cn("lesson__title")}>{item.name}</span>
            <span> ({formatDate(item.createdAt)})</span>
          </div>
        ),
        value: item.id,
      })),
    [groupsStore.lessons],
  )

  const handleClose = () => {
    groupsStore.setSelectedVideo(null)
    groupsStore.setAddVideoModal(false)
  }

  const updateVideo = async (values: VideoFormType) => {
    if (!groupsStore.selectedVideo) {
      return
    }

    try {
      const info = {
        id: groupsStore.selectedVideo.id,
        data: {
          origName: values.origName,
          lessonId: values.lessonId,
        },
      }

      await groupsStore.updateVideo(info)

      addNotification({
        messageType: NotificationMessageType.Success,
        message: mainDictionary.updateNotificationMessage,
      })

      setLoading(false)

      const videoOption = {
        value: groupsStore.key,
        keys: VIDEO_KEYS,
        groupId: Number(id),
      }

      await groupsStore.getAllVideos(videoOption)

      handleClose()
    } catch (error: unknown | Error) {
      addCatchNotification(error)
      setLoading(false)
    }
  }

  const handleFormFinish: FormProps<VideoFormType>["onFinish"] = (values) => {
    setLoading(true)

    if (groupsStore.selectedVideo?.id) {
      updateVideo(values)
    }
  }

  const handleOkClick = () => {
    form.submit()
  }

  useEffect(() => {
    if (id) {
      groupsStore.getLessons({
        id,
        orderBy: GroupByOrder.Desc,
      })
    }

    if (groupsStore.selectedVideo?.id) {
      form.setFieldsValue({
        lessonId: groupsStore.selectedVideo.lesson.id,
        origName: groupsStore.selectedVideo.origName,
      })
    }
  }, [])

  return (
    <Modal
      open={groupsStore.addVideo}
      title={mainDictionary.add}
      onOk={handleOkClick}
      okButtonProps={{ loading }}
      onCancel={handleClose}
    >
      <Form layout="vertical" onFinish={handleFormFinish} form={form}>
        <Form.Item
          label={mainDictionary.lesson}
          name="lessonId"
          rules={[{ required: true, message: mainDictionary.requiredLesson }]}
        >
          <Select options={lessons} placeholder={mainDictionary.selectLesson} />
        </Form.Item>

        <Form.Item
          label={mainDictionary.videoName}
          rules={[{ required: true, message: mainDictionary.requiredLessonName }]}
          name="origName"
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
})
