import { observer } from "mobx-react"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { Avatar, Button, Flex, Typography } from "antd"
import classNames from "classnames/bind"
import MoreIcon from "@/assets/icons/more-icon.svg"
import DefaultUserImg from "@/assets/img/img_avatar.png"
import { CalendarDay } from "@/components/CalendarDay"
import mainDictionary from "@/dictionary"
import { useStores } from "@/stores"
import { makeFileUrl } from "@/utils"

import styles from "./student.module.scss"

const cn = classNames.bind(styles)

type Props = {
  img?: string
  name: string
  fromDate: string
  endDate: string
  totalDebtInHour: number
  totalPaidInCurrency: number
  totalDebtInCurrency: number
  totalPaidInHour: number
  currentDebtInHour: number
  studentId: number
}

export const Student = observer((student: Props) => {
  const { groupsStore } = useStores()

  return (
    <Flex className={cn("student")} align="flex-start">
      <Flex className={cn("student__wrapper")} vertical gap={15}>
        <Flex justify="space-between" className={cn("student__left")} align="center">
          <Flex gap={12} align="center">
            <Avatar
              src={makeFileUrl(student.img || "")}
              size={60}
              icon={<img src={DefaultUserImg} alt="avatar" width={60} height={60} />}
            />

            <Flex vertical gap={4}>
              <Typography.Title className={cn("student__name")} level={5}>
                {student?.name}
              </Typography.Title>

              {!!student.totalDebtInHour && (
                <Typography.Text type="danger">
                  {mainDictionary.debt} {student.totalDebtInHour} soat
                </Typography.Text>
              )}
            </Flex>
          </Flex>

          <Flex gap={12}>
            {(student.fromDate || student.endDate) && (
              <Typography.Text type="secondary">
                {student.fromDate} {mainDictionary.from} <br /> {student.endDate} {mainDictionary.to}
              </Typography.Text>
            )}
            <Button icon={<img src={MoreIcon} />} type="text" shape="circle" />
          </Flex>
        </Flex>

        {/* <Flex gap={12}>
          <Tag className={cn("student__tag")}>{mainDictionary.didNotSignContract}</Tag>
          <Tag className={cn("student__tag")}>{mainDictionary.documents}</Tag>
        </Flex> */}

        <Flex vertical gap={10}>
          <Typography.Text>
            {mainDictionary.payed} {student.totalPaidInHour}s / {student.totalPaidInCurrency}so‘m
          </Typography.Text>
          <Typography.Text>
            {mainDictionary.didNotPay} {student.currentDebtInHour}s / {student.totalDebtInCurrency}so‘m
          </Typography.Text>
        </Flex>
      </Flex>

      <Flex className={cn("student__right")} vertical gap={16}>
        {(groupsStore?.studentSchema?.[student?.studentId]?.total || 0) > 20 && (
          <Flex align="center" gap={20}>
            <Button icon={<LeftOutlined />} shape="circle" />

            <Button icon={<RightOutlined />} shape="circle" />
          </Flex>
        )}

        <Flex wrap align="center" gap={10}>
          {groupsStore.studentSchema?.[student.studentId]?.schema?.map((item) => (
            <CalendarDay date={item.lessonDate} key={item.lessonId} category={item.dayType} />
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
})
