import { ROUTES } from "@/constants"
import { AvailableComponent } from "@/modules/AvailableComponent"
import { permissionObjectTypes } from "@/modules/permission"
import {
  Audit,
  BranchOfficePage,
  BranchOfficeSingle,
  FaceIdDevices,
  FaceIdLogs,
  FaceIdScreens,
  Roles,
  Users,
  UserSingle,
} from "./lazy"

export const controlsRouters = [
  {
    path: ROUTES.controlRoles,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.staffControlRoles}>
        <Roles />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.audits,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.audits}>
        <Audit />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.controlUsers,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.staffControlUsers}>
        <Users />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.controlUsersAdd,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.staffControlUsers}>
        <UserSingle isAdd />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.faceId,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.staffControlFaceIdLogs}>
        <FaceIdLogs />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.faceIdScreens,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.staffControl}>
        <FaceIdScreens />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.controlUserEdit,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.staffControl}>
        <UserSingle isEdit />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.controlBranchOffice,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.staffControlBranchOffice}>
        <BranchOfficePage />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.faceIdDevices,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.staffControlBranchOffice}>
        <FaceIdDevices />
      </AvailableComponent>
    ),
  },
  {
    path: ROUTES.controlBranchOfficeSingle,
    element: (
      <AvailableComponent permissionName={permissionObjectTypes.staffControlBranchOffice}>
        <BranchOfficeSingle />
      </AvailableComponent>
    ),
  },
]
