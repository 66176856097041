import { useMemo } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import { observer } from "mobx-react"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { Button, Flex, Typography } from "antd"
import classNames from "classnames/bind"
import { useStores } from "@/stores"
import { CalendarDay } from "./CalendarDay"

import styles from "./calendar.module.scss"

const cn = classNames.bind(styles)

export const Calendar = observer(() => {
  const location = useLocation()
  const { groupsStore } = useStores()
  const [searchParams, setSearchParams] = useSearchParams()

  const currentDate = useMemo(() => {
    if (!location.search) return

    const params = new URLSearchParams(location.search)
    const currentDate = params.get("date")

    return currentDate
  }, [location.search])

  const handleCalendarClick = (date?: string) => {
    if (!date || !location.search) return

    searchParams.set("date", date)

    setSearchParams(searchParams)
  }

  const handlePrevClick = () => {
    const currentPage = groupsStore.attendanceFilterOptions?.page || 2
    const newPage = currentPage - 1

    groupsStore.setAttendanceFilterOptions({
      ...groupsStore.attendanceFilterOptions!,
      page: newPage,
    })

    const newSearchParams = new URLSearchParams(searchParams)

    newSearchParams.set("page", newPage.toString())

    setSearchParams(newSearchParams)
  }

  const handleNextClick = () => {
    const currentPage = groupsStore.attendanceFilterOptions?.page || 1
    const newPage = currentPage + 1

    groupsStore.setAttendanceFilterOptions({
      ...groupsStore.attendanceFilterOptions!,
      page: newPage,
    })

    const newSearchParams = new URLSearchParams(searchParams)

    newSearchParams.set("page", newPage.toString())

    setSearchParams(newSearchParams)
  }

  return (
    <Flex vertical className={cn("calendar")} gap={12}>
      <Flex gap={20} align="center">
        <Typography.Title level={5}>
          {groupsStore?.attendanceFilterOptions?.page || 1} /{" "}
          {Math.ceil(
            (groupsStore.attendanceSchema?.total || 1) / (groupsStore?.attendanceFilterOptions?.perPage || 15),
          )}
        </Typography.Title>

        <Flex gap={8}>
          <Button
            disabled={groupsStore.attendanceFilterOptions?.page === 1 || groupsStore.attendanceLoading}
            icon={<LeftOutlined />}
            type="text"
            size="small"
            onClick={handlePrevClick}
          />

          <Button
            disabled={
              Boolean(
                (groupsStore.attendanceFilterOptions?.page || 1) >=
                  (groupsStore.attendanceSchema?.total || 1) / (groupsStore?.attendanceFilterOptions?.perPage || 15),
              ) || groupsStore.attendanceLoading
            }
            icon={<RightOutlined />}
            type="text"
            size="small"
            onClick={handleNextClick}
          />
        </Flex>
      </Flex>

      <Flex wrap gap={10}>
        {groupsStore.attendanceSchema?.schema?.map((item) => (
          <CalendarDay
            isToday={item.lessonDate === currentDate}
            category={item.dayType}
            date={item.lessonDate}
            key={item.lessonId}
            handleClick={handleCalendarClick}
          />
        ))}
      </Flex>
    </Flex>
  )
})
