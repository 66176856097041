import { Modal } from "antd"
import { PLAYER_WIDTH } from "./constants"
import { Player, PlayerProps } from "./Player"

const PADDING = 2 * 24

type Props = PlayerProps & {
  isOpen: boolean
  onCancel?: () => void
  title?: string
}

export const PlayerModal = ({ isOpen, onCancel, title, width = PLAYER_WIDTH, ...res }: Props) => {
  const styles = { width: width + PADDING, minWidth: width + PADDING }

  return (
    <Modal open={isOpen} onCancel={onCancel} footer={null} centered title={title} style={styles}>
      <Player {...res} width={width} />
    </Modal>
  )
}
