import { observer } from "mobx-react"
import { Flex, Typography } from "antd"
import classNames from "classnames/bind"
import { DayType } from "@/api/groups"
import { getDayOfMonth, getDayOfWeek } from "@/utils"

import styles from "./calendar-day.module.scss"

const cn = classNames.bind(styles)

type Props = {
  category?: DayType
  date?: string
  isToday?: boolean
  handleClick?: (date?: string) => void
}

export const CalendarDay = observer(({ category, date, isToday, handleClick }: Props) => (
  <Flex
    onClick={handleClick?.bind(null, date)}
    className={cn("calendar-day", category, { today: isToday })}
    vertical
    gap={4}
  >
    <Typography.Text className={cn("calendar-day__week")}>{getDayOfWeek(date) || "Se"}</Typography.Text>
    <Typography.Text className={cn("calendar-day__day")}>{getDayOfMonth(date) || "15"}</Typography.Text>
  </Flex>
))
