import { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { Button, Flex, Form, Typography } from "antd"
import classNames from "classnames/bind"
import mainDictionary from "@/dictionary"
import { addCatchNotification } from "@/modules/notifications"
import { LessonType } from "@/pages/Groups/constants"
import { useStores } from "@/stores"
import { formatAttendanceDate } from "@/utils"
import { Calendar } from "./Calendar"
import { AttendanceTable } from "./Table"
import { Teacher } from "./Teacher"

import styles from "./attendance.module.scss"
const cn = classNames.bind(styles)

export const Attendance = observer(() => {
  const { groupsStore } = useStores()
  const { scheduleId, academicId, id } = useParams()
  const location = useLocation()
  const [selectedDate, setSelectedDate] = useState("")
  const [form] = Form.useForm()

  useEffect(() => {
    if (!location.search) return

    const params = new URLSearchParams(location.search)

    const currentPage = params.get("page") || "1"
    const currentPerPage = params.get("perPage") || "10"

    if (!scheduleId || !academicId) return

    groupsStore.setAttendanceLoading(true)

    const parsedScheduleId = parseInt(scheduleId, 10)
    const parsedAcademicId = parseInt(academicId, 10)
    const parsedPage = groupsStore.attendanceFilterOptions?.page || parseInt(currentPage, 10)
    const parsedPerPage = groupsStore.attendanceFilterOptions?.perPage || parseInt(currentPerPage, 10)

    const filterOption = {
      id: parsedScheduleId,
      page: parsedPage,
      perPage: parsedPerPage,
      academicId: parsedAcademicId,
    }

    if (!groupsStore.attendanceFilterOptions?.id) {
      groupsStore.setAttendanceFilterOptions(filterOption)
    }

    groupsStore
      .getAcademicScheduleSchema(filterOption)
      .then((res) => {
        if (res.success) {
          groupsStore.setAttendanceSchema(res.data)
        }
      })
      .catch(addCatchNotification)
      .finally(() => groupsStore.setAttendanceLoading(false))
  }, [groupsStore.attendanceFilterOptions])

  useEffect(() => {
    if (!location.search) return

    const params = new URLSearchParams(location.search)

    const currentDate = params.get("date")

    if (currentDate) setSelectedDate(currentDate)
  }, [location.search])

  useEffect(() => {
    if (!id) return

    form.setFieldsValue({ lessonType: LessonType.Other })

    const parsedGroupId = parseInt(id, 10)

    groupsStore.getGroupInfo(parsedGroupId)

    return () => {
      groupsStore.setAttendanceFilterOptions(null)
      groupsStore.setAttendanceSchema(null)
    }
  }, [])

  return (
    <Flex vertical className={cn("attendance")}>
      <Calendar />

      <Form form={form} layout="vertical">
        <Flex className={cn("attendance__top")} justify="space-between" align="center">
          <Typography.Title level={5} className={cn("attendance__title")}>
            {groupsStore.groupInfo?.name || ""} {formatAttendanceDate(selectedDate)}
          </Typography.Title>

          <Flex gap={20} align="center">
            <Button>{mainDictionary.cancel}</Button>

            <Button type="primary">{mainDictionary.save}</Button>
          </Flex>
        </Flex>

        <Teacher />

        <AttendanceTable />
      </Form>
    </Flex>
  )
})
