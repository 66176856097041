import { lazy } from "react"
import { handleCatchChunkError } from "@/utils"

export const Groups = lazy(() =>
  import("./Groups").then(({ Groups }) => ({ default: Groups })).catch(handleCatchChunkError),
)

export const GroupAttendance = lazy(() =>
  import("./GroupSingle/Tabs/GroupInfo/Attendance")
    .then(({ Attendance }) => ({ default: Attendance }))
    .catch(handleCatchChunkError),
)

export const ExamMake = lazy(() =>
  import("./GroupSingle/Tabs/Exam/ExamMake")
    .then(({ ExamMake }) => ({ default: ExamMake }))
    .catch(handleCatchChunkError),
)

export const HomeworkSingle = lazy(() =>
  import("./GroupSingle/Tabs/Homework/HomeworkSingle")
    .then(({ HomeworkSingle }) => ({ default: HomeworkSingle }))
    .catch(handleCatchChunkError),
)

export const ExamSingle = lazy(() =>
  import("./GroupSingle/Tabs/Exam/ExamSingle")
    .then(({ ExamSingle }) => ({ default: ExamSingle }))
    .catch(handleCatchChunkError),
)
