import { Endpoints, INetworkConfig, Instance, IResponse } from "@/api"
import { EmptyRooms, EmptyRoomsRequestParams } from "@/types"
import { ScheduleEndpoints } from "./endpoints"

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class ScheduleApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getEmptyRooms = (params: EmptyRoomsRequestParams): Promise<IResponse<EmptyRooms[]>> =>
    this.get(`${ScheduleEndpoints.Classrooms}${ScheduleEndpoints.Free}`, { params })
}

export const scheduleApi = new ScheduleApi(config)
