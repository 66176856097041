import { ICategory, StudentStatus } from "@/api/gamification/types"
import { VideoStatus } from "@/modules/kinescope"
import { HhOffice } from "@/pages/Controls/BranchOffice/types"
import { GenderTypes, RoleTypes } from "@/pages/Controls/types"
import { StaffInfo, UserTypes } from "@/pages/Profile/types"
import { Category, IStudents } from "../students/types"

//
export type Price = {
  id: number
  name: string
  value: number
  packet: boolean
  unit: string
  unitQuantity: number
  partlyPayable: boolean
}

export type PriceRes = {
  prices: Price[]
  total: number
}

export type LearningType = {
  id: number
  name: string
  createdAt: string
}

export type Staff = {
  id: number
  firstName: string
  lastName: string
  role: Role
}

export type Staffs = {
  staff: Staff[]
  title: string
}

export type GroupInfo = {
  staff: Staffs[]
  id: number
  name: string
  studentsCount: string
}

export interface IStudyMonths {
  month: number
  startDate: string
  endDate: string
}

export interface IGroup {
  [key: string]: any
  id: number
  name: string
  classrooms: Classroom[]
  staff?: IStaff[]
  studentsCount?: number
  startedDate: Date
  endedDate: Date
  status: IGroupStatus
  lessons?: ILesson
  beginTime?: string
  endTime?: string
  staffDetail?: StaffGroupDetail[]
  categoryName?: string
  courseId: number
  courseName?: string
  learningTypeName?: string
  attendanceKpi?: number
  examKpi?: number
  homeworkKpi?: number
  officeName?: string
  assigneeName: string
  academics: Academic[]
  scheduleItems: ScheduleItem[]
  priceId: number
}

export interface IGroupsResponse {
  total: number
  groups: IGroup[]
  staffGroups: IGroup[]
}

export interface Role {
  id: number
  name: string
  author: StaffInfo
  type: RoleTypes
  createdAt: string
}

export interface IStaff {
  id: number
  photo: string
  firstName: string
  lastName: string
  rating: number
  teacherWorkStatus: string
  isActive: boolean
  createdAt: string
  login: string
  accountStatus: IStaffAccountStatus
  staffType: IStaffTypes
  role?: Role
  beginTime?: string
  endTime?: string
  daysOfWeek?: string[]
  hasPermission: boolean
  phone?: string
  offices: HhOffice[]
}
export enum IStaffAccountStatus {
  Active = "Faol",
  InActive = "Nofaol",
  HasNotAccount = "Profili yo'q",
}
export enum IStaffTypes {
  ADMIN = 1,
  TEACHER = 2,
  ASSISTANT = 3,
}
export enum IGroupStatus {
  NotStarted = 1,
  Active = 2,
  Ended = 3,
}

export interface IStaffGroupDetail {
  assistants: IStaff[]
  teacher: IStaff
}
export interface StaffOfGroup {
  id: number
  firstName: string
  lastName: string
  role: Role
  hasPermission?: boolean
}

export interface StaffGroupDetail {
  title: string
  staff: IStaff[]
}

export type Classroom = {
  id: number
  name: string
}

export type Curriculum = {
  id: number
}

export interface ILesson {
  id: number
  name: string
  examId?: number
  createdAt: string
  curriculum?: Curriculum
  hasHomeworkDb?: boolean
  isMyLesson: boolean
}

export type Academic = {
  firstName: string
  lastName: string
  id: number
}

export interface ScheduleItem {
  id: number
  staff: StaffOfGroup
  daysOfWeek: string[]
  role: RoleTypes
  beginTime: string
  endTime: string
  classroomName: string
  startDate: string
  endDate: string
  weekdays: number
}

export interface IStudyMonth {
  id: string
  name: string
}

export const enum HomeworkAssignmentTypes {
  Random = 1,
  Same,
}
export const enum HomeworkDbLessonStatuses {
  Completed = 1,
  NotCompleted,
}

export interface StaffGroupsDetail {
  activeScheduleItems: ScheduleItem[]
  othersScheduleItems: ScheduleItem[]
}

export interface ICurriculum {
  id: number
  name: string
  position: number
  createdAt: string
  isUsed: boolean
}

export enum StudentSystemStatus {
  ACTIVE = 1,
  BLOCKED,
}

export interface Group {
  id: number
  hGroupId: number
  hName: string
  hBranchId: number
  startedDate: Date
  endedDate: Date
  createdAt: Date
  category: Category
}
export interface Student {
  id: number
  hId: number
  photo: string
  firstName: string
  lastName: string
  login: string
  phoneNumber: string
  genderType: GenderTypes
  status: StudentStatus
  createdAt: Date
  level?: number
  silver?: number
  xp?: number
  categories?: ICategory[]
  groups?: Group[]
  startedDate?: string
  endedDate?: string
  userId: number
  systemStatus: StudentSystemStatus
  middleName?: string
  totalDebtInHour: number
  totalPaidInCurrency: number
  totalDebtInCurrency: number
  currentDebtInHour: number
  totalPaidInHour: number
}

export enum HomeworkFilterStatus {
  Waiting = 1,
  Rejected,
  Accepted,
  Checking,
  Uncompleted,
  NotGiven,
}

export interface StudentLessonStatistic {
  homeworkScore: number
  homeworkStatus: HomeworkFilterStatus
  lessonName: string
  lessonCreatedAt: Date
  homeworkId: number
  lessonId: number
  homeworkDeletedAt?: boolean
}

export interface StudentHomeworkStatistic {
  id: number
  firstName: string
  lastName: string
  averageScore: number
  acceptedHomeworkCount: number
  rejectedHomeworkCount: number
  uncompletedHomeworkCount: number
  totalHomeworkCount: number
  waitingHomeworkCount?: number
  isStudying?: boolean
  middleName?: string
}

export interface IRole {
  id: number
  name: string
  author: StaffInfo
  type: RoleTypes
  createdAt: Date
}

export type GroupParameters = {
  groupId: number
  groupName: string
  officeId: number
  officeName: string
  courseId: number
  courseName: string
  categoryId: number
  categoryName: string
  learningTypeId: number
  learningTypeName: string
  capacity: number
  priceId: number
  status: number
  studentsCount: number
  contractsCount: number
  totalPaidCount: number
  priceName: string
  priceValue: number
  priceUnitQuantity: number
  priceNotation: string
  assigneeId: number
  assigneeName: string
  averageAge: number
}

export type GroupAcademicPrice = {
  id: number
  minAmount: number
  maxAmount?: number
  notation: string
  from: string
  priceMinAmount?: number
  hourNotation: string
}

export type GroupStatisticsAcademic = {
  firstName: string
  lastName: string
  id: number
  prices: GroupAcademicPrice[]
  totalHours: number
  totalPayments: number
}

export type GroupStatistics = {
  groupId: number
  totalPaid: number
  totalRemainInCurrency: number
  remainInHour: number
  totalRemainInHour: number
  lessonsCount: number
  remainInCurrency: number
  freeLessonsCount: number
  chargeableAbsenceCount: number
  nonChargeableAbsenceCount: number
  academics: GroupStatisticsAcademic[]
}

export type GroupDetail = {
  name: string
  id: number
  activeScheduleItems: ScheduleItem[]
  othersScheduleItems: ScheduleItem[]
}

export type GroupSchedule = {
  id: number
  groupId: number
  groupName: string
  beginTime: string
  endTime: string
  startDate: string
  beginDate: string
  endDate: string
  weekdays: number
  createdAt: string
  officeId: number
  classroomId: number
  classroomName: string
  staff: Staff[]
}

export type GroupScheduleRes = {
  scheduleItems: GroupSchedule[]
  total: number
}

export type ClassRoom = {
  id: number
  name: string
  createdAt: string
}

export type ClassRoomsRes = {
  classrooms: ClassRoom[]
  total: number
}

export type GroupStudentsRes = {
  students: Student[]
  total: number
}

export const enum DayType {
  Lesson = "lesson",
  FreeLesson = "free_lesson",
  ChargeableAbsence = "chargeable_absence",
  NonChargeableAbsence = "nonchargeable_absence",
}

export type DateSchema = {
  lessonId: number
  lessonOrder: number
  lessonDate: string
  dayType: DayType
}

export type DateSchemaRes = {
  total: number
  schema: DateSchema[]
}

export type StudentDuplications = {
  primaryId: number
  primaryFirstName: string
  secondaryId: number
  secondaryFirstName: string
  match: string
  primaryLastName: string
  primaryPhone: string
  primaryEmail: string
  secondaryLastName: string
  secondaryMiddleName: string
  secondaryPhone: string
  secondaryEmail: string
}

export const enum MatchType {
  Name = "name",
  Email = "email",
  Phone = "phone",
}

export type StudentDuplicationsRes = {
  total: number
  duplicates: StudentDuplications[]
}

export interface VideoStatusHistory {
  createdAt: string
  id: number
  message: string
  status: VideoStatus
}

export interface ILessonsResponse {
  total: number
  lessons: ILesson[]
}

export interface IVideo {
  [key: string]: any
  id: number
  lesson: ILesson
  size: number
  status: VideoStatus
  origName: string
  statusHistories: VideoStatusHistory[]
  url: string
  filename: string
  duration: number
  updatedAt: string
  createdAt: string
  hasLesson: boolean
  percentage?: number
  isUploading?: boolean
  currentStatus?: VideoStatus
  onStart?: () => void
  onAbort?: () => Promise<any>
}

export interface IVideosResponse {
  total: number
  videos: IVideo[]
}

export type Lesson = {
  id: number
  name: string
  createdAt: string
}

export interface HomeworkInfo {
  desc: string
  attachments: HomeworkAttachment[]
  homeworkDbs?: HomeworkInfo[]
  id: number
  allowedResubmission: boolean
}

export type Homework = {
  id: number
  deadline: string
  lesson: Lesson
  studentsCount: number
  waitingHomeworkCount: number
  acceptedHomeworkCount: number
  deadlineType: number
  homeworkInfo: HomeworkInfo
  createdByType: number
  createdAt: string
}

export type GetHomeworkRes = {
  total: number
  homework: Homework[]
}

export const enum ExamStatus {
  InProgress = 1,
  Completed,
}

export interface HomeworkAttachment {
  url: string
  origName: string
  size: number
  duration?: number
}

export type Exam = {
  id: number
  name: string
  createdAt: Date
  deadline: string
  examDate: string
  failedStudentsCount?: number
  announcedAt?: string
  announceable: boolean
  lessonDate?: string
  lessonId?: number
  lessonBeginTime: string
  status: ExamStatus
  desc?: string
  attachments?: HomeworkAttachment[]
  studyMonthId?: string
  studentsCount?: number
}

export type GetExamRes = {
  total: number
  exams: Exam[]
}

export interface IGetHomeworkFileUrlRes {
  filename: string
}

export interface IGetStudyMonthRes {
  studyMonths: IStudyMonths[]
}

export interface JournalDay {
  day?: number
  month?: number
  date?: string
  beginTime?: string
  endTime?: string
  homeworkId?: number
  title?: string
  lessonId?: number
}

export interface GeneralStats {
  total: number
  value: number
  percentage: number
}

export enum JournalAttendedStatus {
  Attended = 1,
  Absent,
  NoClass,
  Late,
  Ended,
}

export interface JournalStats {
  homeworkStatus: HomeworkFilterStatus
  attendedStatus: JournalAttendedStatus
  date: string
  attendance: GeneralStats
  homework: GeneralStats
  attendedCount?: number
  homeworkId?: number
  lessonId?: number
  acceptedCount?: number
  homeworkChatId?: number
  lateness?: number
}

export interface JournalStudent {
  id?: number
  firstName: string
  lastName: string
  userId?: number
  middleName?: string
  stats?: JournalStats[]
  attendance?: GeneralStats
  homework?: GeneralStats
  homeworkAverage?: number
  attendanceAverage?: number
}

export interface IAdditionalStatistics {
  stats: AdditionalStats[]
  homeworkPercentage: number
  attendancePercentage: number
}

export interface AdditionalStats {
  attendedStudentCount: number
  acceptedHomeworkCount: number
  attendance: GeneralStats
  homework: GeneralStats
}

export interface GetJournalListRes {
  days: JournalDay[]
  students: JournalStudent[]
  firstName?: string
  lastName?: string
  additionalStats: IAdditionalStatistics
  homework?: number
}

export enum HomeworkChatsStatus {
  WAITING = 1,
  REJECTED = 2,
  ACCEPTED = 3,
  NotDone,
  NotGiven,
}

export type HomeworkInfoCountRes = {
  waiting: number
  rejected: number
  accepted: number
  uncompleted: number
}

export interface IHomeworkChatUser {
  id: number
  firstName: string
  lastName: string
  studentId: number
  role?: IRole
  userType: UserTypes
  score?: number
  middleName: string
  staffId?: number
  roleName?: string
}

export interface IHomeworkChat {
  id: number
  sender: IHomeworkChatUser
  receiver: IHomeworkChatUser
  isEditable: boolean
  message: string
  status: HomeworkChatsStatus
  attachments: HomeworkAttachment[]
  createdAt: string
  lesson: ILesson
  comment?: string
  updatedAt: string
  score?: number
  deadline?: string
  xp?: number
  coin?: number
  homeworkDbId?: number
  isExam?: boolean
  submissions?: IHomeworkChat[]
}

export interface IGetAllHomeworkChatsRes {
  homeworkChats: IHomeworkChat[]
  homeworkInfo: HomeworkInfo
  lesson: ILesson
}

export interface IHomeworkResponse {
  total: number
  homeworkChatsData: IGetAllHomeworkChatsRes
  lesson: ILesson
}

export enum ExamChatsStatus {
  WAITING = 1,
  REJECTED = 2,
  ACCEPTED = 3,
  NotDone,
  NotGiven,
}

export type ExamChat = {
  studentId: number
  firstName: string
  lastName: string
  middleName: string
  status: ExamChatsStatus
  score: number
  chatCreatedAt: string
  chatUpdatedAt: string
}

export type GroupAcademicHour = {
  id: number
  priceName: string
  priceMinAmount: number
  hourNotation: string
  from: string
}

export type TeacherAttendance = {
  id: number
  photo: string
  lessonId: number
  lessonName: string
  beginTime: string
  endTime: string
  createdAt: string
}

export type GetTeacherAttendanceRes = {
  attendances: TeacherAttendance[]
  total: number
}
