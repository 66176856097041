export enum GroupsEndpoints {
  Groups = "/groups",
  StudentDuplications = "/students/duplicates",
  Merge = "/merge",
  Ignore = "/ignore",
  ForAdmin = "/for-admin",
  ForAcademic = "/for-academic",
  Lessons = "/lessons",
  CreateVideoForAdmin = "/video/create-for-admin",
  CreateVideoForAcademic = "/video/create-for-academic",
  Video = "/video",
  Videos = "/videos",
  TusFiles = "/tus/files",
  VideoDeleteForAdmin = "/video/delete-for-admin",
  VideoDeleteForAcademic = "/video/delete-for-academic",
  VideoUpdateStatusForAdmin = "/video/update-status-for-admin",
  VideoUpdateStatusForAcademic = "/video/update-status-for-academic",
  UpdateForAcademic = "/update-for-academic",
  UpdateForAdmin = "/update-for-admin",
  Homework = "/homework",
  Submissions = "/submissions",
  Create = "/create",
  ScheduleItems = "/schedule-items",
  HomeworkChats = "/homework-chats",
  ClassRooms = "/classrooms",
  Chats = "/chats",
  Check = "/check",
  HomeworkChat = "/homework-chat",
  CheckStudentHomeworkForAdmin = "/check-student-homework-for-admin",
  CheckStudentHomeworkForAcademic = "/check-student-homework-for-academic",
  NotSubmittedHomeworkStudents = "/not-submitted-homework-students",
  Attendance = "attendances",
  FaceControlLastScreenInDevice = "/face-control/last-screen-in-device",
  AttendanceSaveForAdmin = "/attendance/save-for-admin",
  AttendanceSaveForAcademic = "/attendance/save-for-academic",
  StaffAttendances = "/staff-attendances",
  Exams = "/exams",
  Participants = "/participants",
  Students = "/students",
  StudentSchema = "/student-schema",
  StudyMonths = "/study-months",
  Academic = "/academic",
  Exam = "/exam",
  HomeworkStatistics = "homework-statistics",
  AcademicGroups = "/academic/{id}/groups",
  ForStaff = "/for-staff",
  ByChecking = "/by-checking",
  ByStudent = "/by-student",
  AttendanceCurriculum = "/attendance/curriculum",
  Info = "/info",
  UsersStaff = "/staff",
  Count = "/count",
  Journal = "/journal",
  StudentGroups = "/student-groups",
  Prices = "/prices",
  Add = "/add",
  Continue = "/continue",
  Stop = "/stop",
  Deadline = "/deadline",
  ExamFailingExam = "/exams/failing-reasons",
  Results = "/results",
  Announce = "/announce",
  ByGroup = "/by-group",
  FilterObjects = "/filter-objects",
  HomeWorkUpload = "/upload/homework-attachments",
  FormOptions = "/form-options",
  AttendanceSaveAcademic = "/attendance/save-academic",
  LearningTypes = "learning-types",
  Parameters = "/parameters",
  Statistics = "/statistics",
  Detail = "/detail",
  AcademicSchema = "/academic-schema",
  AcademicPrices = "/academic-prices",
}
