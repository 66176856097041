import { makeAutoObservable, runInAction } from "mobx"
import { paymentsApi } from "@/api/payments"
import { addCatchNotification } from "@/modules/notifications"
import {
  AcademicPaymentRequest,
  AcademicPaymentResponse,
  DebtorsRequestParams,
  DebtorsResponse,
  Expense,
  Expenses,
  ExpensesRequestParams,
  ExpensesType,
  ExpensesUpdateParams,
  PaymentMethod,
  PaymentsRes,
  PaymentsType,
  PriceReqParams,
  PriceResponse,
  Remains,
  RemainsRequestParams,
  RevenueAndPaymentsReqParams,
  TransactionType,
  TuitionFeesRequestParams,
  TuitionFeesResponse,
} from "@/types"
import { getFormattedCurrentDate } from "@/utils"

class FinanceStore {
  revenueAndPayments: PaymentsRes | null = null
  revenueAndPaymentsPage = 1
  revenueAndPaymentsPerPage = 10
  academicPayments: AcademicPaymentResponse | null = null
  transactionTypes: TransactionType[] | null = null
  remains: Remains | null = null
  paymentTypes: PaymentsType[] | null = null
  prices: PriceResponse | null = null
  tuitionFees: TuitionFeesResponse | null = null
  debtors: DebtorsResponse | null = null
  expenses: Expenses | null = null
  expensesTypes: ExpensesType[] | null = null
  isExpenseModal = false
  isUpdateExpenseModal = false
  paymentMethods: PaymentMethod[] | null = null
  isDeleteExpenseModal = false
  singleExpense: Expense | null = null
  filterOptions: RevenueAndPaymentsReqParams = {
    page: 1,
    perPage: 10,
  }

  remainsFilterOptions: RemainsRequestParams = {
    page: 1,
    perPage: 10,
  }

  tuitionFeesFilterOptions: TuitionFeesRequestParams = {
    page: 1,
    perPage: 10,
  }

  debtorsFilterOptions: DebtorsRequestParams = {
    page: 1,
    perPage: 10,
    date: getFormattedCurrentDate(),
  }

  academicPaymentsFilterOptions: AcademicPaymentRequest = {
    page: 1,
    perPage: 10,
  }

  expensesFilterOptions: ExpensesRequestParams = {
    page: 1,
    perPage: 10,
  }

  constructor() {
    makeAutoObservable(this)
  }

  setExpensesFilterOptions = (options: ExpensesRequestParams) => {
    this.expensesFilterOptions = options
  }

  setPage = (value: number) => {
    this.revenueAndPaymentsPage = value
  }

  setExpenseModal = (value: boolean) => {
    this.isExpenseModal = value
  }

  setUpdateExpenseModal = (value: boolean) => {
    this.isUpdateExpenseModal = value
  }

  setSingleExpense = (expense: Expense | null) => {
    this.singleExpense = expense
  }

  setPaymentMethods = (data: PaymentMethod[]) => {
    this.paymentMethods = data
  }

  setPerPage = (value: number) => {
    this.revenueAndPaymentsPerPage = value
  }

  setAcademicPaymentsFilterOptions = (options: AcademicPaymentRequest) => {
    this.academicPaymentsFilterOptions = options
  }

  setFilterOption = (filterOptions: RevenueAndPaymentsReqParams) => {
    this.filterOptions = filterOptions
  }

  setRemainsFilterOption = (value: RemainsRequestParams) => {
    this.remainsFilterOptions = value
  }

  setTuitionFeesFilterOption = (values: TuitionFeesRequestParams) => {
    this.tuitionFeesFilterOptions = values
  }

  setTuitionFees = (data: TuitionFeesResponse) => {
    this.tuitionFees = data
  }

  setDebtorsFilterOption = (filterOptions: DebtorsRequestParams) => {
    this.debtorsFilterOptions = filterOptions
  }

  setRevenueAndPayments = (params: PaymentsRes | null) => {
    this.revenueAndPayments = params
  }

  setRemains = (remains: Remains | null) => {
    this.remains = remains
  }

  setPaymentsTypes = (value: PaymentsType[] | null) => {
    this.paymentTypes = value
  }

  setPrices = (value: PriceResponse) => {
    this.prices = value
  }

  setDebtors = (debtors: DebtorsResponse | null) => {
    this.debtors = debtors
  }

  setAcademicPayments = (params: AcademicPaymentResponse | null) => {
    this.academicPayments = params
  }

  setTransactionTypes = (value: TransactionType[]) => {
    this.transactionTypes = value
  }

  setExpenses = (data: Expenses) => {
    this.expenses = data
  }

  setExpenseTypes = (data: ExpensesType[]) => {
    this.expensesTypes = data
  }

  getRevenueAndPayments = (params: RevenueAndPaymentsReqParams) =>
    paymentsApi
      .getRevenueAndPayments(params)
      .then((response) => {
        if (response.success && response.data) {
          this.setRevenueAndPayments(response.data)
        }
      })
      .catch(addCatchNotification)

  getRemains = (params?: RemainsRequestParams) =>
    paymentsApi
      .getRemains(params)
      .then((response) => {
        if (response.success && response.data) {
          runInAction(() => {
            this.setRemains(response.data)
          })
        }
      })
      .catch(addCatchNotification)

  getDebtors = (params: DebtorsRequestParams) =>
    paymentsApi
      .getDebtors(params)
      .then((response) => {
        if (response.success && response.data) {
          this.setDebtors(response.data)
        }
      })
      .catch(addCatchNotification)

  getPaymentsTypes = () =>
    paymentsApi
      .getPaymentsTypes()
      .then((response) => {
        if (response.success && response.data) {
          runInAction(() => {
            this.setPaymentsTypes(response.data)
          })

          return response
        }
      })
      .catch(addCatchNotification)

  getPaymentPrices = (params?: PriceReqParams) =>
    paymentsApi
      .getPaymentPrices(params)
      .then((response) => {
        if (response.success && response.data) {
          runInAction(() => {
            this.setPrices(response.data)
          })
        }
      })
      .catch(addCatchNotification)

  getTuitionFees = (params?: TuitionFeesRequestParams) =>
    paymentsApi
      .getTuitionFees(params)
      .then((response) => {
        if (response.success && response.data) {
          this.setTuitionFees(response.data)
        }
      })
      .catch(addCatchNotification)

  getAcademicPayments = (params?: AcademicPaymentRequest) =>
    paymentsApi
      .getAcademicPayments(params)
      .then((response) => {
        if (response.success && response.data) {
          runInAction(() => {
            this.setAcademicPayments(response.data)
          })
        }
      })
      .catch(addCatchNotification)

  getTransactionTypes = () =>
    paymentsApi
      .getTransactionTypes()
      .then((response) => {
        if (response.success && response.data) {
          runInAction(() => {
            this.setTransactionTypes(response.data)
          })

          return response
        }
      })
      .catch(addCatchNotification)

  getExpenses = (params: ExpensesRequestParams) =>
    paymentsApi
      .getExpenses(params)
      .then((response) => {
        if (response.success && response.data) {
          this.setExpenses(response.data)
        }
      })
      .catch(addCatchNotification)

  getExpenseTypes = () =>
    paymentsApi
      .getExpenseTypes()
      .then((response) => {
        if (response.success && response.data) {
          this.setExpenseTypes(response.data)
        }
      })
      .catch(addCatchNotification)

  getPaymentMethods = () =>
    paymentsApi
      .getPaymentMethods()
      .then((response) => {
        if (response.success && response.data) {
          this.setPaymentMethods(response.data)

          return response
        }
      })
      .catch(addCatchNotification)

  addExpense = (params: ExpensesUpdateParams) => paymentsApi.addExpense(params).catch(addCatchNotification)

  updateExpense = (params: ExpensesUpdateParams) => paymentsApi.updateExpense(params).catch(addCatchNotification)

  deleteExpense = (id: number) => paymentsApi.deleteExpense(id).catch(addCatchNotification)

  reset = () => {
    this.revenueAndPayments = null
    this.revenueAndPaymentsPage = 1
    this.revenueAndPaymentsPerPage = 10
    this.paymentTypes = null
    this.prices = null
    this.tuitionFees = null
    this.debtors = null
    this.remains = null
    this.expensesTypes = null
    this.expenses = null
    this.isExpenseModal = false
    this.paymentMethods = null
    this.isUpdateExpenseModal = false
    this.singleExpense = null
    this.tuitionFeesFilterOptions = {
      page: 1,
      perPage: 10,
    }
    this.filterOptions = {
      page: 1,
      perPage: 10,
    }
    this.remains = null
    this.remainsFilterOptions = {
      page: 1,
      perPage: 10,
    }
    this.debtorsFilterOptions = {
      page: 1,
      perPage: 10,
      date: getFormattedCurrentDate(),
    }

    this.academicPaymentsFilterOptions = {
      page: 1,
      perPage: 10,
    }
    this.expensesFilterOptions = {
      page: 1,
      perPage: 10,
    }
  }
}

export const financeStore = new FinanceStore()
