import { useEffect } from "react"
import { observer } from "mobx-react"
import { Flex, Typography } from "antd"
import classNames from "classnames/bind"
import mainDictionary from "@/dictionary"
import { useStores } from "@/stores"
import { Filter } from "./Filter"
import { DuplicationsTable } from "./Table"

import styles from "./student-duplications.module.scss"

const cn = classNames.bind(styles)

export const StudentDuplications = observer(() => {
  const { groupsStore } = useStores()

  useEffect(() => {
    groupsStore.setStudentDuplicationsLoading(true)

    groupsStore.getStudentDuplications(groupsStore.studentDuplicationsFilterOptions)
  }, [groupsStore.studentDuplicationsFilterOptions])

  return (
    <Flex vertical gap={20}>
      <Typography.Title level={4}>{mainDictionary.studentDuplications}</Typography.Title>
      <Flex className={cn("student-duplications")} vertical>
        <Filter />
        <DuplicationsTable />
      </Flex>
    </Flex>
  )
})
