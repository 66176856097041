export const enum PaymentEndpoints {
  Payments = "/payments",
  Balances = "/balances",
  Types = "/types",
  Prices = "/prices",
  GroupsPayments = "/groups/payments",
  Debtors = "/debtors",
  AcademicPayments = "academics/payments",
  CostItems = "/cost-items",
  Methods = "/methods",
}
