import { Link } from "react-router-dom"
import { observer } from "mobx-react"
import { DeleteOutlined, EditOutlined, LeftOutlined, RightOutlined, ScheduleOutlined } from "@ant-design/icons"
import { Button, Flex, Popconfirm, Tooltip, Typography } from "antd"
import classNames from "classnames/bind"
import { useBoolean } from "usehooks-ts"
import { GroupSchedule } from "@/api/groups"
import mainDictionary from "@/dictionary"
import { addCatchNotification, addSuccessNotification } from "@/modules/notifications"
import { convertWeekDays } from "@/pages/Groups/Table/util"
import { useStores } from "@/stores"
import { formatDate } from "@/utils"
import { CalendarDay } from "./CalendarDay"

import styles from "./row-item.module.scss"

const cn = classNames.bind(styles)

export const RowItem = observer((props: GroupSchedule) => {
  const { groupsStore } = useStores()
  const { value: isLoading, setTrue: setLoadingTrue, setFalse: setLoadingFalse } = useBoolean()

  const handleEdit = () => {
    groupsStore.setScheduleModal(true)
    groupsStore.setSelectedSchedule(props)
  }

  const handleNextClick = () => {
    groupsStore.setAcademicScheduleSchemaOption({
      ...groupsStore.academicScheduleSchemaOption,
      [props.id]: {
        ...groupsStore.academicScheduleSchemaOption?.[props.id]!,
        page: groupsStore.academicScheduleSchemaOption?.[props.id]?.page! + 1,
      },
    })

    groupsStore
      .getAcademicScheduleSchema({
        id: props.id,
        page: groupsStore.academicScheduleSchemaOption?.[props.id]?.page || 1,
        perPage: groupsStore.academicScheduleSchemaOption?.[props.id]?.perPage || 19,
        academicId: props.staff?.[0]?.id,
      })
      .then((res) => {
        if (res.success) {
          groupsStore.setAcademicScheduleSchema({
            ...groupsStore.academicScheduleSchema,
            [props.id]: res.data,
          })
        }
      })
  }

  const handlePrevClick = () => {
    groupsStore.setAcademicScheduleSchemaOption({
      ...groupsStore.academicScheduleSchemaOption,
      [props.id]: {
        ...groupsStore.academicScheduleSchemaOption?.[props.id]!,
        page: groupsStore.academicScheduleSchemaOption?.[props.id]?.page! - 1,
      },
    })

    groupsStore
      .getAcademicScheduleSchema({
        id: props.id,
        page: groupsStore.academicScheduleSchemaOption?.[props.id]?.page || 1,
        perPage: groupsStore.academicScheduleSchemaOption?.[props.id]?.perPage || 19,
        academicId: props.staff?.[0]?.id,
      })
      .then((res) => {
        if (res.success) {
          groupsStore.setAcademicScheduleSchema({
            ...groupsStore.academicScheduleSchema,
            [props.id]: res.data,
          })
        }
      })
  }

  const handleDelete = () => {
    setLoadingTrue()

    groupsStore
      .deleteSchedule(props.id)
      .then((res) => {
        if (res.success && groupsStore.groupInfo?.id) {
          groupsStore.getGroupSchedule({
            page: 1,
            perPage: 100,
            groupId: groupsStore.groupInfo?.id,
          })

          addSuccessNotification(mainDictionary.successfullyDeleted)
        }
      })
      .catch(addCatchNotification)
      .finally(() => setLoadingFalse())
  }

  return (
    <Flex gap={10} vertical>
      <div className={cn("row")}>
        <Link className={cn("row__name")} to="/">
          {`${props.staff?.[0]?.firstName} ${props.staff?.[0]?.lastName}`}
        </Link>

        <Typography.Text>{convertWeekDays(props.weekdays)}</Typography.Text>

        <Typography.Text>
          {props.beginTime?.slice(0, 5)} dan - {props.endTime?.slice(0, 5)} gacha
        </Typography.Text>
        <Typography.Text>
          {formatDate(props.beginDate)} - {formatDate(props.endDate)}
        </Typography.Text>

        <Typography.Text>{props.classroomName || "-"}</Typography.Text>

        <Flex gap={16} justify="center">
          <Tooltip title={mainDictionary.schedule}>
            <Button icon={<ScheduleOutlined />} />
          </Tooltip>

          <Tooltip title={mainDictionary.edit}>
            <Button onClick={handleEdit} icon={<EditOutlined />} />
          </Tooltip>

          <Popconfirm placement="left" onConfirm={handleDelete} title={mainDictionary.areYouSureToDelete}>
            <Tooltip title={mainDictionary.delete}>
              <Button loading={isLoading} danger icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
        </Flex>
      </div>

      <Flex vertical gap={12}>
        <Flex gap={20} align="center">
          <Typography.Title level={5}>
            {`${groupsStore.academicScheduleSchemaOption?.[props.id]?.page || 1} / 
              ${Math.ceil((groupsStore.academicScheduleSchema?.[props.id]?.total || 1) / 19)}`}
          </Typography.Title>

          <Flex gap={8}>
            <Button
              disabled={groupsStore.academicScheduleSchemaOption?.[props.id]?.page === 1}
              icon={<LeftOutlined />}
              type="text"
              size="small"
              onClick={handlePrevClick}
            />

            <Button
              disabled={Boolean(
                (groupsStore.academicScheduleSchemaOption?.[props.id]?.page || 1) >=
                  (groupsStore.academicScheduleSchema?.[props.id]?.total || 1) / 19,
              )}
              icon={<RightOutlined />}
              type="text"
              size="small"
              onClick={handleNextClick}
            />
          </Flex>
        </Flex>
        <Flex wrap gap={10}>
          {groupsStore.academicScheduleSchema?.[props?.id]?.schema.map((item) => (
            <CalendarDay
              scheduleId={props.id}
              academicId={props.staff?.[0]?.id}
              category={item.dayType}
              date={item.lessonDate}
              key={item.lessonId}
              page={groupsStore.academicScheduleSchemaOption?.[props.id]?.page || 1}
              perPage={groupsStore.academicScheduleSchemaOption?.[props.id]?.perPage || 15}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
})
