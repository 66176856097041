import { useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { ClearOutlined } from "@ant-design/icons"
import { Button, Collapse, DatePicker, Flex, Form, Select } from "antd"
import classNames from "classnames/bind"
import dayjs from "dayjs"
import mainDictionary from "@/dictionary"
import { allOption, curriculumDate } from "@/pages/Groups/constants"
import { useStores } from "@/stores"
import { formatDate } from "@/utils/date"
import { useCheckMyGroup } from "@/utils/hooks/useCheckMyGroup"

import styles from "./filters.module.scss"

const cn = classNames.bind(styles)
const controller = new AbortController()

export const Filter = observer(() => {
  const { groupsStore } = useStores()
  const { id } = useParams()
  const isAcademic = useCheckMyGroup()
  const [form] = Form.useForm()

  useEffect(() => {
    if (!id) return

    const groupId = parseInt(id, 10)

    groupsStore.setJournalLoader(true)

    groupsStore.getJournalFilterObjects(parseInt(id, 10)).then(() => {
      const studyMonth = groupsStore.journalObjectFilters?.studyMonths
      const lastMonth = studyMonth?.at(-1)

      if (studyMonth?.length) {
        groupsStore.getJournals({
          groupId,
          endDate: lastMonth?.endDate,
          startDate: lastMonth?.startDate,
        })

        return
      }

      groupsStore.getJournals({ groupId })
    })

    return () => {
      groupsStore.resetJournalObjectFilters()
      controller.abort()
    }
  }, [])

  const studyMonth = useMemo(() => {
    const month = groupsStore.journalObjectFilters?.studyMonths.map((item) => ({
      label: `${item.month}-oy (${curriculumDate(item.startDate)}-${curriculumDate(item.endDate)})`,
      value: `${item.startDate} ${item.endDate}`,
    }))

    return month?.length ? [allOption, ...month] : [allOption]
  }, [groupsStore.journalObjectFilters])

  useEffect(() => {
    const studyMonth = groupsStore.journalObjectFilters?.studyMonths

    if (studyMonth?.length) {
      const lastMonth = studyMonth?.at(-1)

      form.setFieldValue("curriculum", lastMonth ? `${lastMonth?.startDate} ${lastMonth?.endDate}` : null)
    }
  }, [groupsStore.journalObjectFilters])

  const handleChangeDate = (date: any) => {
    if (!date) {
      groupsStore.setJournalTableLoading(true)

      groupsStore.getJournals({ groupId: parseInt(id!, 10) })

      return
    }

    form.setFieldValue("curriculum", null)
    const startDate = formatDate(date?.[0], true)
    const endDate = formatDate(date?.[1], true)

    groupsStore.setJournalTableLoading(true)

    groupsStore.getJournals({
      groupId: parseInt(id!, 10),
      startDate,
      endDate,
    })
  }

  const handleChangeStudyMonth = (value: string) => {
    const dates = value?.split(" ")
    const startDate = dates?.[0]
    const endDate = dates?.[1]

    form.setFieldValue("date", [undefined, undefined])

    groupsStore.setJournalTableLoading(true)

    groupsStore.getJournals({
      groupId: parseInt(id!, 10),
      startDate,
      endDate,
    })
  }

  const disabledDate = (current: dayjs.Dayjs) => current.isAfter(dayjs().endOf("day"))
  // current.isBefore(
  //   dayjs((isAcademic ? groupsStore?.groupSingle : groupsStore.singleGroup)?.startedDate).startOf("day"),
  // )

  const handleReset = () => {
    if (!id) return

    const groupId = parseInt(id, 10)

    groupsStore.setJournalTableLoading(true)

    form.resetFields()

    const studyMonth = groupsStore.journalObjectFilters?.studyMonths

    if (studyMonth?.length) {
      const lastMonth = studyMonth?.at(-1)

      form.setFieldValue("curriculum", lastMonth ? `${lastMonth?.startDate} ${lastMonth?.endDate}` : null)

      if (studyMonth?.length) {
        const lastMonth = studyMonth?.at(-1)

        groupsStore.getJournals({
          groupId,
          endDate: lastMonth?.endDate,
          startDate: lastMonth?.startDate,
        })

        form.setFieldValue("curriculum", `${lastMonth?.startDate} ${lastMonth?.endDate}`)
      }
    }
  }

  return (
    <>
      <Collapse defaultActiveKey={["2"]} className={cn("journal-filter")} size="small">
        <Collapse.Panel header={mainDictionary.filters} key="2">
          <Form form={form} layout="vertical">
            <Flex gap="large" align="center">
              <Form.Item name="curriculum" label={mainDictionary.byCurriculum}>
                <Select onChange={handleChangeStudyMonth} options={studyMonth} className={cn("filter__select")} />
              </Form.Item>

              <Form.Item name="date" label={mainDictionary.byDate}>
                <DatePicker.RangePicker
                  disabledDate={disabledDate}
                  onChange={handleChangeDate}
                  className={cn("filter__select")}
                />
              </Form.Item>

              <Button onClick={handleReset} icon={<ClearOutlined />} />
            </Flex>
          </Form>
        </Collapse.Panel>
      </Collapse>
    </>
  )
})
