export const financeDictionary = {
  finance: "Moliya",
  revenueAndPayments: "Tushum va to'lovlar",
  tuitionFees: "O'qish uchun to'lovlar",
  teacherFees: "O'qituvchi uchun to'lovlar",
  debtors: "Qarzdorlar",
  remains: "Qoldiqlar sh.h.",
  payerBalances: "To'lovchining shaxsiy hisobidagi qoldiqlar",
  incomeIndicator: "Daromad ko'rsatkichi",
  costs: "Xarajatlar",
  historyOfOperations: "Operatsiyalar tarixi",
  typeOfPayment: "To'lov turi",
  financePeriod: "Davr",
  numberOfAccount: "Hisob raqami",
  payer: "To'lovchi",
  financeAmount: "Summa",
  whosAdded: "Kim qo'shgan",
  getPaymentSummary: (payersCount: number, amount: number, transactionCount: number) =>
    `${payersCount} nafar to'lovchidan jami ${amount} so'mlik ${transactionCount} ta to'lovlar aniqlandi.`,
  pricingDetails: "Narx, chegirma, qo'shimcha to'lovlar",
  hour: "Soat",
  incoming: "Kirim",
  outgoing: "Chiqim",
  remainingAmount: "Qoldiq summa",
  payerMetrics: (payerCount: number, totalAmount: number) =>
    `Belgilangan mezonlar asosida ${payerCount} nafar to'lovchi aniqlandi. Bir PM uchun umumiy summa: ${totalAmount} so'm`,
  delayCalculation: "Kechikishini hisoblash",
  responsible: "Mas'ul",
  fullName: "FISh",
  phoneNumber: "Telefon raqami",
  remainingHour: "Qoldiq soat",
  getTotalDebtors: (totalDebtors: number, debtorAmount: number) =>
    `Jami ${totalDebtors} nafar qarzdor aniqlangan. Jami qarz ${debtorAmount} so'm`,
  startedMonth: "Boshlanish oyi",
  endedMonth: "Tugash oyi",
  revenueCalculationMethod: "Daromadni hisoblash usuli",
  expenseCalculationMethod: "Xarajatlarni hisoblash usuli",
  chooseFieldsToReports: "Hisobotni tuzish uchun mezonlarni belgilang",
  unpaid: "To'lanmagan",
  paid: "To'langan",
  unconfirmed: "Tasdiqlanmagan",
  sum: "so'm",
  expenses: "Xarajatlar",
  expense: "Xarajat",
  typeOfExpense: "Xarajat turi",
  exportToCSV: "CSV yuklash",
  fromPaidAt: "To'langan vaqtdan boshlab",
  createdAtFrom: "Qo'shilgan vaqtdan boshlab",
  extraFilter: "Qo'shimcha filter",
  clearAll: "Barchasini tozalash",
  whosReceived: "Kim qabul qilgan",
  typeOfPrice: "Narx turi",
  typeOfTransaction: "Tur",
  price: "Narx",
  refundsOnly: "Faqat hisobga qaytarishlar",
  removedGroupMembers: "Faqat guruhdan olib tashlanganlarga",
  package: "Paket",
  doesNotShowDebsLess: "Qarzlaringizni kamroq ko'rsatmang",
  era: "Davr",
  paymentsForTeachers: "O'qituvchi to'lovlari",
  addExpense: "Xarajat qo'shish",
  deleteExpense: "Xarajatni o'chirish",
  idNotFound: "ID topilmadi",
}
