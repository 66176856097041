import { observer } from "mobx-react"
import { UserOutlined } from "@ant-design/icons"
import { Avatar, Flex, Typography } from "antd"
import classNames from "classnames/bind"
import mainDictionary from "@/dictionary"

import styles from "./info.module.scss"

const cn = classNames.bind(styles)

export const Info = observer(() => {
  console.log("")

  return (
    <Flex vertical gap={16} className={cn("info")}>
      <Typography.Title level={5}>{mainDictionary.info}</Typography.Title>

      <Flex gap={12} align="center">
        <Avatar size={60} shape="circle" icon={<UserOutlined />} />

        <Flex vertical gap={4}>
          <Typography.Title level={5} className={cn("info__name")}>
            Falonchi falonchixon
          </Typography.Title>

          <Typography.Text className={cn("info__position")}>Ustoz</Typography.Text>
        </Flex>
      </Flex>

      <Flex vertical gap={8}>
        <Flex className={cn("info__wrapper")} gap={32} align="center">
          <Flex vertical gap={4}>
            <Typography.Text className={cn("info__subtitle")}>{mainDictionary.lessonDay}</Typography.Text>

            <Typography.Title level={5} className={cn("info__day")}>
              10 Avg, 2025
            </Typography.Title>
          </Flex>

          <Flex vertical gap={4}>
            <Typography.Text className={cn("info__subtitle")}>{mainDictionary.lessonTime}</Typography.Text>

            <Typography.Title level={5} className={cn("info__day")}>
              10 Avg, 2025
            </Typography.Title>
          </Flex>

          <Flex vertical gap={4}>
            <Typography.Text className={cn("info__subtitle")}>{mainDictionary.branch}</Typography.Text>

            <Typography.Title level={5} className={cn("info__day")}>
              10 Avg, 2025
            </Typography.Title>
          </Flex>

          <Flex vertical gap={4}>
            <Typography.Text className={cn("info__subtitle")}>{mainDictionary.room}</Typography.Text>

            <Typography.Title level={5} className={cn("info__day")}>
              10 Avg, 2025
            </Typography.Title>
          </Flex>
        </Flex>

        <Flex vertical gap={12} className={cn("info__wrapper")}>
          <Typography.Title level={5}>{mainDictionary.payment}</Typography.Title>

          <Flex justify="space-between" align="center">
            <Typography.Text>{mainDictionary.students}</Typography.Text>
            <Typography.Text>10</Typography.Text>
          </Flex>

          <Flex justify="space-between" align="center">
            <Typography.Text>{mainDictionary.teachers}</Typography.Text>
            <Typography.Text>10</Typography.Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
})
