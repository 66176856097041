import { observer } from "mobx-react"
import { Checkbox, Collapse, Flex, Form, Tag, Typography } from "antd"
import { CheckboxProps } from "antd/lib"
import TriangleWithoutBorderImage from "@/assets/img/downloads-without-border.svg?react"
import TriangleImage from "@/assets/img/triangle.svg?react"
import mainDictionary from "@/dictionary"
import { useStores } from "@/stores"

import styles from "./info.module.scss"

export const Info = observer(() => {
  const { groupsStore } = useStores()

  const handleChangeStatus: CheckboxProps["onChange"] = (event) => {
    groupsStore.setHomeworkStatusIcon(event.target.checked)
  }

  return (
    <Collapse defaultActiveKey={["1"]} className={styles.info} size="small">
      <Collapse.Panel header={mainDictionary.signAndColors} key="1">
        <Flex gap={50} className={styles.info__top} align="flex-start">
          <Typography.Title className={styles["info__no-space"]} level={5}>
            {mainDictionary.homeworkConditions}
          </Typography.Title>

          <Form.Item name="status" className={styles["info__no-space"]} label={mainDictionary.homeWorkStatusIcon}>
            <Checkbox defaultChecked onChange={handleChangeStatus} />
          </Form.Item>
        </Flex>

        <Flex className={styles.info__top} gap="middle">
          <Flex align="center">
            <TriangleWithoutBorderImage className={`${styles.info__icon} ${styles["icon--accepted"]}`} />
            <Typography.Text>- {mainDictionary.acceptedHomework}</Typography.Text>
          </Flex>

          <Flex align="center">
            <TriangleWithoutBorderImage className={styles[`info__icon` + ` ${styles["icon--rejects"]}`]} />
            <Typography.Text>- {mainDictionary.rejects}</Typography.Text>
          </Flex>

          <Flex align="center">
            <TriangleWithoutBorderImage className={`${styles.info__icon} ${styles["icon--waiting"]}`} />
            <Typography.Text>- {mainDictionary.waiting}</Typography.Text>
          </Flex>

          <Flex align="center">
            <TriangleWithoutBorderImage className={`${styles.info__icon} ${styles["icon--notDone"]}`} />
            <Typography.Text>- {mainDictionary.notDoneHomework}</Typography.Text>
          </Flex>

          <Flex className="info__not-given" align="center">
            <TriangleImage />
            <Typography.Text>- {mainDictionary.notGivenHomework}</Typography.Text>
          </Flex>
        </Flex>

        <Typography.Title level={5}>{mainDictionary.attendanceConditions}</Typography.Title>

        <Flex gap="middle">
          <Flex>
            <Tag>+</Tag>

            <Typography.Text>- {mainDictionary.inLesson}</Typography.Text>
          </Flex>

          <Flex>
            <Tag className={styles["info__came-late-sign"]}>+</Tag>

            <Typography.Text>- {mainDictionary.cameLate}</Typography.Text>
          </Flex>

          <Flex>
            <Tag className={styles.info__absent}>-</Tag>

            <Typography.Text>- {mainDictionary.wasNotInLesson}</Typography.Text>
          </Flex>

          <Flex>
            <Tag className={styles.info__finished}>*</Tag>

            <Typography.Text>- {mainDictionary.finishGroup}</Typography.Text>
          </Flex>

          <Flex>
            <Tag className={styles["info__have-not"]}>!</Tag>

            <Typography.Text>- {mainDictionary.didHaveLesson}</Typography.Text>
          </Flex>
        </Flex>
      </Collapse.Panel>
    </Collapse>
  )
})
