import { observer } from "mobx-react"
import { Modal, Table } from "antd"
import mainDictionary from "@/dictionary"
import { videoStatusColumns } from "@/pages/Groups/constants"
import { useStores } from "@/stores"

export const StatusHistory = observer(() => {
  const { groupsStore } = useStores()

  const handleClose = () => {
    groupsStore.setStatusHistoryModal(false)
    groupsStore.setSelectedVideo(null)
  }

  return (
    <Modal
      open={groupsStore.statusHistoryModal}
      title={mainDictionary.videoHistory}
      onCancel={handleClose}
      onOk={handleClose}
    >
      <Table
        columns={videoStatusColumns}
        dataSource={groupsStore.selectedVideo?.statusHistories}
        bordered
        pagination={false}
      />
    </Modal>
  )
})
