import { useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { DeleteOutlined, MoreOutlined } from "@ant-design/icons"
import { Flex, Popconfirm, Popover, Space } from "antd"
import { Button } from "@/ant"
import { IVideo } from "@/api/groups"
import mainDictionary from "@/dictionary"
import { addCatchNotification, addSuccessNotification } from "@/modules/notifications"
import { permissionObjectTypes, PermissionOptions, useCheckPermission } from "@/modules/permission"
import { useStores } from "@/stores"
import { VideoEdit } from "./VideoEdit"

interface IProps {
  video: IVideo
}

export const VideoActions = observer(({ video }: IProps) => {
  const { groupsStore } = useStores()
  const { id: groupId } = useParams()

  const [isAvailableEdit] = useCheckPermission({
    // TODO
    module: permissionObjectTypes.allGroups,
    option: PermissionOptions.Update,
  })
  const [isAvailableEditMyGroup] = useCheckPermission({
    module: permissionObjectTypes.myGroupsVideoUpload,
    option: PermissionOptions.Update,
  })

  const handleDelete = async () => {
    if (!groupId) return

    groupsStore.setVideoLoader(true)

    try {
      await groupsStore.deleteVideo(video?.id)

      const videoInfo = {
        value: groupsStore.key,
        keys: ["origName"],
        groupId: parseInt(groupId, 10),
      }

      await groupsStore.getAllVideos(videoInfo)

      addSuccessNotification(mainDictionary.successDelete)
    } catch (err: Error | unknown) {
      addCatchNotification(err)
    } finally {
      groupsStore.setVideoLoader(false)
    }
  }

  const handleConfirmDelete = () => {
    handleDelete()
  }

  if (!isAvailableEdit && !isAvailableEditMyGroup && video.isUploading) {
    return
  }

  return (
    <Popover
      placement="left"
      content={
        <Flex vertical gap={8} className="action-wrapper">
          <VideoEdit video={video} />

          <Popconfirm placement="left" title={mainDictionary.sureToDeleteVideo} onConfirm={handleConfirmDelete}>
            <Button danger icon={<DeleteOutlined />}>
              {mainDictionary.delete}
            </Button>
          </Popconfirm>
        </Flex>
      }
    >
      <Button type="text" icon={<MoreOutlined />} />
    </Popover>
  )
})
