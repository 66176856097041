import { Dispatch, SetStateAction } from "react"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react"
import { Menu as AntdMenu } from "antd"
import { MenuProps } from "antd/es/menu/menu"
import { appStore } from "@/stores/app"
import { menuWithOutChildren } from "./constants"

type Props = {
  setVisibleDrawer?: Dispatch<SetStateAction<boolean>>
  toggleVisibleDrawer?: VoidFunction
}

export const Menu = observer(({ setVisibleDrawer, toggleVisibleDrawer }: Props) => {
  const { selectedKeys, menuItems } = appStore

  const navigate = useNavigate()

  const handleClick: MenuProps["onClick"] = ({ key, domEvent }) => {
    domEvent.preventDefault()
    domEvent.stopPropagation()

    if (domEvent.ctrlKey || domEvent.metaKey) {
      window.open(`${location.origin}${key}`)

      return
    }
    navigate(key)

    if (menuWithOutChildren.includes(key) && toggleVisibleDrawer) {
      toggleVisibleDrawer()
    }
  }

  return (
    <AntdMenu
      defaultOpenKeys={selectedKeys}
      defaultSelectedKeys={selectedKeys}
      items={menuItems}
      onClick={handleClick}
      selectedKeys={selectedKeys}
      mode="inline"
      theme="light"
      style={{ border: "none" }}
    />
  )
})
