import { forwardRef } from "react"
import { Button as AntdButton, ButtonProps, Tooltip } from "antd"
import mainDictionary from "@/dictionary"
import { useOnLine } from "@/shared/hooks"

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { isOnline } = useOnLine()

  return (
    <Tooltip color="black" title={mainDictionary.noInternet} {...(isOnline ? { open: false } : {})}>
      <AntdButton ref={ref} {...props} {...(!isOnline ? { disabled: true } : {})} />
    </Tooltip>
  )
})
