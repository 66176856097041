import { useStores } from "@/stores"
import { Button, Flex, Popconfirm, Tooltip } from "antd"
import { observer } from "mobx-react"
import mainDictionary from "@/dictionary"
import { CalendarOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { IGroup } from "@/api/groups"
import { addCatchNotification, addSuccessNotification } from "@/modules/notifications"
import { getPerPages } from "@/shared"

export const TableActions = observer((group: IGroup) => {
  const { groupsStore } = useStores()
  const perPageFromLocalStorage = getPerPages()

  const handleEdit = () => {
    groupsStore.setGroupMakeModal(true)
    groupsStore.setSelectedGroupForEdit(group)
  }

  const handleDelete = () => {
    if (!group.id) return

    groupsStore.setGroupsTableLoading(true)

    groupsStore
      .deleteGroup(group.id)
      .then((res) => {
        if (res.success) {
          addSuccessNotification(mainDictionary.successfullyDeleted)

          groupsStore.getGroups({
            ...groupsStore.filterOptions,
            page: 1,
            perPage: perPageFromLocalStorage?.groupsPerPage || 10,
          })
        }
      })
      .catch(addCatchNotification)
  }

  return (
    <Flex justify="center" gap={8}>
      <Tooltip title={mainDictionary.schedule}>
        <Button size="small" icon={<CalendarOutlined />} />
      </Tooltip>

      <Tooltip title={mainDictionary.edit}>
        <Button onClick={handleEdit} size="small" icon={<EditOutlined />} />
      </Tooltip>

      <Tooltip title={mainDictionary.delete}>
        <Popconfirm
          okText={mainDictionary.yes}
          cancelText={mainDictionary.cancel}
          onConfirm={handleDelete}
          placement="left"
          title={mainDictionary.areYouSureToDelete}
        >
          <Button danger size="small" icon={<DeleteOutlined />} />
        </Popconfirm>
      </Tooltip>
    </Flex>
  )
})
