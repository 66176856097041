import { ROUTES } from "@/constants"
import { AvailableComponent } from "@/modules/AvailableComponent"
import { permissionObjectTypes } from "@/modules/permission"
import { EmptyRooms } from "./lazy"

export const scheduleRouters = [
  {
    path: ROUTES.schedule,
    children: [
      {
        path: ROUTES.emptyRooms,
        element: (
          // Should be change
          <AvailableComponent permissionName={permissionObjectTypes.finance}>
            <EmptyRooms />
          </AvailableComponent>
        ),
      },
    ],
  },
]
