import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { Flex, Segmented, Typography } from "antd"
import mainDictionary from "@/dictionary"
import { GroupLessonTabs } from "@/pages/Groups/constants"
import { useStores } from "@/stores"
import { Exam } from "../Exam"
import { Homework } from "../Homework"
import { Journal } from "../Journal"
import { Videos } from "../Videos"

export const GroupLessons = observer(() => {
  const { groupsStore } = useStores()
  const { id: groupId } = useParams()

  const handleSegmentChange = (value: string) => {
    groupsStore.setActiveGroupLessonsTab(value as GroupLessonTabs)
  }

  const groupLessonTabs = useMemo(
    () => [
      {
        label: mainDictionary.homework,
        value: GroupLessonTabs.Homework,
      },
      {
        label: mainDictionary.videos,
        value: GroupLessonTabs.Videos,
      },
      {
        label: mainDictionary.exams,
        value: GroupLessonTabs.Exam,
      },
      {
        label: mainDictionary.journal,
        value: GroupLessonTabs.Journal,
      },
    ],
    [],
  )

  return (
    <Flex vertical gap={22}>
      <Flex gap={32} align="center">
        <Typography.Title level={5}>{mainDictionary.groupLessons}</Typography.Title>
        <Segmented value={groupsStore.activeGroupLessonsTab} onChange={handleSegmentChange} options={groupLessonTabs} />
      </Flex>

      {groupsStore.activeGroupLessonsTab === GroupLessonTabs.Homework ? (
        <Homework />
      ) : groupsStore.activeGroupLessonsTab === GroupLessonTabs.Videos ? (
        <Videos groupName={groupsStore.groupDetail?.name || ""} groupId={parseInt(groupId!, 10)} />
      ) : groupsStore.activeGroupLessonsTab === GroupLessonTabs.Exam ? (
        <Exam />
      ) : (
        <Journal />
      )}
    </Flex>
  )
})
