import { observer } from "mobx-react"
import { EditOutlined } from "@ant-design/icons"
import { Button } from "@/ant"
import { IVideo } from "@/api/groups"
import mainDictionary from "@/dictionary"
import { useStores } from "@/stores"
import { UploadType } from "@/stores/groups"

type Props = {
  available?: boolean
  video: IVideo
  videoStatus?: UploadType
}

export const VideoEdit = observer(({ video }: Props) => {
  const { groupsStore } = useStores()

  const handleEdit = () => {
    groupsStore.setAddVideoModal(true)
    groupsStore.setSelectedVideo(video)
  }

  return (
    <Button icon={<EditOutlined />} onClick={handleEdit}>
      {mainDictionary.edit}
    </Button>
  )
})
