import "./helper.css"

import { useEffect, useMemo } from "react"
import { observer } from "mobx-react"
import { TeamOutlined, UserOutlined } from "@ant-design/icons"
import { Flex, Tabs, Typography } from "antd"
import classNames from "classnames/bind"
import { CustomFieldTargetType } from "@/api/settings"
import mainDictionary from "@/dictionary"
import { useStores } from "@/stores"
import { MakeModal } from "./MakeModal"
import { Students } from "./Tabs"

import styles from "./custom-fields.module.scss"

const cn = classNames.bind(styles)

export const CustomFields = observer(() => {
  const { settingsStore } = useStores()

  const tabs = useMemo(
    () => [
      {
        label: (
          <Flex vertical gap={10} align="center">
            <UserOutlined className={cn("custom-fields__icon")} />

            <Typography.Title level={5}>{mainDictionary.students}</Typography.Title>
          </Flex>
        ),
        children: <Students />,
        key: CustomFieldTargetType.Student,
      },
      {
        label: (
          <Flex vertical gap={10} align="center">
            <TeamOutlined className={cn("custom-fields__icon")} />

            <Typography.Title level={5}>{mainDictionary.groups}</Typography.Title>
          </Flex>
        ),
        children: <Students />,
        key: CustomFieldTargetType.Group,
      },
    ],
    [],
  )

  const handleTabChange = (value: string) => {
    settingsStore.setSelectedCustomField(null)
    settingsStore.setCustomFieldsTarget(value)
  }

  useEffect(() => {
    settingsStore.setCustomFieldsLoading(true)
    settingsStore.getCustomFields({ target: settingsStore.customFieldsTarget })
  }, [settingsStore.customFieldsTarget])

  return (
    <Flex vertical gap={20} className={`custom-fields ${cn("custom-fields")}`}>
      <Typography.Title level={4}>{mainDictionary.customFields}</Typography.Title>
      <Tabs onChange={handleTabChange} className={cn("custom-fields__wrapper")} items={tabs} centered />

      {settingsStore.makeCustomFieldModal && <MakeModal />}
    </Flex>
  )
})
