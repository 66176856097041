import "./helper.css"

import { useEffect, useMemo, useState } from "react"
import { observer } from "mobx-react"
import { Checkbox, DatePicker, Divider, Form, Modal, Select, TimePicker } from "antd"
import classNames from "classnames/bind"
import dayjs from "dayjs"
import { useBoolean } from "usehooks-ts"
import mainDictionary from "@/dictionary"
import { addCatchNotification, addSuccessNotification } from "@/modules/notifications"
import { branchOfficeStore } from "@/pages/Controls/BranchOffice"
import { weekDays } from "@/pages/Groups/constants"
import { convertWeekDays } from "@/pages/Groups/Table/util"
import { useStores } from "@/stores"
import { ScheduleItemFormValues } from "@/stores/groups"
import { formatDate, formatHours, TIME_FULL_FORMAT } from "@/utils"

import styles from "./make-schedule.module.scss"

const cn = classNames.bind(styles)

type FormValues = {
  branch: number
  classroomId: number
  periodDate: string[]
  periodTime: string[]
  staffIds: number[]
  weekdays: number[]
}

export const MakeSchedule = observer(() => {
  const { groupsStore, academicStore } = useStores()
  const [form] = Form.useForm()
  const { value: isLoading, setTrue: setLoadingTrue, setFalse: setLoadingFalse } = useBoolean()
  const [officeId, setOfficeId] = useState<number | undefined>()

  const branches = useMemo(
    () =>
      branchOfficeStore.branchOffices?.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    [branchOfficeStore.branchOffices],
  )

  const users = useMemo(
    () =>
      academicStore.academics?.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      })),
    [academicStore.academics],
  )

  const rooms = useMemo(
    () =>
      groupsStore.classRooms?.classrooms?.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    [groupsStore.classRooms],
  )

  const handleBranchChange = (value: number) => {
    form.setFieldValue("classRoomId", null)
    setOfficeId(value)
  }

  const handleCancel = () => {
    groupsStore.setScheduleModal(false)
    groupsStore.setSelectedSchedule(null)
  }

  const handleSuccess = () => {
    handleCancel()
    addSuccessNotification(mainDictionary.successFullySaved)

    if (!groupsStore.groupInfo?.id) return

    groupsStore.setGroupScheduleLoading(true)

    groupsStore.getGroupSchedule({
      page: 1,
      perPage: 100,
      groupId: groupsStore.groupInfo?.id,
    })
  }

  const handleFinish = (values: FormValues) => {
    const { branch, periodDate, periodTime, weekdays, ...data } = values

    const startDate = formatDate(periodDate[0], true)
    const endDate = formatDate(periodDate[1], true)
    const startTime = formatHours(periodTime[0])
    const endTime = formatHours(periodTime[1])
    const formattedWeekDays = weekdays.reduce((acc, prev) => prev + acc)

    if (!groupsStore.groupInfo?.id) return

    const info: ScheduleItemFormValues = {
      ...data,
      groupId: groupsStore.groupInfo?.id,
      beginTime: startTime,
      endTime,
      beginDate: startDate,
      endDate,
      weekdays: formattedWeekDays,
    }

    setLoadingTrue()

    if (groupsStore.selectedSchedule?.id) {
      const editedSchedule = {
        id: groupsStore.selectedSchedule?.id,
        data: info,
      }

      groupsStore
        .editSchedule(editedSchedule)
        .then((res) => {
          if (res.success) {
            handleSuccess()
          }
        })
        .catch(addCatchNotification)
        .finally(() => setLoadingFalse())

      return
    }

    groupsStore
      .addGroupScheduleItem(info)
      .then((res) => {
        if (res.success) {
          handleSuccess()
        }
      })
      .catch(addCatchNotification)
      .finally(() => setLoadingFalse())
  }

  useEffect(() => {
    branchOfficeStore.getBranchOffices()
    academicStore.getAcademics({
      page: 1,
      perPage: 100,
    })
  }, [])

  useEffect(() => {
    if (officeId) {
      groupsStore.getClassRooms({
        page: 1,
        perPage: 100,
        officeId,
      })
    }
  }, [officeId])

  useEffect(() => {
    const selectedSchedule = groupsStore.selectedSchedule

    if (selectedSchedule) {
      const formattedWeekDays = convertWeekDays(selectedSchedule.weekdays).split("-")

      form.setFieldsValue({
        classroomId: selectedSchedule.classroomId,
        staffIds: selectedSchedule.staff?.map((item) => item.id),
        periodDate: [dayjs(selectedSchedule.startDate), dayjs(selectedSchedule.endDate)],
        periodTime: [
          dayjs(selectedSchedule.beginTime, TIME_FULL_FORMAT),
          dayjs(selectedSchedule.endTime, TIME_FULL_FORMAT),
        ],
        weekdays: weekDays.filter((item) => formattedWeekDays.includes(item.label)).map((item) => item.value),
        branch: selectedSchedule.officeId,
      })

      setOfficeId(selectedSchedule.officeId)
    }
  }, [groupsStore.selectedSchedule])

  return (
    <Modal
      width={600}
      onOk={form.submit}
      okText={mainDictionary.save}
      cancelText={mainDictionary.cancel}
      title={mainDictionary.addScheduleToGroup}
      onCancel={handleCancel}
      open={groupsStore.scheduleModal}
      className={cn("schedule")}
      okButtonProps={{ loading: isLoading }}
    >
      <Divider />
      <Form onFinish={handleFinish} form={form}>
        <Form.Item name="branch" label={mainDictionary.branch}>
          <Select
            onChange={handleBranchChange}
            options={branches}
            className={cn("schedule__input")}
            placeholder={mainDictionary.choose}
          />
        </Form.Item>

        <Form.Item
          name="classroomId"
          rules={[{ required: true, message: mainDictionary.shouldFill }]}
          label={mainDictionary.room}
        >
          <Select options={rooms} className={cn("schedule__input")} placeholder={mainDictionary.choose} />
        </Form.Item>

        <Form.Item name="periodDate" label={mainDictionary.period}>
          <DatePicker.RangePicker className={cn("schedule__input")} />
        </Form.Item>

        <Form.Item name="periodTime" label={mainDictionary.lessonTime}>
          <TimePicker.RangePicker format="HH:mm" className={cn("schedule__input")} />
        </Form.Item>

        <Form.Item name="weekdays" label={mainDictionary.weekdays}>
          <Checkbox.Group className={cn("schedule__input")} options={weekDays} />
        </Form.Item>

        <Form.Item name="staffIds" label={mainDictionary.teacher}>
          <Select
            options={users}
            mode="multiple"
            className={cn("schedule__input")}
            placeholder={mainDictionary.choose}
          />
        </Form.Item>
      </Form>
      <Divider />
    </Modal>
  )
})
