import {
  DayType,
  FinanceDiscountsTypes,
  PaymentSegmentedValues,
  PricesUnits,
} from "@/pages/Settings/SettingsFinance/constants"
import { PricesItem } from "@/stores/settings"
import { Course } from "../gamification/types"

export type GamificationOfferingParams = {
  body: string
}

export type GamificationOfferingResponseTypes = {
  body: string
  updatedDate: string
}

export type StudentStatus = {
  id: number
  name: string
  reason: string
  controlActionLimit: number
  statisticsAccess: boolean
  faceAccess: boolean
  startDate?: string
  cabinetAccess: boolean
  isAuto: boolean
  displayName: string
}

export type StudentStatusesRes = {
  total: number
  controlActions: StudentStatus[]
}

export type Attachment = {
  url: string
  size: number
  origName: string
}

export type Contract = {
  id: number
  title: string
  course: Course
  attachment: Attachment
}

export type ContractRes = {
  total: number
  contracts: Contract[]
}

export type ContractHistory = {
  id: number
  url: string
  origName: string
  initiatorName: string
  createdAt: string
}

export type ContractHistoryRes = {
  total: number
  history: ContractHistory[]
}

export type FinanceDiscounts = {
  id?: number
  name: string
  value: number
  createdAt?: string
  type: FinanceDiscountsTypes
}

export type EditFinanceDiscount = {
  id: number
  data: FinanceDiscounts
}

export type AcademicPriceValue = {
  id?: number
  minStudentCount: number
  value: number
  createdAt?: string
}

export type AcademicPrices = {
  studentDayTypes?: DayType[]
  dayTypes?: DayType[]
  academicPriceValues: AcademicPriceValue[]
  id?: number
  name: string
  createdAt?: string
  academicHourId: number
  academicHourName?: string
  academicHourMinutes?: number
  academicHourNotation?: string
}

export type AcademicPriceEdit = {
  id: number
  data: AcademicPrices
}

export type AcademicHoursRes = {
  id: number
  name: string
  minutes: number
  notation: string
  createdAt: string
}

export type SettingsFinancePriceParam = {
  page: number
  perPage: number
  packet?: boolean
  unit?: PricesUnits
}

export type AcademicHoursItem = {
  id: number
  name: string
  notation: string
  minutes: number
  createdAt?: string
}

export type AcademicHoursEdit = {
  id: number
  data: AcademicHoursItem
}

export type SettingsFinanePriceEdit = {
  data: PricesItem
  id: number
}

export type FinancePayments = {
  id?: number
  name: string
  isFixed?: boolean
  integration?: string
  createdAt?: string
}

export type EditFinancePayments = {
  id: number
  data: FinancePayments
  type: PaymentSegmentedValues
}

export type FinancePaymentsData = {
  type: PaymentSegmentedValues
  data: FinancePayments
}

export type DeleteFinancePayments = {
  id: number
  type: PaymentSegmentedValues
}

export type AccessControl = {
  id: number
  name: string
  createdAt: string
  authorId: number
  authorFirstName: string
  authorLastName: string
  staffCount: number
}

export type RolePermission = {
  name: string
  granted: boolean
  id: number
  children: RolePermission[]
}

export type StaffStatus = {
  id: number
  name: string
  isFixed: boolean
  createdAt: string
}

export type StaffStatusesRes = {
  total: number
  statuses: StaffStatus[]
}

export const enum CustomFieldType {
  String = "string",
  Number = "number",
  Boolean = "boolean",
  Array = "array",
  Text = "text",
  Date = "date",
}

export const enum CustomFieldTargetType {
  Student = "student",
  Group = "group",
}

export type CustomField = {
  id: number
  name: string
  type: CustomFieldType
  parameters: string[]
}

export type Courses = {
  id?: number
  name: string
  categoryName?: string
  categoryId: number
  hasStudyMonths?: boolean
  hasCurriculum?: boolean
  createdAt?: string
  imageIllustration?: string
}

export type CoursesRes = {
  courses: Courses[]
  total: number
}

export type CoursesEdit = {
  id: number
  data: Courses
}

export type Categories = {
  id?: number
  name: string
  displayName?: string
  createdAt?: string
}

export type CategoriesRes = {
  categories: Categories[]
  total: number
}

export type CategoriesEdit = {
  id: number
  data: Categories
}

export type LearningPayments = {
  id?: number
  name: string
  createdAt?: string
}

export type LearningPaymentsEdit = {
  id: number
  data: LearningPayments
}

export type Location = {
  id?: number
  name: string
  createdAt?: string
}

export type LocationEdit = {
  id: number
  data: Location
}

export type Expenses = {
  id?: number
  name: string
  createdAt?: string
}

export type ExpensesEdit = {
  id: number
  data: Expenses
}
