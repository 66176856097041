import "./style.css"

import { useEffect, useMemo } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { Flex, Spin, Table, TableProps, Tooltip, Typography } from "antd"
import { HomeworkFilterStatus, JournalAttendedStatus } from "@/api/groups"
import TriangleWithoutBorderImage from "@/assets/img/downloads-without-border.svg?react"
import TriangleImage from "@/assets/img/triangle.svg?react"
import { ROUTES } from "@/constants"
import mainDictionary from "@/dictionary"
import { permissionObjectTypes, PermissionOptions, useCheckPermission } from "@/modules/permission"
import {
  homeworkIconClasses,
  journalAttendanceTableColors,
  journalAttendanceTableContent,
} from "@/pages/Groups/constants"
import { FinalResults } from "@/pages/Groups/types"
import { useStores } from "@/stores"
import { ITotalColumns, JournalTableDays } from "@/stores/groups"
import { useCheckMyGroup } from "@/utils/hooks/useCheckMyGroup"
import { convertSeconds } from "@/utils/secondsToHours"
import { TableTitle } from "./TableTitle"

import styles from "./table.module.scss"

export const JournalTable = observer(() => {
  const { groupsStore } = useStores()
  const { id, staffId, scheduleId } = useParams()
  const navigate = useNavigate()
  const academic = useCheckMyGroup()
  const [isAvailableToSee] = useCheckPermission({
    module: permissionObjectTypes.usersStudents,
    option: PermissionOptions.Read,
  })

  const [isAvailableToCheckAllHomeworks] = useCheckPermission({
    module: permissionObjectTypes.myGroupsCheckHomeworks,
    option: PermissionOptions.Read,
  })

  const [isAvailableToCheckMyHomeworks] = useCheckPermission({
    module: permissionObjectTypes.myGroupsCheckHomeworks,
    option: PermissionOptions.Read,
  })

  const handleClickHomeworkStatus = (lessonId: number, homeworkId: number, chatId: number, userId: number) => {
    if (!chatId || !lessonId || !homeworkId || !userId) return

    console.log("clicked") // TODO

    // const homeworkChatRoute = (academic ? ROUTES.myHomeworkSingleView : ROUTES.homeworkSingleView)
    //   .replace(':id', id!)
    //   .replace(':staffId', staffId!)
    //   .replace(':scheduleId', scheduleId!)
    //   .replace(':lessonId', lessonId.toString())
    //   .replace(':homeworkId', homeworkId.toString())
    //   .replace(':chatId', chatId.toString())
    //   .replace(':senderId', userId.toString())

    // const homeworkChatRouteWithAddition = `${homeworkChatRoute}?journal=true`

    // navigate(homeworkChatRouteWithAddition)
  }

  const formattedTitle = (student: ITotalColumns, index: number, fileType: FinalResults) => {
    const mainRoot = student?.student?.stats?.[index]?.[fileType]

    return `${mainRoot?.value} / ${mainRoot?.total} (${mainRoot?.percentage} %)`
  }

  const tableColumns: TableProps<ITotalColumns>["columns"] = useMemo(() => {
    const columns = groupsStore?.journalDays?.map((journal: JournalTableDays, i: number) => ({
      title: TableTitle.bind(null, journal, isAvailableToCheckAllHomeworks, isAvailableToCheckMyHomeworks),
      render: (item: ITotalColumns) => {
        if (item.student?.stats?.[i]?.attendance) {
          return <h4 className={styles["journal-table__info"]}>{formattedTitle(item, i, FinalResults.Attendance)}</h4>
        }

        if (item.student?.stats?.[i]?.homework) {
          return <h4 className={styles["journal-table__info"]}>{formattedTitle(item, i, FinalResults.Homework)}</h4>
        }

        if (item.student?.firstName === mainDictionary.haveLesson) {
          if (!item?.stats?.stats?.[i]?.attendedCount && journal.title === mainDictionary.attendanceTitle) {
            return <h4 className={styles["journal-table__item"]}>{item.stats?.attendancePercentage || 0} %</h4>
          }

          return <h4 className={styles["journal-table__item"]}>{item?.stats?.stats?.[i]?.attendedCount || "-"}</h4>
        }

        if (!item.student?.stats?.[i] && item.student?.firstName === mainDictionary.acceptHomework) {
          if (!item?.stats?.stats?.[i]?.acceptedCount && journal.title === mainDictionary.homeworkColumn) {
            return <h4 className={styles["journal-table__item"]}>{item.student.homeworkAverage || 0} %</h4>
          }

          if (!item?.stats?.stats?.[i]?.attendedCount && journal.title === mainDictionary.attendanceTitle) {
            return <h4 className={styles["journal-table__item"]}>-</h4>
          }

          return <h4 className={styles["journal-table__item"]}>{item?.stats?.stats?.[i]?.acceptedCount}</h4>
        }

        const newClass = journalAttendanceTableColors[item.student?.stats?.[i]?.attendedStatus!]

        const isAvailable =
          item.student?.stats?.[i]?.attendedStatus !== JournalAttendedStatus.Ended &&
          item.student?.stats?.[i]?.attendedStatus !== JournalAttendedStatus.NoClass

        return (
          <div
            onClick={handleClickHomeworkStatus.bind(
              null,
              item.student?.stats?.[i]?.lessonId!,
              item.student?.stats?.[i]?.homeworkId!,
              item.student?.stats?.[i]?.homeworkChatId!,
              item.student?.userId!,
            )}
          >
            <Tooltip placement="top" title={convertSeconds(item.student?.stats?.[i]?.lateness)}>
              <h4
                className={`${styles[newClass]} ${styles["journal-table__item"]} ${
                  styles[item.student?.stats?.[i]?.attendedStatus === JournalAttendedStatus.Late ? "come-late" : ""]
                }`}
              >
                {journalAttendanceTableContent[item.student?.stats?.[i]?.attendedStatus!] || "-"}
              </h4>
            </Tooltip>

            {isAvailable &&
              (item.student?.stats?.[i]?.homeworkStatus === HomeworkFilterStatus.NotGiven ? (
                <div className={styles["journal-info__icon"]}>
                  {groupsStore.homeworkStatusIcon && <TriangleImage />}
                </div>
              ) : (
                <div>
                  {groupsStore.homeworkStatusIcon && (
                    <TriangleWithoutBorderImage
                      className={`${styles[`journal-info__icon`]} ${
                        styles[homeworkIconClasses[item.student?.stats?.[i]?.homeworkStatus!]]
                      }`}
                    />
                  )}
                </div>
              ))}
          </div>
        )
      },
    }))

    return columns?.length
      ? [
          {
            title: mainDictionary.student,
            render: (item: ITotalColumns) => {
              if (item?.title) {
                return <h4 className={styles["journal-table__item"]}>{item?.title}</h4>
              }

              if (isAvailableToSee && item?.student?.id) {
                const singleStudentRoute = ROUTES.singleStudent.replace(":studentId", item?.student?.id?.toString())
                const singleStudentRouteWithAddition = `${singleStudentRoute}?journal=true`

                return (
                  <Link
                    className={`${styles["journal-table__item"]} ${styles["journal-table__title"]}`}
                    to={singleStudentRouteWithAddition}
                  >
                    {`${item?.student?.firstName} ${item.student?.lastName} ${item.student?.middleName || ""}`}
                  </Link>
                )
              }

              return (
                <h4
                  title={`${item?.student?.firstName} ${item.student?.lastName}`}
                  className={`${styles["journal-table__item"]} ${styles["journal-table__title"]}`}
                >
                  {`${item?.student?.firstName} ${item.student?.lastName} ${item.student?.middleName || ""}`}
                </h4>
              )
            },
            fixed: true,
            position: "left",
          },
          ...columns,
        ]
      : []
  }, [groupsStore.journals, groupsStore.homeworkStatusIcon])

  useEffect(
    () => () => {
      groupsStore.setJournalTable(null)
      groupsStore.setJournals(null)
    },
    [],
  )

  return (
    <div className={`journal ${styles["journal-table-wrapper"]}`}>
      {groupsStore.journalLoader || groupsStore.journalTableLoading ? (
        <Flex justify="center">
          <Spin />
        </Flex>
      ) : groupsStore.journals?.days?.length ? (
        <Table<ITotalColumns>
          className={styles["journal-table"]}
          columns={tableColumns}
          dataSource={groupsStore.journalTable || []}
          bordered
          pagination={false}
          rowClassName={styles["journal-table__row"]}
          loading={groupsStore.journalTableLoading}
        />
      ) : !groupsStore.journals?.days?.length && !groupsStore.journalTableLoading ? (
        <Typography.Title className={styles["journal-table__info"]} level={4}>
          {mainDictionary.teacherIsNotSelected}
        </Typography.Title>
      ) : (
        ""
      )}
    </div>
  )
})
