import { ReactNode } from "react"
import { MenuOutlined } from "@ant-design/icons"
import { Button, Drawer as MuiDrawer, DrawerProps } from "antd"
import classnamesBind from "classnames/bind"
import { useBoolean } from "usehooks-ts"
import { Icons } from "@/components"
import { useStores } from "@/stores"
import { Menu } from "../Menu"

import styles from "./drawer.module.scss"

const cn = classnamesBind.bind(styles)

type Props = {
  title?: string
  placement?: DrawerProps["placement"]
  extraFilterDrawer?: boolean
  children?: ReactNode
}

export const Drawer = ({ title, placement, extraFilterDrawer, children = false }: Props) => {
  const { value: isVisibleDrawer, toggle: toggleVisibleDrawer } = useBoolean(false)
  const { appStore } = useStores()

  return (
    <div className={cn("drawer")}>
      {!extraFilterDrawer && (
        <Button className="menu" type="primary" icon={<MenuOutlined />} onClick={toggleVisibleDrawer} />
      )}

      <MuiDrawer
        title={title || appStore.adminRole}
        placement={placement || "left"}
        onClose={toggleVisibleDrawer}
        open={isVisibleDrawer}
        className={cn("drawer__drawer")}
        extra={
          !extraFilterDrawer && (
            <div className={cn("drawer__icon")}>
              <Icons.Logo width={40} />
            </div>
          )
        }
      >
        {children || <Menu toggleVisibleDrawer={toggleVisibleDrawer} />}
      </MuiDrawer>
    </div>
  )
}
