import { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { MinusOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Flex, Form, Input, Modal, Select } from "antd"
import classNames from "classnames/bind"
import { useBoolean } from "usehooks-ts"
import { CustomFieldTargetType, CustomFieldType } from "@/api/settings"
import mainDictionary from "@/dictionary"
import { addCatchNotification, addSuccessNotification } from "@/modules/notifications"
import { useStores } from "@/stores"
import { customFieldTypes } from "../../constants"

import styles from "./make-modal.module.scss"

const cn = classNames.bind(styles)

type Parameter = {
  value: string
}

type FormValues = {
  name: string
  type: string
  parameters: Parameter[]
}

export const MakeModal = observer(() => {
  const { settingsStore } = useStores()
  const [selectedType, setSelectedType] = useState<CustomFieldType | null>(null)
  const [form] = Form.useForm()
  const { value: isLoading, setTrue: setLoadingTrue, setFalse: setLoadingFalse } = useBoolean()

  const handleCancel = () => {
    settingsStore.setMakeCustomFieldModal(false)
    settingsStore.setSelectedCustomField(null)
  }

  const handleTypeChange = (value: CustomFieldType) => {
    setSelectedType(value)
  }

  const handleSuccess = () => {
    settingsStore.setCustomFieldsLoading(true)
    settingsStore.getCustomFields({ target: settingsStore.customFieldsTarget })

    addSuccessNotification(mainDictionary.successFullySaved)

    handleCancel()
  }

  const handleFinish = (values: FormValues) => {
    setLoadingTrue()

    const { parameters, ...data } = values

    const info = {
      ...data,
      parameters: parameters?.length ? parameters?.map((item) => item.value) : null,
      target: settingsStore.customFieldsTarget as CustomFieldTargetType,
    }

    if (settingsStore.selectedCustomField) {
      settingsStore
        .editCustomField({ id: settingsStore.selectedCustomField.id, data: info })
        .then((res) => {
          if (res.success) {
            handleSuccess()
          }
        })
        .catch(addCatchNotification)
        .finally(() => setLoadingFalse())

      return
    }

    settingsStore
      .addStudentCustomField(info)
      .then((res) => {
        if (res.success) {
          handleSuccess()
        }
      })
      .catch(addCatchNotification)
      .finally(() => setLoadingFalse())
  }

  useEffect(() => {
    const selectedCustomField = settingsStore.selectedCustomField

    if (selectedCustomField) {
      form.setFieldsValue({
        name: selectedCustomField.name,
        type: selectedCustomField.type,
        parameters: selectedCustomField.parameters?.map((item) => ({ value: item })),
      })

      setSelectedType(selectedCustomField.type)
    }
  }, [settingsStore.selectedCustomField])

  return (
    <Modal
      okText={mainDictionary.save}
      cancelText={mainDictionary.cancel}
      title={mainDictionary.addNewField}
      onCancel={handleCancel}
      onOk={form.submit}
      className={cn("make-modal")}
      open={settingsStore.makeCustomFieldModal}
      okButtonProps={{ loading: isLoading }}
    >
      <Form onFinish={handleFinish} form={form} initialValues={{ parameters: [{}] }} layout="vertical">
        <Form.Item
          rules={[{ required: true, message: mainDictionary.shouldFill }]}
          name="name"
          label={mainDictionary.name}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={mainDictionary.fieldType}
          name="type"
          rules={[{ required: true, message: mainDictionary.shouldFill }]}
        >
          <Select onChange={handleTypeChange} options={customFieldTypes} />
        </Form.Item>

        {selectedType === CustomFieldType.Array && (
          <Form.List name="parameters">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Flex align="center" gap={10} key={field.key}>
                    <Form.Item
                      label={mainDictionary.value}
                      name={[field.name, "value"]}
                      className={cn("make-modal__input")}
                      rules={[{ required: true, message: mainDictionary.shouldFill }]}
                    >
                      <Input />
                    </Form.Item>

                    <Button onClick={() => remove(field.name)}>
                      <MinusOutlined />
                    </Button>
                  </Flex>
                ))}
                <Button block onClick={add} icon={<PlusOutlined />} />
              </>
            )}
          </Form.List>
        )}
      </Form>
    </Modal>
  )
})
