import { AxiosProgressEvent, ResponseType } from "axios"
import { Upload } from "tus-js-client"
import {
  AdditionalStats,
  ExamChatsStatus,
  GeneralStats,
  HomeworkChatsStatus,
  IGroupStatus,
  IVideo,
  JournalDay,
  JournalStats,
  JournalStudent,
} from "@/api/groups"
import { IUploadData } from "@/api/upload"

export enum OrderBy {
  Asc = "ASC",
  Desc = "DESC",
}

export type AcademicVoiceFiles = {
  size?: number
  url: string
  origName: string
}

export type ColumnSettings = {
  label: string
  value: number
  isChecked?: boolean
}

export type PriceRequest = {
  page: number
  perPage: number
}

export type GroupMakeFormValues = {
  name: string
  officeId: number
  categoryId: number
  courseId: number
  assignee_id: number
  priceId: number
  studentsCount: number
  status: number
  learningTypeId: number
  description?: string
}

export type GroupEditParams = {
  id: number
  data: GroupMakeFormValues
}

export interface IGetGroupsRequest {
  page?: number
  perPage?: number
  keys?: string[]
  value?: string
  sortBy?: string
  orderBy?: OrderBy
  fromByStartedDate?: string
  toByStartedDate?: string
  fromByEndedDate?: string | null
  toByEndedDate?: string | null
  status?: string | IGroupStatus
  id?: number
  officeIds?: number[]
  name?: string
  kpiDate?: string
  categoryId?: number
  courseId?: number
  teacherId?: number
  learningTypeId?: number
}

export const enum GroupByOrder {
  Asc = "ASC",
  Desc = "DESC",
}

export type GroupDownloadOptionType = {
  responseType: ResponseType
  onDownloadProgress: OnDownloadProgressType
}

export type OnDownloadProgressType = (e: AxiosProgressEvent) => void

export type GetGroupSchedulesReq = {
  page: number
  perPage: number
  groupId: number
}

export type GetClassRoomsReq = {
  page: number
  perPage: number
  officeId?: number
}

export type ScheduleItemFormValues = {
  groupId: number
  staffIds: number[]
  startDate?: string
  beginDate?: string
  endDate: string
  beginTime: string
  endTime: string
  weekdays: number
  classroomId: number
}

export type EditScheduleReq = {
  id: number
  data: ScheduleItemFormValues
}

export type GetGroupStudentsReq = {
  id: number
  page: number
  perPage: number
  scheduleItemId: number
  studentId?: number
  statusId?: number
  dateFrom?: string
  dateTo?: string
  key?: string
}

export type GetStudentSchemaReq = {
  id: number
  page: number
  perPage: number
  studentId: number
}

export type GetTeacherScheduleSchemaReq = {
  id: number
  page: number
  perPage: number
  academicId: number
}

export type GetStudentDuplicationsReq = {
  page: number
  perPage: number
  officeId?: number
}

export type DuplicationsActionReq = {
  primaryStudentId: number
  secondaryStudentId: number
}

export interface IGetVideosRequest {
  page?: number
  perPage?: number
  keys?: string[]
  value?: string | null
  sortBy?: string
  orderBy?: GroupByOrder
  groupId: number
}

export type VideoFormType = {
  lessonId: number
  origName: string
}

export interface IUpdateVideoRequest {
  id: number
  data: {
    lessonId?: number
    origName: string
  }
}

export interface IGetLessonsRequest {
  id: string
  page?: string
  perPage?: string
  keys?: string[]
  value?: string
  sortBy?: string
  orderBy?: GroupByOrder
  haveNotHomework?: boolean
  staffId?: string
  createdFrom?: string
  createdTo?: string
}

export type UploadType = {
  upload: Upload
  video: IVideo
  paused: boolean
  error: boolean
}

export type GetHomeworkReq = {
  id?: number
  page: number
  perPage: number
}

export type GetExamsReq = {
  page: number
  perPage: number
  id?: number
}

export interface IGetHomeworkUploadUrl {
  fileOrigName: string
}

export type MakeExamInfo = {
  lessonId?: number
  desc: string
  attachments: IUploadData[]
  deadline: string
}

export type EditExamInfo = {
  id: number
  data: MakeExamInfo
}

export interface IGetJournalsReq {
  groupId: number
  startDate?: string
  endDate?: string
  signal?: AbortSignal
}

export interface JournalTableData {
  date: JournalDay[]
  student: JournalStudent
  stats: AdditionalStats[]
  firstName?: string
  homeworkId: number
  lessonId?: number
}

export interface JournalTableDays extends JournalDay {
  title?: string
}

type AdditionalOverAllStats = {
  attendance?: GeneralStats
  homework?: GeneralStats
  acceptedCount?: number
  attendedCount?: number
}

type FixedStudentStats = JournalStats | AdditionalOverAllStats

export interface ITotalColumns {
  date?: JournalDay[]
  stats?: {
    attendancePercentage?: number
    homeworkPercentage?: number
    stats?: FixedStudentStats[]
  }
  student: JournalStudent
  homeworkAverage?: number
  total?: number
  title?: string
}

export interface ITotalData {
  attendance?: GeneralStats
  firstName: string
  homework?: GeneralStats
  id?: number
  lastName: string
  middleName?: string
  stats?: any
  attendanceAverage?: number
  homeworkAverage?: number
}

export interface IGetHomeworksData {
  id: number
  page?: number
  perPage?: number
  keys?: string[]
  value?: string
  homeworkId?: number
  status?: HomeworkChatsStatus | string
  studentUserId?: number
  orderBy?: OrderBy
  lessonId?: number
}

export interface IGetHomeworks {
  id: number
  data: IGetHomeworksData
}

export type IGetIncompleteStudentsRequest = {
  groupId: number
  homeworkId: number
}

export type GetExamChatsReq = {
  id: number
  page: number
  perPage: number
  status?: ExamChatsStatus | string
}

export type AcademicPrices = {
  id: number
  from?: string | null
}

export type ChangeAcademicHourParams = {
  academicId: number
  academicPrices: AcademicPrices[]
  id: number
}

export type GetGroupAcademicHours = {
  id: number
  academicId: number
}

export type GetTeacherAttendanceReq = {
  id?: number
  page: number
  perPage: number
}
