import { observer } from "mobx-react"
import { Modal } from "antd"
import { stage } from "@/api/endpoints"
import { useStores } from "@/stores"
import { Player } from "./player"

export const PlayerModal = observer(() => {
  const { groupsStore } = useStores()
  const { isVideoPlayerOpen, selectedVideo, setSelectedVideo, setIsVideoPlayerOpen } = groupsStore
  const url = `${stage?.cdnHost}/${selectedVideo?.url!}`

  const handleCancel = () => {
    setSelectedVideo(null)
    setIsVideoPlayerOpen(false)
  }

  return (
    <Modal
      open={isVideoPlayerOpen}
      onCancel={handleCancel}
      footer={null}
      centered
      title={selectedVideo?.lesson?.name}
      style={{ width: 100, minWidth: 100 }}
    >
      <Player
        url={url}
        controls
        width="100%"
        height="100%"
        muted
        playing
        config={{ file: { attributes: { controlsList: "nodownload" } } }}
      />
    </Modal>
  )
})
