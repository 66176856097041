import { observer } from "mobx-react"
import { Flex, Modal, Typography } from "antd"
import classNames from "classnames/bind"
import mainDictionary from "@/dictionary"
import { useStores } from "@/stores"
import { downloadUrl } from "@/utils/getHlsVideoLink"

import styles from "./homework-info.module.scss"

const cn = classNames.bind(styles)

export const HomeworkInfo = observer(() => {
  const { groupsStore } = useStores()

  const handleCancel = () => {
    groupsStore.setHomeworkInfoModal(false)
    groupsStore.setHomeworkInfo(null)
  }

  return (
    <Modal title={mainDictionary.homework} onCancel={handleCancel} open={groupsStore.homeworkInfoModal}>
      <Flex vertical>
        <Typography.Title level={5}>{mainDictionary.homeworkDesc}:</Typography.Title>
        <p dangerouslySetInnerHTML={{ __html: groupsStore.homeworkInfo?.desc || "" }} />
      </Flex>

      <div>
        <Typography.Title level={5}>{mainDictionary.files}:</Typography.Title>
        <div>
          {groupsStore.homeworkInfo?.attachments?.length
            ? groupsStore.homeworkInfo?.attachments.map((item) => (
                <a
                  className={cn("homework-view__file")}
                  href={downloadUrl(item?.url)}
                  key={item.origName}
                  download={downloadUrl(item.url)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.origName}
                </a>
              ))
            : "-"}
        </div>
      </div>
    </Modal>
  )
})
