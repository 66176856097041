import { StaffData } from "@/pages/Users/Staff/types"
import { IStaff } from "../gamification/types"
import { Student } from "../groups/types"
import { IPagination } from "../types"

export interface IStaffParams extends IPagination {
  keys?: string[]
  value?: string
  orderBy?: string
  isActive?: boolean | null
  workStatus?: number | null
  roleId?: number | null
  officeIds?: number[]
  staffSystemStatus?: number | null
  staffName?: string
}

export interface IStaffRolesParams extends IPagination {
  keys?: string[]
  value?: string
  orderBy?: string
  sortBy?: string
  type?: number
  officeIds?: number[]
}

export interface IStaffRoles {
  roles: StaffRolesResponse[]
  total: number
}

export interface StaffRolesResponse {
  id: number
  name: string
  createdAt: string
  author: any | null
  staffCount: number
  type: number
}

export interface StaffResponse {
  total: number
  staff: StaffData[]
}

export interface SingleStaffParams {
  id: number
}

export interface ResetPassword {
  password: string
}

export interface UpdateResponse {
  success: boolean
}

export enum EnterTypes {
  ENTER = 1,
  EXIT,
  InOffice,
}

export enum RoleTypes {
  STAFF = 1,
  ACADEMIC,
  STUDENT,
}

export interface ActivityLog {
  [key: string]: any
  id: number
  enterType: EnterTypes
  actionImageUrl: string
  branch: string
  user: ActivityLogUser
  createdAt: string
  office: UserOffice
  deviceName: string
}

type UserOffice = {
  id: number
  name: string
  hOffice: null
}

export interface ActivityLogUser {
  student: Student
  staff: IStaff
}

export interface IFaceIdLogsResponse {
  activityLogs: ActivityLog[]
  total: number
}
