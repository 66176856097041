import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { EditOutlined } from "@ant-design/icons"
import { Button, Flex, Spin, Typography } from "antd"
import classNames from "classnames/bind"
import mainDictionary from "@/dictionary"
import { useStores } from "@/stores"

import styles from "./parameters.module.scss"

const cn = classNames.bind(styles)

export const Parameters = observer(() => {
  const { groupsStore } = useStores()
  const { id } = useParams()

  const handleEdit = () => {
    groupsStore.setGroupMakeModal(true)
  }

  useEffect(() => {
    if (id) {
      const parsedId = parseInt(id, 10)

      groupsStore.setGroupParametersLoading(true)
      groupsStore.getGroupParameters(parsedId)
    }
  }, [])

  if (groupsStore.groupParametersLoading)
    return (
      <Flex className={cn("parameters")} justify="center" align="center">
        <Spin />
      </Flex>
    )

  return (
    <div className={cn("parameters")}>
      <Flex vertical gap={24}>
        <Flex justify="space-between" align="center">
          <Typography.Title level={4}>{mainDictionary.parameters}</Typography.Title>

          <Button onClick={handleEdit} className={cn("parameters__button")} type="text" icon={<EditOutlined />}>
            {mainDictionary.edit}
          </Button>
        </Flex>
        <Flex vertical gap={20}>
          <div className={cn("parameters__wrapper")}>
            <Typography.Text className={cn("parameters__text")}>{mainDictionary.branch}:</Typography.Text>
            <Typography.Text className={cn("parameters__price")}>
              {groupsStore.groupParameters?.officeName}
            </Typography.Text>
          </div>

          <div className={cn("parameters__wrapper")}>
            <Typography.Text className={cn("parameters__text")}>{mainDictionary.course}:</Typography.Text>
            <Typography.Text className={cn("parameters__title")}>
              {groupsStore.groupParameters?.courseName}
            </Typography.Text>
          </div>

          <div className={cn("parameters__wrapper")}>
            <Typography.Text className={cn("parameters__text")}>{mainDictionary.type}:</Typography.Text>
            <Typography.Text className={cn("parameters__title")}>
              {groupsStore.groupParameters?.learningTypeName}
            </Typography.Text>
          </div>

          <div className={cn("parameters__wrapper")}>
            <Typography.Text className={cn("parameters__text")}>{mainDictionary.category}:</Typography.Text>
            <Typography.Text className={cn("parameters__title")}>
              {groupsStore.groupParameters?.categoryName}
            </Typography.Text>
          </div>

          <div className={cn("parameters__wrapper")}>
            <Typography.Text className={cn("parameters__text")}>{mainDictionary.paymentType}:</Typography.Text>
            <Typography.Text className={cn("parameters__title")}>
              {groupsStore.groupParameters?.priceName}
            </Typography.Text>
          </div>

          <div className={cn("parameters__wrapper")}>
            <Typography.Text className={cn("parameters__text")}>{mainDictionary.averageAge}</Typography.Text>
            <Typography.Text className={cn("parameters__title")}>
              {groupsStore.groupParameters?.averageAge}
            </Typography.Text>
          </div>

          <div className={cn("parameters__wrapper")}>
            <Typography.Text className={cn("parameters__text")}>{mainDictionary.capacityOfStudents}:</Typography.Text>
            <Typography.Text className={cn("parameters__title")}>
              {groupsStore.groupParameters?.capacity}
            </Typography.Text>
          </div>

          <div className={cn("parameters__wrapper")}>
            <Typography.Text className={cn("parameters__text")}>{mainDictionary.existantStudent}:</Typography.Text>
            <Typography.Text className={cn("parameters__title")}>
              {groupsStore.groupParameters?.studentsCount}
            </Typography.Text>
          </div>

          <div className={cn("parameters__wrapper")}>
            <Typography.Text className={cn("parameters__text")}>{mainDictionary.contracts}:</Typography.Text>
            <Typography.Text className={cn("parameters__title")}>
              {groupsStore.groupParameters?.contractsCount}
            </Typography.Text>
          </div>

          <div className={cn("parameters__wrapper")}>
            <Typography.Text className={cn("parameters__text")}>{mainDictionary.paidStudents}</Typography.Text>
            <Typography.Text className={cn("parameters__title")}>
              {groupsStore.groupParameters?.totalPaidCount}
            </Typography.Text>
          </div>
        </Flex>
      </Flex>
    </div>
  )
})
