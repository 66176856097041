import { useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { Avatar, Image, Table, TableProps } from "antd"
import classNames from "classnames/bind"
import { TeacherAttendance as TeacherAttendanceType } from "@/api/groups"
import mainDictionary from "@/dictionary"
import { getPaginationParams } from "@/pages/utils"
import { useStores } from "@/stores"
import { formatDate, getRowIndex, makeFileUrl } from "@/utils"

import styles from "./teacher-attendance.module.scss"

const cn = classNames.bind(styles)

export const TeacherAttendance = observer(() => {
  const { groupsStore } = useStores()
  const { id } = useParams()

  const columns: TableProps<TeacherAttendanceType>["columns"] = useMemo(
    () => [
      {
        title: mainDictionary.order,
        width: 100,
        render: (item: TeacherAttendanceType, record: TeacherAttendanceType, index: number) => (
          <span>
            {getRowIndex(
              groupsStore.teacherAttendancePagination.page,
              groupsStore.teacherAttendancePagination.perPage,
              index,
            )}
          </span>
        ),
      },
      {
        title: mainDictionary.topic,
        render: (item: TeacherAttendanceType) => <span>{item.lessonName || "-"}</span>,
      },
      {
        title: mainDictionary.teacherPhoto,
        width: 140,
        align: "center",
        render: (item: TeacherAttendanceType) => (
          <Avatar size={48} src={<Image src={makeFileUrl(item.photo)} alt={item.lessonName} />} />
        ),
      },
      {
        width: 120,
        align: "center",
        title: mainDictionary.lessonTime,
        render: (item: TeacherAttendanceType) => (
          <span>
            {item.beginTime?.slice(0, 5)} - {item.endTime?.slice(0, 5)}
          </span>
        ),
      },
      {
        width: 200,
        align: "center",
        title: mainDictionary.photoTime,
        render: (item: TeacherAttendanceType) => <span>{formatDate(item.createdAt, undefined, true)}</span>,
      },
    ],
    [],
  )

  const handlePaginationChange = (page: number, perPage: number) => {
    groupsStore.setTeacherAttendancePagination({
      ...groupsStore.teacherAttendancePagination,
      page,
      perPage,
    })
  }

  useEffect(() => {
    if (!id) return
    const parsedId = parseInt(id, 10)

    groupsStore.setTeacherAttendanceLoading(true)
    groupsStore.getTeacherAttendance({ id: parsedId, ...groupsStore.teacherAttendancePagination })
  }, [groupsStore.teacherAttendancePagination])

  return (
    <Table
      className={cn("teacher-attendance")}
      columns={columns}
      loading={groupsStore.teacherAttendanceLoading}
      dataSource={groupsStore.teacherAttendance?.attendances}
      pagination={{
        ...getPaginationParams({
          total: groupsStore.teacherAttendance?.total,
          onChange: handlePaginationChange,
        }),
      }}
    />
  )
})
