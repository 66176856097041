export enum SingleBranchTabs {
  Info = 1,
  Staff,
  Rooms,
}

export type AddRoomType = {
  name: string
  officeId?: number
}

export type EditRoomType = {
  id: number
  data: AddRoomType
}
