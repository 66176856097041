import { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { Button, Checkbox, Divider, Flex, Modal, Typography } from "antd"
import { CheckboxChangeEvent } from "antd/es/checkbox"
import classNames from "classnames/bind"
import { useBoolean } from "usehooks-ts"
import mainDictionary from "@/dictionary"
import { useStores } from "@/stores"
import { ColumnSettings } from "@/stores/groups"
import { COLUMN_SETTINGS, groupColumns } from "../constants"

import styles from "./column-settings.module.scss"

const cn = classNames.bind(styles)

export const ColumnsSettings = observer(() => {
  const { groupsStore } = useStores()
  const { value: isLoading, setTrue: setIsLoading, setFalse: setIsNotLoading } = useBoolean(false)
  const [columnValues, setColumnValues] = useState<ColumnSettings[]>([])

  const handleCancel = () => {
    groupsStore.setColumnsSettingsModal(false)
  }

  const handleSuccessCancel = () => {
    setTimeout(() => {
      setIsNotLoading()

      handleCancel()
    }, 10)
  }

  const handleSave = () => {
    setIsLoading()
    const newColumnValues = JSON.stringify(columnValues)

    window.localStorage.setItem(COLUMN_SETTINGS, newColumnValues)
    groupsStore.setColumnsSettingsValues(columnValues)

    handleSuccessCancel()
  }

  const handleReset = () => {
    setIsLoading()

    const newColumnValues = JSON.stringify(groupColumns)

    window.localStorage.setItem(COLUMN_SETTINGS, newColumnValues)
    groupsStore.setColumnsSettingsValues(columnValues)

    handleSuccessCancel()
  }

  const handleChange = (value: number, e: CheckboxChangeEvent) => {
    setColumnValues((prev) =>
      prev.map((item) => {
        if (item.value === value) {
          return { ...item, isChecked: e.target.checked }
        }

        return item
      }),
    )
  }

  useEffect(() => {
    if (groupsStore.columnsSettingsValues) {
      setColumnValues(groupsStore.columnsSettingsValues)
    }
  }, [])

  return (
    <Modal
      title={mainDictionary.columnSettingModalTitle}
      onCancel={handleCancel}
      open={groupsStore.columnsSettingsModal}
      footer={null}
      width={500}
      className={cn("column-settings")}
    >
      <Flex vertical gap={20}>
        <Flex justify="space-between" align="center">
          <Typography.Title level={5}>{mainDictionary.name}</Typography.Title>
          <Typography.Title level={5}>{mainDictionary.permission}</Typography.Title>
        </Flex>

        <Flex justify="space-between" align="center">
          <Flex className={cn("column-settings__wrapper")} gap={10} vertical>
            {groupsStore.columnsSettingsValues?.map((item) => (
              <Flex key={item.value} justify="space-between" align="center">
                <Typography.Title level={5}>{item.label}</Typography.Title>

                <Checkbox onChange={handleChange.bind(null, item.value)} defaultChecked={item.isChecked} />
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>

      <Divider />

      <Flex justify="space-between">
        <Button onClick={handleReset} loading={isLoading}>
          {mainDictionary.resetColumns}
        </Button>

        <Flex gap={20}>
          <Button onClick={handleCancel}>{mainDictionary.cancel}</Button>
          <Button loading={isLoading} onClick={handleSave} type="primary">
            {mainDictionary.save}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
})
