import { observer } from "mobx-react"
import { Flex, Form, Select } from "antd"
import classNames from "classnames/bind"

import styles from "./filter.module.scss"
const cn = classNames.bind(styles)

import { useEffect, useMemo } from "react"
import mainDictionary from "@/dictionary"
import { branchOfficeStore } from "@/pages/Controls/BranchOffice"
import { useStores } from "@/stores"
import { allOption } from "../../constants"

export const Filter = observer(() => {
  const { groupsStore } = useStores()

  const branchOptions = useMemo(() => {
    const offices = branchOfficeStore.branchOffices?.map((branch) => ({
      label: branch.name,
      value: branch.id,
    }))

    return [allOption, ...(offices || [])]
  }, [branchOfficeStore.branchOffices])

  const handleBranchChange = (value: number) => {
    groupsStore.setStudentDuplicationsFilterOptions({
      ...groupsStore.studentDuplicationsFilterOptions,
      officeId: value,
    })
  }

  useEffect(() => {
    branchOfficeStore.getBranchOffices()
  }, [])

  return (
    <Form layout="vertical">
      <Flex className={cn("filter")} gap={20}>
        <Form.Item label={mainDictionary.branch}>
          <Select
            options={branchOptions}
            placeholder={mainDictionary.branch}
            className={cn("filter__input")}
            onChange={handleBranchChange}
          />
        </Form.Item>
      </Flex>
    </Form>
  )
})
