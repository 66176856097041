import { TableProps, Tag, TagProps, Tooltip } from "antd"
import classNames from "classnames/bind"
import dayjs from "dayjs"
import {
  ExamStatus,
  HomeworkFilterStatus,
  IGroupStatus,
  IVideo,
  JournalAttendedStatus,
  MatchType,
  VideoStatusHistory,
} from "@/api/groups"
import { IOption, ROUTES } from "@/constants"
import mainDictionary from "@/dictionary"
import { VideoStatus } from "@/modules/kinescope"
import { SortStatistic } from "@/pages/Gamification/types"
import { IBreadcrumb } from "@/stores/breadcrumb/types"
import { Orders } from "@/stores/gamification/types"
import { DATE_FORMAT_WITH_HOUR, formatDate, getFormattedFullDateFromParams } from "@/utils/date"
import { formatBytes } from "@/utils/formatBytes"
import { VideoName, VideoStat } from "./GroupSingle/Tabs/Videos/ColumnItems"
import { VideoActions } from "./GroupSingle/Tabs/Videos/ColumnItems/VideoActions"
import { GroupColumns, HomeworkChatsStatus, HomeworkStatistics } from "./types"

import styles from "./groups.module.scss"
const cn = classNames.bind(styles)

export const videoConstraints = {
  width: 420,
  height: 420,
  facingMode: "user",
}

export const tableSizes = {
  x: 1500,
  y: 500,
}

export const imgFormat = "image/jpeg"

export const groupStatus = [
  {
    value: null,
    label: mainDictionary.all,
  },
  {
    value: IGroupStatus.NotStarted,
    label: mainDictionary.notStarted,
  },
  {
    value: IGroupStatus.Active,
    label: mainDictionary.active,
  },
  {
    value: IGroupStatus.Ended,
    label: mainDictionary.ended,
  },
]

export const videoStatusText: Record<VideoStatus, string> = {
  [VideoStatus.Processing]: mainDictionary.convertedFileInKineskope,
  [VideoStatus.Done]: mainDictionary.convertedFileUpload,
  [VideoStatus.Error]: mainDictionary.error,
  [VideoStatus.Pause]: mainDictionary.pause,
  [VideoStatus.Uploading]: mainDictionary.uploading,
  [VideoStatus.Waiting]: mainDictionary.uploadWaiting,
}

export const tagColor: Record<VideoStatus, TagProps["color"]> = {
  [VideoStatus.Processing]: "blue",
  [VideoStatus.Done]: "green",
  [VideoStatus.Error]: "red",
  [VideoStatus.Pause]: "yellow",
  [VideoStatus.Uploading]: "blue",
  [VideoStatus.Waiting]: "yellow",
}

export const homeworkStatusTagColors: Record<number, TagProps["color"]> = {
  [HomeworkChatsStatus.ACCEPTED]: "green",
  [HomeworkChatsStatus.REJECTED]: "red",
  [HomeworkChatsStatus.WAITING]: "yellow",
  [HomeworkChatsStatus.NotDone]: "yellow",
}

export const homeworkStatusTagTexts: Record<string, TagProps["color"]> = {
  [HomeworkChatsStatus.ACCEPTED]: mainDictionary.approved,
  [HomeworkChatsStatus.REJECTED]: mainDictionary.rejects,
  [HomeworkChatsStatus.WAITING]: mainDictionary.wait,
  [HomeworkChatsStatus.NotDone]: mainDictionary.wait,
}

export const DidNotDoHomework = "4"

const newDate = new Date()

export const currentDate = formatDate(newDate, true)

export const currentFormattedDate = formatDate(newDate, true)

export const lessonTimeFormatter = (time: string) => time?.split(":").slice(0, 2).join(":")

export const groupsBreadCrummb: IBreadcrumb[] = [{ label: mainDictionary.groups }]

export const groupsSingleBreadCrummb: IBreadcrumb[] = [{ label: mainDictionary.groups, link: ROUTES.groups }]

export const MAX_SCORE = 100
export const UNIQUE_KEY = "UNIQUE_KEY"
export const ERROR_NOTIFICATION_UNIQUE_KEY = "ERROR_NOTIFICATION_UNIQUE_KEY"
export const dayWeeks = ["Ya", "Du", "Se", "Ch", "Pay", "Ju", "Sha"]

export const formattedTypes = {
  ascend: Orders.Ascend,
  descend: Orders.Descend,
}

export const formattedTitles = {
  Level: SortStatistic.LEVEL,
  XP: SortStatistic.XP,
  Kumush: SortStatistic.COIN,
}

export const INVALID = "invalid"

export const allOption = {
  label: mainDictionary.allOfThem,
  value: null,
}

export const homeworkStudentStatisticsFilterOption = [
  {
    value: null,
    label: mainDictionary.allOfThem,
  },
  {
    value: HomeworkChatsStatus.ACCEPTED,
    label: mainDictionary.acceptedHomework,
  },
  {
    value: HomeworkChatsStatus.REJECTED,
    label: mainDictionary.rejectedHomework,
  },
  {
    value: HomeworkChatsStatus.WAITING,
    label: mainDictionary.waiters,
  },
  {
    value: HomeworkChatsStatus.NotDone,
    label: mainDictionary.notDone,
  },
  {
    value: HomeworkChatsStatus.NotGiven,
    label: mainDictionary.byNotGiven,
  },
]

export const homeworkStatisticsFilterOption: IOption[] = [
  {
    value: HomeworkStatistics.AverageScore,
    label: <span>{mainDictionary.byScore}</span>,
    className: "option-st",
  },
  {
    value: HomeworkStatistics.RejectedHomeworkCount,
    className: "option-st",
    label: <span className="rejected-st normalize-option-st">{mainDictionary.byRejected}</span>,
  },
  {
    value: HomeworkStatistics.UncompletedHomeworkCount,
    className: "option-st",
    label: <span className="normalize-option-st uncompleted-st">{mainDictionary.byNotDone}</span>,
  },
  {
    label: <span className="accepted-st normalize-option-st">{mainDictionary.byAccepted}</span>,
    value: HomeworkStatistics.AcceptedHomeworkCount,
    className: "option-st",
  },
  {
    value: HomeworkStatistics.WaitingHomework,
    className: "option-st",
    label: <span className="normalize-option-st waiter-st">{mainDictionary.byWaiting}</span>,
  },
]

export const studentHomeworkStatisticsFilterOption: IOption[] = [
  {
    value: HomeworkChatsStatus.ACCEPTED,
    className: "option-st",
    label: <span>{mainDictionary.byAccepted}</span>,
  },
  {
    value: HomeworkChatsStatus.REJECTED,
    className: "option-st",
    label: <span className={`rejected 'normalize-option`}>{mainDictionary.byRejected}</span>,
  },
  {
    value: HomeworkChatsStatus.WAITING,
    className: "option-st",
    label: <span>{mainDictionary.waitingForChecking}</span>,
  },
  {
    value: HomeworkChatsStatus.NotDone,
    className: "option-st",
    label: <span>{mainDictionary.byNotDone}</span>,
  },
  {
    value: HomeworkChatsStatus.NotGiven,
    label: mainDictionary.byNotGivenHw,
  },
]

export const studentSortOptions = [allOption, ...studentHomeworkStatisticsFilterOption]

export const calendarSettings = {
  lang: {
    locale: "ru",
    placeholder: "Select date",
    rangePlaceholder: ["Start date", "End date"],
    today: "Today",
    now: "Now",
    backToToday: "Back to today",
    ok: "OK",
    clear: "Clear",
    month: "Month",
    year: "Year",
    timeSelect: "Select time",
    dateSelect: "Select date",
    monthSelect: "Choose a month",
    yearSelect: "Choose a year",
    decadeSelect: "Choose a decade",
    yearFormat: "YYYY",
    dateFormat: "M/D/YYYY",
    dayFormat: "D",
    dateTimeFormat: "M/D/YYYY HH:mm:ss",
    monthFormat: "MMMM",
    monthBeforeYear: true,
    previousMonth: "Previous month (PageUp)",
    nextMonth: "Next month (PageDown)",
    previousYear: "Last year (Control + left)",
    nextYear: "Next year (Control + right)",
    previousDecade: "Last decade",
    nextDecade: "Next decade",
    previousCentury: "Last century",
    nextCentury: "Next century",
    shortWeekDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  },
  timePickerLocale: {
    placeholder: "Select time",
  },
  dateFormat: "YYYY-MM-DD",
  dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
  weekFormat: "YYYY-wo",
  monthFormat: "YYYY-MM",
}

export const isAcademic = "my-groups"

export const PER_ATTENDANCE_GET_SECOND = 30000

export const VALID_EXAM_SCORE = /^([1-9]|[1-9][0-9]|100)$/
export const studentColumn = {
  name: mainDictionary.student,
}

export const curriculumDate = (date: string) => dayjs(date).format("DD.MM")
export const disabledDate = (current: dayjs.Dayjs | null) => {
  if (!current) {
    return false
  }

  return current.isAfter(dayjs().endOf("month")) || current.isBefore(dayjs().subtract(4, "days").startOf("day"))
}

export const formattedTime = (time: string): string => time.slice(0, 2)

export const formattedNumber = (index: number) => index.toString().padStart(2, "0")

export const checkingExamScore = /^(?:100(?:\.0{1,2})?|\d{1,2}(?:\.\d{1,2})?|0(?:\.[0-9]{1,2})?)$/

export const fillHour = (time: number) => {
  if (!time) {
    return
  }

  return time.toString().padStart(2, "0")
}

export const imageExtensions = ["jpeg", "jpg", "png", "webp"]

export const groupColumns = [
  {
    label: mainDictionary.groups,
    value: GroupColumns.Groups,
    isChecked: true,
  },
  {
    label: mainDictionary.branch,
    value: GroupColumns.Branch,
    isChecked: true,
  },
  {
    label: mainDictionary.course,
    value: GroupColumns.Course,
    isChecked: true,
  },
  {
    label: mainDictionary.category,
    value: GroupColumns.Category,
    isChecked: true,
  },
  {
    label: mainDictionary.schedule,
    value: GroupColumns.Schedule,
    isChecked: true,
  },
  {
    label: mainDictionary.teachers,
    value: GroupColumns.Teachers,
    isChecked: true,
  },
  {
    label: mainDictionary.learningTypes,
    value: GroupColumns.Type,
    isChecked: true,
  },
  {
    label: mainDictionary.room,
    value: GroupColumns.Room,
    isChecked: true,
  },
  {
    label: mainDictionary.debtor,
    value: GroupColumns.Debtor,
    isChecked: true,
  },
  {
    label: mainDictionary.responsiblePerson,
    value: GroupColumns.ResponsiblePerson,
    isChecked: false,
  },
  {
    label: mainDictionary.groupStatus,
    value: GroupColumns.GroupStatus,
    isChecked: false,
  },
  {
    label: mainDictionary.groupCapacity,
    value: GroupColumns.GroupCapacity,
    isChecked: false,
  },
  {
    label: mainDictionary.students,
    value: GroupColumns.Students,
    isChecked: false,
  },
]

enum WeekDayValues {
  Monday = 1,
  Tuesday,
  Wednesday = 4,
  Thursday = 8,
  Friday = 16,
  Saturday = 32,
  Sunday = 64,
}

export const COLUMN_SETTINGS = "groupColumnsSettings"

export const weekDays = [
  {
    label: "Du",
    value: WeekDayValues.Monday,
  },
  {
    label: "Se",
    value: WeekDayValues.Tuesday,
  },
  {
    label: "Ch",
    value: WeekDayValues.Wednesday,
  },
  {
    label: "Pa",
    value: WeekDayValues.Thursday,
  },
  {
    label: "Ju",
    value: WeekDayValues.Friday,
  },
  {
    label: "Sh",
    value: WeekDayValues.Saturday,
  },
  {
    label: "Ya",
    value: WeekDayValues.Sunday,
  },
]

export const colors = [
  {
    value: "lightBlue",
    color: "#C0E1FB",
  },
  {
    value: "purple",
    color: "#DDC7FA",
  },
  {
    value: "green",
    color: "#CEEFDF",
  },
  {
    value: "orange",
    color: "#FAD6C7",
  },
  {
    value: "grey",
    color: "#FAC7F2",
  },
  {
    value: "grey",
    color: "#DCE1E5",
  },
  {
    value: "blue",
    color: "#C0E1FB",
  },
]

export const studentTypes = [
  {
    label: mainDictionary.activeUsers,
    value: 1,
  },
  {
    label: mainDictionary.finishedUsers,
    value: 2,
  },
]

export const MatchTypes = {
  [MatchType.Phone]: mainDictionary.phoneNumber,
  [MatchType.Email]: mainDictionary.email,
  [MatchType.Name]: mainDictionary.name,
}

export enum GroupLessonTabs {
  Videos = "1",
  Homework = "2",
  Exam = "3",
  Journal = "4",
}

export const videoColumns: TableProps<IVideo>["columns"] = [
  {
    title: mainDictionary.videoName,
    render: (item: IVideo) => <VideoName video={item} />,
  },
  {
    title: mainDictionary.lessonName,
    render: (item: IVideo) => (
      <Tooltip placement="top" title={item?.lesson?.name}>
        <span className={cn("lesson-name")}>{item.lesson.name}</span>
      </Tooltip>
    ),
  },
  {
    title: mainDictionary.status,
    align: "center",
    render: (item: IVideo) => <VideoStat video={item} />,
  },
  {
    title: mainDictionary.lessonDate,
    align: "center",
    render: (item: IVideo) => <span>{formatDate(item.lesson.createdAt)}</span>,
  },
  {
    title: mainDictionary.size,
    align: "center",
    render: (item: IVideo) => <span>{formatBytes(item.size)}</span>,
  },
  {
    title: mainDictionary.addedDate,
    align: "center",
    render: (item: IVideo) => <span>{formatDate(item.createdAt)}</span>,
  },
  {
    title: mainDictionary.actions,
    width: 100,
    align: "center",
    render: (item: IVideo) => item.status !== VideoStatus.Uploading && <VideoActions video={item} />,
  },
]

export const videoStatusColumns: TableProps<VideoStatusHistory>["columns"] = [
  {
    title: mainDictionary.status,
    key: "status",
    render: (value, record) => <Tag color={tagColor[record.status]}>{videoStatusText[record.status]}</Tag>,
  },
  {
    title: mainDictionary.message,
    dataIndex: "message",
    key: "message",
  },
  {
    title: mainDictionary.date,
    key: "message",
    render: (value, record) => <span className={cn("nowrap")}>{getFormattedFullDateFromParams(record.createdAt)}</span>,
  },
]

export enum LessonType {
  Curriculum = 1,
  Other,
}

export const attendanceTypes = [
  {
    label: mainDictionary.byCurriculum,
    value: LessonType.Curriculum,
  },
  {
    label: mainDictionary.other,
    value: LessonType.Other,
  },
]

export const examStatuses = {
  [ExamStatus.InProgress]: mainDictionary.active,
  [ExamStatus.Completed]: mainDictionary.completed,
}

export const getDateHomeworkFormat = (date: string | any) => dayjs(date).add(-5, "hour").format(DATE_FORMAT_WITH_HOUR)

type TStatusMap = { [key in JournalAttendedStatus]: string }

export const journalAttendanceTableColors: TStatusMap = {
  [JournalAttendedStatus.Absent]: "absent",
  [JournalAttendedStatus.Ended]: "ended",
  [JournalAttendedStatus.NoClass]: "no-class",
  [JournalAttendedStatus.Late]: "",
  [JournalAttendedStatus.Attended]: "",
}

export const journalAttendanceTableContent = {
  [JournalAttendedStatus.Absent]: "-",
  [JournalAttendedStatus.Ended]: "*",
  [JournalAttendedStatus.Late]: "+",
  [JournalAttendedStatus.NoClass]: "!",
  [JournalAttendedStatus.Attended]: "+",
}

export const homeworkIconClasses = {
  [HomeworkFilterStatus.Accepted]: "accepted-icon",
  [HomeworkFilterStatus.Rejected]: "rejected-icon",
  [HomeworkFilterStatus.Uncompleted]: "notDone-icon",
  [HomeworkFilterStatus.Waiting]: "waiting-icon",
  [HomeworkFilterStatus.NotGiven]: "not-given-icon",
  [HomeworkFilterStatus.Checking]: "waiting-icon",
}
