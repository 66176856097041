import { useMemo } from "react"
import { observer } from "mobx-react"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Flex, Popconfirm, Table, TableProps } from "antd"
import classNames from "classnames/bind"
import { CustomField } from "@/api/settings"
import mainDictionary from "@/dictionary"
import { addCatchNotification, addSuccessNotification } from "@/modules/notifications"
import { useStores } from "@/stores"

import styles from "./students.module.scss"
const cn = classNames.bind(styles)

export const Students = observer(() => {
  const { settingsStore } = useStores()

  const handleDelete = (id: number) => {
    settingsStore.setCustomFieldsLoading(true)
    settingsStore
      .deleteCustomField(id)
      .then((res) => {
        if (res.success) {
          settingsStore.getCustomFields({ target: settingsStore.customFieldsTarget })
          addSuccessNotification(mainDictionary.successFullySaved)
        }
      })
      .catch(addCatchNotification)
  }

  const handleEdit = (item: CustomField) => {
    settingsStore.setSelectedCustomField(item)
    settingsStore.setMakeCustomFieldModal(true)
  }

  const columns: TableProps<CustomField>["columns"] = useMemo(
    () => [
      {
        title: mainDictionary.name,
        render: (item: CustomField) => <span>{item.name}</span>,
      },
      {
        title: mainDictionary.type,
        render: (item: CustomField) => <span>{item.type}</span>,
      },
      {
        title: mainDictionary.variants,
        render: (item: CustomField) => <span>{item.parameters.length ? item.parameters?.join(", ") : "-"}</span>,
      },
      {
        title: mainDictionary.actions,
        width: 100,
        render: (item: CustomField) => (
          <Flex gap={10}>
            <Button onClick={handleEdit.bind(null, item)} icon={<EditOutlined />} type="text" />

            <Popconfirm
              placement="left"
              onConfirm={handleDelete.bind(null, item.id)}
              title={mainDictionary.areYouSureToDelete}
              cancelText={mainDictionary.cancel}
              okText={mainDictionary.delete}
            >
              <Button icon={<DeleteOutlined />} type="text" danger />
            </Popconfirm>
          </Flex>
        ),
      },
    ],
    [],
  )

  const handleAdd = () => {
    settingsStore.setMakeCustomFieldModal(true)
  }

  return (
    <Flex vertical gap={20} className={cn("students")}>
      <Flex justify="flex-end">
        <Button onClick={handleAdd} type="primary">
          {mainDictionary.add}
        </Button>
      </Flex>

      <Table
        pagination={false}
        loading={settingsStore.customFieldsLoading}
        columns={columns}
        dataSource={settingsStore.customFields || []}
      />
    </Flex>
  )
})
