import "./helper.css"

import { useMemo, useState } from "react"
import { observer } from "mobx-react"
import { Flex, Form, Input, Radio, RadioChangeEvent, Select, Switch, Table, TableProps, Typography } from "antd"
import classNames from "classnames/bind"
import mainDictionary from "@/dictionary"
import { attendanceTypes, LessonType } from "@/pages/Groups/constants"

import styles from "./table.module.scss"

const cn = classNames.bind(styles)

export const AttendanceTable = observer(() => {
  const [lessonType, setLessonType] = useState<LessonType>(LessonType.Other)

  const columns: TableProps["columns"] = useMemo(
    () => [
      {
        title: mainDictionary.order,
        width: 100,
        render: () => <span>1</span>,
      },
      {
        title: mainDictionary.studentName,
        render: () => <span>John Doe</span>,
      },
      {
        title: mainDictionary.time,
        width: 100,
        render: () => <span>10:00</span>,
      },
      {
        title: mainDictionary.came,
        align: "center",
        width: 100,
        render: () => (
          <Flex justify="center">
            <Switch />
          </Flex>
        ),
      },
    ],
    [],
  )

  const handleLessonTypeChange = (params: RadioChangeEvent) => {
    setLessonType(params.target?.value)
  }

  return (
    <Flex className={`table ${cn("table")}`} vertical gap={20}>
      <Typography.Title className={cn("table__title")} level={5}>
        {mainDictionary.attendanceAndEnteringTopic}
      </Typography.Title>

      <Form.Item name="lessonType">
        <Radio.Group onChange={handleLessonTypeChange} value={lessonType} options={attendanceTypes} />
      </Form.Item>

      <div className={cn("table__wrapper")}>
        {lessonType === LessonType.Curriculum ? (
          <Form.Item label={mainDictionary.topic} rules={[{ required: true }]} name="topic">
            <Select />
          </Form.Item>
        ) : (
          <Form.Item label={mainDictionary.topic} name="topic" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        )}
      </div>

      <Table columns={columns} dataSource={[{}, {}]} />
    </Flex>
  )
})
