import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { Flex, Segmented, Spin } from "antd"
import classNames from "classnames/bind"
import mainDictionary from "@/dictionary"
import { GroupMake } from "@/pages/Groups/GroupMake"
import { useStores } from "@/stores"
import { GroupMentors } from "./GroupMentors"
import { Parameters } from "./Parameters"
import { Schedule } from "./Schedule"
import { Statistics } from "./Statistics"
import { TeacherPayments } from "./TeacherPayments"

import styles from "./group-info.module.scss"

const cn = classNames.bind(styles)

export const GroupInfo = observer(() => {
  const { groupsStore } = useStores()
  const { id } = useParams()
  const [selectedSegment, setSelectedSegment] = useState(mainDictionary.schedule)

  useEffect(() => {
    if (id) {
      const parsedId = parseInt(id, 10)

      groupsStore.setGroupInfoLoader(true)

      groupsStore.getGroupInfo(parsedId)
    }
  }, [])

  if (groupsStore.groupInfoLoader)
    return (
      <Flex justify="center" align="center">
        <Spin />
      </Flex>
    )

  return (
    <Flex className={cn("group-info")} vertical gap={32}>
      <Flex align="flex-start" gap={20}>
        <Statistics />

        <Flex className={cn("group-info__right")} vertical gap={12}>
          <GroupMentors />
          <Parameters />
        </Flex>
      </Flex>

      <div>
        <Segmented onChange={setSelectedSegment} options={[mainDictionary.schedule, mainDictionary.teacherPayments]} />
      </div>

      {selectedSegment === mainDictionary.schedule ? <Schedule /> : <TeacherPayments />}

      {groupsStore.groupMakeModal && <GroupMake />}
    </Flex>
  )
})
