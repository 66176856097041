import { FC, useMemo } from "react"
import { observer } from "mobx-react"
import { Tag } from "antd"
import classNames from "classnames/bind"
import { IVideo } from "@/api/groups"
import { VideoStatus } from "@/modules/kinescope"
import { tagColor, videoStatusText } from "@/pages/Groups/constants"
import { useStores } from "@/stores"

import styles from "./video-status.module.scss"

const cn = classNames.bind(styles)

type Props = {
  video: IVideo
}

export const VideoStat: FC<Props> = observer(({ video }) => {
  const { groupsStore } = useStores()
  const status = video.currentStatus || video.status
  const statusText = useMemo(() => videoStatusText[status], [status])
  const statusColor = useMemo(() => tagColor[status], [status])

  const handleOpenStatusHistories = () => {
    groupsStore.setStatusHistoryModal(true)
    groupsStore.setSelectedVideo(video)
  }

  return (
    <Tag color={statusColor} onClick={handleOpenStatusHistories} className={cn("status")}>
      {statusText}&nbsp;
      {(status === VideoStatus.Uploading || status === VideoStatus.Pause) && video.percentage !== undefined && (
        <b>{video.percentage}%</b>
      )}
    </Tag>
  )
})
